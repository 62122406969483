<template>
  <div id="app">
    <hik-header></hik-header>
    <router-view/>
    <hik-bottom></hik-bottom>
  </div>
</template>

<script>
  import HikHeader from "components/HikHeader"
  import HikBottom from "components/HikBottom"
  import { ethers } from 'ethers'
  export default {
    name: 'App',
    components: {
      HikHeader,
      HikBottom
    },
    created() {
      let that = this
      ethereum.on('chainChanged', function(chainId) {
        if (ethers.utils.formatUnits(chainId, 0) !== process.env.VUE_APP_CHAIN_ID) {
          that.$alert(`Change network to ${process.env.VUE_APP_NETWORK}`, 'ネットワークをご確認ください。', {type: 'error'})
        }
      })
      ethereum.on('accountsChanged', function(accounts) {
        that.$store.commit('SET_ACCOUNT', accounts[0])
        if (that.$store.getters.token) {
          that.$store.dispatch('Logout')
        }
      })
      ethereum.on('disconnect', function(accounts) {
        that.$store.commit('SET_ACCOUNT', '')
      })
    }
  }
</script>

<style>
	@import "./assets/css/base.css";
  @media (max-width: 800px) {
  	#app{padding-top:60px;}
    .el-message {
      min-width: 300px !important;
    }
    .el-message-box{
      width: 80vw !important;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	#app{padding-top:80px;}
  }
  @media(min-width: 1200px) {
  	#app{padding-top:80px;}
  }
</style>
