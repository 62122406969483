<template>
  <div>

    <div class="receive_container_ce">
    	<div class="receive_til">受信</div>
    	<div class="receive_address" v-if="data">
    		<div class="address_info">{{data.walletAddress[0]}}</div>
    		<div class="address_copy" @click="copyAddress()"></div>
    	</div>
    	<div class="qrcode_img">
    		<img :src="qrcode" />
    	</div>
    </div>

    <div class="bottomDiv"></div>
  </div>
</template>

<script>
  import { putAction,getAction } from '@/network/manage'
  import { ethers } from 'ethers'
  import numeral from 'numeral'
  import qrcode from 'qrcode'
  export default {
    name: 'Qrcode',
    components: {
    },
    data() {
      return {
        url: {
          user: '/user/'
        },
        data:null,
        qrcode:''
      }
    },
    computed: {

    },
    created(){
      this.loadDetail();
    },
    methods:{
      loadDetail(){
        this.initUserInfo()
      },
      initUserInfo() {
        const that = this;
        getAction(this.url.user,{}).then((res) => {
          if (res.code == 200) {
            this.headimgurl = res.data.profileImage !== null ? res.data.profileImage : require('assets/img/headimg.jpg')
            that.data = res.data
            qrcode.toDataURL(res.data.walletAddress[0], (err,url) => {
                that.qrcode = url
            })
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      toTop(){
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      },
      goHome() {
        this.$router.push({path:'home'})
      },
      copyAddress() {
        var oInput = document.createElement('input')
        oInput.value = this.data.walletAddress[0]
        document.body.appendChild(oInput)
        oInput.select()
        document.execCommand("Copy")
        oInput.style.display='none'
        this.$message.success('コピーしました。')
      }
    }
  }
</script>

<style scoped>
body{background: #ffffff;overflow-x: hidden;}
*{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
input,select{outline:none;}
a{text-decoration: none;}
.more{background: #00C999;color:#ffffff;border-radius: 35px;height:44px;width: 148px;margin:60px auto 0 auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}
.paths{display: flex;padding:36px 0;}
.paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
.paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
.paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
.paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
@media (max-width: 800px) {
  .bottomDiv{height:100px;}
  .receive_til {
  	margin-top:30px;
  	text-align: center;
  	font-size: 20px;
  	color: #4A4A4A;
  	font-family: 'SF UI Display blod';
  }

  .receive_address {
  	width:350px;
  	margin: auto;
  	border:1px #F0F0F0 solid;
  	margin-top:40px;
  	border-radius: 20px;
  	height: 51px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  }

  .address_info {
  	font-family: 'SF UI Display light';
  	font-size: 13px;
  	color: #888888;
    width:100%;
    word-break: break-all;
    margin-left:15px;
  }

  .address_copy {
  	margin-right:15px;
  	width:21px;
  	height:20px;
  	background-image: url(../assets/img/copy.png);
  	background-repeat: no-repeat;
  	background-position: center center;
    flex-shrink: 0;
  }

  .qrcode_img {
  	width:250px;
  	height:250px;
  	border:1px #f0f0f0 solid;
  	margin: auto;
  	border-radius: 20px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	margin-top:15px;
  }

  .qrcode_img img {
  	width:235px;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px){

}


@media(min-width: 1200px){

}
</style>
