import { ethers } from 'ethers'
import { putAction, postAction } from "network/manage"
import store from '@/store'

export function get_eth_account() {
  return new Promise((resolve, reject) => {
    if (typeof window.ethereum === 'undefined') {
      reject({message: 'metamask is not installed'})
    } else {
      ethereum.request({ method: 'eth_requestAccounts' })
      .then(accounts => {
        resolve(accounts[0])
      })
      .catch(err => {
        reject(err)
      })
    }
  })
}

export async function get_wallet() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const address = await signer.getAddress()
    const balance = await provider.getBalance(address)
    const ether = ethers.utils.formatEther(balance)
    return Promise.resolve({address, ether})
  } catch(err) {
    return Promise.reject(err)
  }
}

export function get_eth_chainId() {
  return new Promise((resolve, reject) => {
    if (typeof window.ethereum === 'undefined') {
      reject({message: 'metamask is not installed'})
    } else {
      ethereum.request({ method: 'eth_chainId' })
      .then(chain_id => {
        const chainId = ethers.utils.formatUnits(chain_id, 0)
        resolve(chainId)
      })
      .catch(err => {
        reject(err)
      })
    }
  })
}

export function toEther(wei) {
  return ethers.utils.formatEther(wei)
}

export function toWei(ether) {
  return ethers.utils.parseEther(ether)
}

function getMetaDataUrl(groupId) {
  return new Promise((resolve, reject) => {
    putAction(`/purchase/create_metadata/${groupId}`, {external_url: encodeURIComponent(`${process.env.VUE_APP_DOMAIN_NAME}/ntfHome?id=${groupId}`)})
    .then(res => {
      if (res.code === 200) {
        resolve(res.data.metadata_url)
      } else {
        reject(res)
      }
    })
    .catch(err => {
      reject(err)
    })
  })
}

async function getContractsInfo() {
  try {
    if (store.getters.purchaseAddress === '' || store.getters.purchaseAbi === '') {
      await store.dispatch('GetContracts')
    }
    return Promise.resolve()
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function initItem(groupId, price) {
  try {
    const metadata_url = await getMetaDataUrl(groupId)
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    await getContractsInfo()
    const contract = new ethers.Contract(store.getters.purchaseAddress, store.getters.purchaseAbi, signer)
    const estimateGas = await contract.estimateGas.buyNft(groupId, metadata_url, {
      value: toWei(price.toString())
    })
    const feeData = await calcGas(estimateGas, provider)
		
    const gasFee = parseFloat(ethers.utils.formatEther(feeData.maxFeePerGas.mul(feeData.gasLimit))).toFixed(9)
    return Promise.resolve({metadata_url, gasFee: parseFloat(gasFee)})
  } catch(err) {
		console.log(err.error);
    return Promise.reject(err.error ? err.error : err.message)
  }
}

function purchase(tokenId, groupId, transactionHash, metadata) {
  return new Promise((resolve, reject) => {
    postAction('/purchase/', {tokenId, groupId, transactionHash, metadata})
    .then(res => {
      if (res.code === 200) {
        resolve()
      } else {
        reject(res)
      }
    })
    .catch(err => {
      reject(err)
    })
  })
}

async function calcGas(gasEstimated, provider) {
    let gas = {
        gasLimit: gasEstimated.mul(110).div(100),
    };
    try {
    		const data = await provider.getFeeData()
				console.log(ethers.utils.formatUnits(data.maxFeePerGas, "gwei"));
				if(ethers.utils.formatUnits(data.maxFeePerGas, "gwei") < 30) {
					gas.maxFeePerGas = ethers.utils.parseUnits("30", "gwei").mul(150).div(100)
				} else {
					gas.maxFeePerGas = data.maxFeePerGas.mul(150).div(100)
				}
				gas.maxPriorityFeePerGas = ethers.utils.parseUnits("30", "gwei");
    } catch (error) {
    	console.log(error);
    }
    return gas;
}

export async function signItem(item) {
  try {
    let metadata_url = item.metadata_url
    if (!metadata_url) {
      metadata_url = await getMetaDataUrl(item.groupId)
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    await getContractsInfo()
    const contract = new ethers.Contract(store.getters.purchaseAddress, store.getters.purchaseAbi, signer)
		const gasEstimated = await contract.estimateGas.buyNft(item.groupId, metadata_url, {
			value:toWei(item.price.toString())
		})
		const gas = await calcGas(gasEstimated, provider)
		gas.value = toWei(item.price.toString())
    const res = await contract.buyNft(item.groupId, metadata_url, gas)
    await provider.waitForTransaction(res.hash)
    const receipt = await provider.getTransactionReceipt(res.hash)
		//遍历所有日志
		const logsDatas = receipt.logs
		let tokenId = ""
		for(let logData of logsDatas) {
			try {
				const log = contract.interface.parseLog(logData)
				tokenId = log.args._tokenId.toNumber()
				break
			} catch(err) {
				continue
			}
		}
		if(tokenId != "") {
				await purchase(tokenId, item.groupId, res.hash, metadata_url)
				return Promise.resolve()
		} else {
			return Promise.reject('Abnormal payment, the fee has been returned, please refresh and try again')
		}
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function setSalePrice(groupId, price) {
  try {
    const provider = ethers.getDefaultProvider(process.env.VUE_APP_NETWORK_URL)
    const wallet = new ethers.Wallet(process.env.VUE_APP_SUBADMIN_PRIVATE_KEY, provider)
    await getContractsInfo()
    const contract = new ethers.Contract(store.getters.purchaseAddress, store.getters.purchaseAbi, wallet)
		const gasEstimated = await contract.estimateGas.setSalePrice(groupId, price)
		const gas = await calcGas(gasEstimated, provider)
    const res = await contract.setSalePrice(groupId, price, gas)
		await provider.waitForTransaction(res.hash)
    return Promise.resolve(res)
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function setDateTime(groupId, startTime, endTime) {
  try {
    const provider = ethers.getDefaultProvider(process.env.VUE_APP_NETWORK_URL)
    const wallet = new ethers.Wallet(process.env.VUE_APP_SUBADMIN_PRIVATE_KEY, provider)
    await getContractsInfo()
    const contract = new ethers.Contract(store.getters.purchaseAddress, store.getters.purchaseAbi, wallet)
		const gasEstimated = await contract.estimateGas.setDateTime(groupId, startTime, endTime)
		const gas = await calcGas(gasEstimated, provider)
    const res = await contract.setDateTime(groupId, startTime, endTime, gas)
		await provider.waitForTransaction(res.hash)
    return Promise.resolve(res)
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function setItemDownShelf(groupId) {
  try {
    const provider = ethers.getDefaultProvider(process.env.VUE_APP_NETWORK_URL)
    const wallet = new ethers.Wallet(process.env.VUE_APP_SUBADMIN_PRIVATE_KEY, provider)
    await getContractsInfo()
    const contract = new ethers.Contract(store.getters.purchaseAddress, store.getters.purchaseAbi, wallet)
		const gasEstimated = await contract.estimateGas.setSaleStatus(groupId, false)
		const gas = await calcGas(gasEstimated, provider)
    const res = await contract.setSaleStatus(groupId, false, gas)
		await provider.waitForTransaction(res.hash)
    return Promise.resolve(res)
  } catch(err) {
    return Promise.reject(err)
  }
}

export function isMobile() {
  const u = navigator.userAgent
  const browser = {
    isMobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    isIos: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    isAndroid: u.indexOf('Android') > -1, //android终端或者uc浏览器
    isIPhone: u.indexOf('iPhone') > -1, //是否为iPhone
    isIPad: u.indexOf('iPad') > -1 //是否iPad
  }
  if (browser.isMobile || browser.isIos || browser.isAndroid || browser.isIPhone || browser.isIPad) {
    return true
  }
  return false
}
