<template>
  <div id="register">
    <div class="content mai">
      <div class="title">会員登録</div>
      <h2>Step 1. <span @click="gotoMetaMask">ここをクリックして</span>METAMASKウォレットを作成してください。</h2>
      <p>MATAMASKをお持ちですか？  MATAMASKを起動して次のステップに進んでください。</p>
      <h2>Step 2. <span @click="connectWallet">ここをクリックし</span>てウォレットに接続してください。</h2>
      <div class="connection">{{connectMsg}}</div>
      <div class="connectionResult" v-show="isConnected">
        <b>住所 </b>
        <label>{{account}}</label>
      </div>
      <h2>Step 3.  お客様の情報を入力してください。</h2>
      <form class="inputDiv" @keydown.enter="toSignup" @submit.prevent>
        <div class="til">E-mail</div>
        <input placeholder="メールアドレス" v-model="email" @input="emailCheck"/>
        <div class="msg" :class="{msgOk : msgState_email}">{{checkMsg_email}}</div>
        <div class="til">ニックネーム</div>
        <input placeholder="ニックネーム" v-model="nickname" @input="nicknameCheck"/>
        <div class="msg" :class="{msgOk : msgState}">{{checkMsg}}</div>
      </form>
      <div class="button" :class="{ok : formCheckRes}" @click="toSignup">登録</div>
    </div>
    <div class="bottomDiv"></div>
  </div>
</template>

<script>
  import HikHeader from "components/HikHeader"
  import HikBottom from "components/HikBottom"
  import { mapGetters } from 'vuex'
  import { get_eth_account } from '@/utils/utils'
  import { duplicate, signup } from "network/user"
  import { ACCESS_TOKEN } from '@/store/mutation-types'


  export default {
    name: 'Register',
    components: {
      HikHeader,
      HikBottom
    },
    data() {
      return {
        email: '',
        nickname: '',
        checkMsg: '',
        checkMsg_email: '',
        msgState: false,
        msgState_email: false,
        emailCheckRes: false,
        nicknameCheckRes: false
      }
    },
    computed: {
      ...mapGetters([
        'account'
      ]),
      isConnected() {
        return this.account !== ''
      },
      connectMsg() {
        if (this.isConnected) {
          return '接続済み'
        } else if (typeof window.ethereum === 'undefined') {
          return 'インストールしてください。'
        } else {
          return '接続できませんでした。'
        }
      },
      formCheckRes() {
        return this.isConnected && this.emailCheckRes && this.nicknameCheckRes
      }
    },
    created() {
      //获取当前账号，判断是否已经存在，如果存在自动登录
    },
    methods: {
      gotoMetaMask() {
        window.open('https://metamask.io/download/', '_blank')
      },
      connectWallet() {
        if (!this.isConnected) {
          const that = this
          get_eth_account()
          .then(res => {
            that.$store.commit('SET_ACCOUNT', res)
          })
          .catch(err => {
            that.$notification.error({title: 'システムメッセージ', message: err.message })
          })
        }
      },
      async emailCheck() {
        let result = false
        if (this.email === '') {
          this.checkMsg_email = 'Eメールを入力してください。'
        } else if (!new RegExp(/^[a-z0-9_\+-]+(\.[a-z0-9_\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]{2,4})$/).test(this.email)) {
          this.checkMsg_email = 'Eメールを正しく入力してください。'
        } else {
          try {
            const duplicateResult = await duplicate('EMAIL', this.email)
            if (duplicateResult.code === 200 && !duplicateResult.data.isDuplicated) {
              this.checkMsg_email = 'このメールをご利用ください。'
              result = true
            } else {
              this.checkMsg_email = 'このメールは使用できません。別のメールをお試しください。'
            }
          } catch(err) {
            this.$notification.error({title: 'システムメッセージ', message: err.message })
          }
        }
        this.emailCheckRes = result
        this.msgState_email = result
        return result
      },
      async nicknameCheck() {
        let result = false
        if (this.nickname === '') {
          this.checkMsg = 'ニックネーム入力してください。'
        } else {
          try {
            const duplicateResult = await duplicate('NICKNAME', this.nickname)
            if (duplicateResult.code === 200 && !duplicateResult.data.isDuplicated) {
              this.checkMsg = 'このニックネームを使用することができます。'
              result = true
            } else {
              this.checkMsg = 'このニックネームは使用できません。別のニックネームをお試しください。'
            }
          } catch(err) {
            this.$notification.error({title: 'システムメッセージ', message: err.message })
          }
        }
        this.nicknameCheckRes = result
        this.msgState = result
        return result
      },
      async toSignup() {
        if (!this.isConnected) {
          this.checkMsg = '接続できませんでした。'
        } else if (await this.emailCheck() && await this.nicknameCheck()) {
          let data = {email: this.email, nickname: this.nickname, walletAddress: this.account}
          const loading = this.$loading.service()
          try {
            const res = await signup(data)
            this.$ls.set(ACCESS_TOKEN, res.data.token, 10 * 60 * 60 * 1000)
            this.$store.commit('SET_TOKEN', res.data.token)
            this.$router.replace('/registerFinish')
          } catch(err) {
            this.$notification.error({title: 'システムメッセージ', message: err.message })
          } finally {
            loading.close()
          }
        }
      }
    }
  }
</script>

<style scoped>
  .mai{display: flex;flex-direction: column;align-items: center;}
  .mai span{color:#1192CA;cursor: pointer;}
  .mai h2{font-weight: normal;}
  .mai p{color:#888888;margin-bottom:30px;}
  .mai .connection{border:1px solid #F0F0F0;background-size: 30px;margin-top:20px;border-radius: 2px;font-family: 'SF UI Display light';background-image: url('../assets/img/fox.png');background-position: left 22px center;background-repeat: no-repeat;color:#1192CA;display: flex;justify-content: center;align-items: center;}
  .mai .connectionResult{border: 1px solid #F0F0F0;border-radius: 20px;height:46px;margin-top:16px;margin-bottom:30px;display: flex;align-items: center;}
  .mai .connectionResult b{flex-shrink: 0;font-weight: normal;font-family: 'SF UI Display light';}
  .mai .connectionResult label{width:100%;color:#888888;font-family: 'SF UI Display light';font-size:13px;text-align: left;}
  .mai .inputDiv .til{font-size: 13px;font-family: 'SF UI Display light';margin:10px 0;}
  .mai .inputDiv input{border: 1px solid #F0F0F0;border-radius: 2px;width:100%;height:40px;padding:0 20px;color: #4A4A4A;font-size: 13px;font-family: 'SF UI Display light';}
  .mai .inputDiv input::-webkit-input-placeholder{color:#D0D0D0;font-family:'SF UI Display light';}
  .mai .inputDiv .msg{font-size:14px;color:#FF6746;font-family: 'SF UI Display light';margin-top:5px;}
  .msgOk{color: #1192CA !important;}
  .mai .button{height:40px;background: #D0D0D0;border-radius: 2px;color:#ffffff;margin-top:50px;display: flex;justify-content: center;align-items: center;}
  .ok{background: #00C999 !important;cursor: pointer;}
  @media (max-width: 800px) {
  	.mai .title{font-size:18px;margin:30px 0;align-self: center;}
  	.mai h2{font-size:14px;}
  	.mai p{font-size:14px;margin-top:10px;}
  	.mai{align-items: flex-start;}
  	.mai .button{width:100%;}
  	.mai .connection{width:60vw;align-self: center;height:46px;padding-left:15vw;}
  	.mai .connectionResult{width: 100%;height:36px;}
  	.mai .connectionResult b{font-size:13px;margin:0 20px;}
  	.mai .connectionResult label{font-size:13px;}
  	.mai .inputDiv{width:100%;}
  	.mai .inputDiv .msg{font-size:12px;}
  	.content{margin:0 16px;}
    .bottomDiv{height:100px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:800px;margin:auto;}
  	.mai h2{font-size:24px;margin:10px auto;}
  	.mai .title{font-size:30px;margin-top:40px;margin-bottom:50px;}
  	.mai .connection{width:280px;height:46px;padding-left:60px;}
  	.mai .connectionResult{width:440px;}
  	.mai .button{width:400px;}
  	.mai .inputDiv{width:400px;}
  	.mai .connectionResult b{margin:0 20px;}
    .bottomDiv{height:100px;}
  }
  @media(min-width: 1200px) {
  	.mai .inputDiv{width:400px;}
  	.mai .title{font-size:30px;margin-top:40px;margin-bottom:50px;}
  	.mai h2{font-size:24px;margin:10px auto;}
  	.content{width:1200px;margin:auto;}
  	.mai .connection{width:280px;height:46px;padding-left:60px;}
  	.mai .connectionResult{width:440px;}
  	.mai .button{width:400px;}
  	.mai .connectionResult b{margin:0 20px;}
    .bottomDiv{height:200px;}
  }
</style>
