import Vue from 'vue'
import { login, getUserInfo } from '@/network/user'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { get_eth_account } from '@/utils/utils'
import { MessageBox } from 'element-ui'

const user = {
  state: {
    account: '',
    token: '',
    nickname: '',
    headimgurl: '',
    userId: ''
  },

  mutations: {
    SET_ACCOUNT: (state, account) => {
      state.account = account
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname
    },
    SET_HEADIMGURL: (state, headimgurl) => {
      state.headimgurl = headimgurl
    },
    SET_USERID: (state, userid) => {
      state.userId = userid
    }
  },

  actions: {
    async Login({ dispatch, commit, state }) {
      try {
        if (state.account === '') {
          const account = await get_eth_account()
          commit('SET_ACCOUNT', account)
        }
        const res = await login(state.account)
        if (res.code === 200) {
          Vue.ls.set(ACCESS_TOKEN, res.data.token, 10 * 60 * 60 * 1000)
          commit('SET_TOKEN', res.data.token)
          await dispatch('GetUserInfo')
          await dispatch('UpdateCartNumber')
        }
        return Promise.resolve(res)
      } catch(err) {
        return Promise.reject(err)
      }
    },
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            if (res.code === 200) {
              commit('SET_NICKNAME', res.data.nickName)
              commit('SET_USERID', res.data.userId)
              if (res.data.profileImage) {
                commit('SET_HEADIMGURL', res.data.profileImage)
              }
              resolve(res)
            } else {
              reject(res)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 登出
    Logout({ commit, state }) {
      commit('SET_NICKNAME', '')
      commit('SET_HEADIMGURL', '')
      commit('SET_TOKEN', '')
      commit('SET_USERID', '')
      commit('SET_NUMBER', 0)
      Vue.ls.remove(ACCESS_TOKEN)
    },
  },
}

export default user
