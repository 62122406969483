<template>
  <div id="home" v-if="nftObj != null">
    <hik-header></hik-header>
    <div class="content mai">
			<div class="left">
				<div class="goodsImgBanner">
					<div class="imgShow" :style="actIndex==0?nftObj.files.thumbnail.urlBG:nftObj.files.assistImages[actIndex-1].urlBG"></div>
					<div class="gallery-thumbs">
						<div class="cont">
						  <div class="swiperpager">
							<div class="bg">
							  <div class="swiper-button-prev"></div>
							</div>
							<div class="bg">
							  <div class="swiper-button-next"></div>
							</div>
						  </div>
						  <swiper :options="swiperOptions">
							<swiper-slide>
							  <img :class="actIndex==0?'act':''" @click="changeactIndex(0)" :src="nftObj.files.thumbnail.url">
							</swiper-slide>
							<swiper-slide v-for="(item,i) in nftObj.files.assistImages" :key="i">
							  <img :class="actIndex== i + 1 ? 'act' : ''" :src="item.url" @click="changeactIndex(i+1)"/>
							</swiper-slide>
						  </swiper>
						</div>
					</div>
				</div>
				<h1 class="pc">プロジェクトのご紹介</h1>
				<p class="pc">{{nftObj.description}}</p>
				<div class="tit pc">詳細</div>
				<div class="desc pc" v-html="nftObj.introduction"></div>
        <h1 class="pc">制作時参考PC仕様</h1>
        <pre class="pc">{{nftObj.pc_official}}</pre>
			</div>
			<div class="right">
				<h1>{{nftObj.title}}</h1>
				<div class="userSpan">
					<i :style="nftObj.sellerProfileImg"></i>
					{{nftObj.sellerNickname}}
				</div>
				<div class="link">{{nftObj.isAllowedEdit ? '当該NFTは再編集できます。' : '当該NFTは再編集できません。'}}</div>
				<div class="roy" v-if="nftObj.royalty != null && nftObj.royalty != 0">ロイヤリティー : {{nftObj.royalty * 100}} %</div>
				<div class="skp">{{nftObj.title}}{{nftObj.uploadSuffix}} : {{nftObj.files.nftContent.size}} MB</div>
				<div class="tags">
					<span v-for="(item,i) in nftObj.files.nftContent.extensions" :key="i">{{item}}</span>
				</div>
				<div class="ops">
					<div :class="nftObj.isLiked ? 'act' : 'likeSpan'" @click="like_item">お気に入り追加</div>
					<div class="cartSpan" @click="cart_item">カートへ追加</div>
				</div>

				<div class="btn" v-if="nftObj.sale_status == 'on_sale'"  @click="cart_item_buy">
					<span>QTY {{nftObj.sold}}/{{nftObj.volumes}}</span>
					<div>
						<h2>{{numberFormat(nftObj.price)}}</h2>
						<h3>MATIC</h3>
						<h4>( ≈ {{numberFormat(nftObj.price * hl)}} YEN)</h4>
					</div>
				</div>
        <div class="btn" v-if="nftObj.sale_status == 'not_on_sale'" style="background-color: #888888;">
        		販売待機中
        </div>
        <div class="btn" v-if="nftObj.sale_status == 'sold_out'" style="background-color: #888888;">
        		販売完了
        </div>
        <div class="btn" v-if="nftObj.sale_status == 'blocked'" style="background-color: #888888;">
        		販売待機中
        </div>

				<div class="rightBanner pc">
          <swiper :options="swiperOptions2">
          	<swiper-slide v-for="(item,i) in banner" :key="i">
              <a class="img" :href="item.linkTo" :style="item.contentUrl"></a>
            </swiper-slide>
          	<div class="swiper-pagination" slot="pagination"></div>
          </swiper>
				</div>
				<div class="divLine mobile"></div>
				<div class="mobileDetail mobile">
					<h1>プロジェクトのご紹介</h1>
					<p>{{nftObj.description}}</p>
					<div class="tit">詳細</div>
					<div class="desc" v-html="nftObj.introduction"></div>
          <h1>制作時参考PC仕様</h1>
          <pre>{{nftObj.pc_official}}</pre>
				</div>
			</div>
		</div>
		<div class="bottomDiv"></div>
		<div class="content paths pc">
			<i class="home" @click="go_home"></i>
			<i class="jt"></i>
			<a>{{nftObj.sellerNickname}}様の作品</a>
			<i class="jt"></i>
			<a>{{nftObj.title}}</a>
		</div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import store from '@/store'
  import { swiper, swiperSlide} from "vue-awesome-swiper"
  import 'swiper/css/swiper.css'
  import HikHeader from "components/HikHeader"
  import { putAction,getAction,deleteAction,postAction } from '@/network/manage'
  import { isMobile } from '@/utils/utils'
  import numeral from 'numeral'
  export default {
    name: 'NftHome',
    components: {
      HikHeader,
      swiper,
      swiperSlide
    },
    data() {
      return {
        url: {
          detail: '/nft/detail',
          hl:'/matic_conversion/',
          banner: '/banner/',
          like:'/like/',
          cart:'/cart/'
        },
        nftObj:null,
        banner:[],
        hl:1,
        spage:1,
        actIndex:0,
        swiperOptions:{
          slidesPerView: 5,
          spaceBetween: 10,
          slidesPerGroup: 5,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
        swiperOptions2:{
          loop: true,
          speed: 1000,
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        'token',
        'userId'
      ])
    },
    created(){
      this.loadHl();
      this.listData();
      this.loadBanner('ITEM_BANNER');
    },
    methods:{
      like_item(){
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.like_item_function();
        }else{
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'ログイン',
            callback: (action, instance) => {
              if (action === 'confirm') {
                if(typeof window.ethereum === 'undefined') {
                  if (isMobile()) {
                    window.location.href = `https://metamask.app.link/dapp/${process.env.VUE_APP_DOMAIN_NAME}`
                  } else {
                    that.$router.push({path:'/register'})
                  }
                } else {
                  store.dispatch('Login')
                  .then(res => {
                    if (res.code === 200) {
                      that.like_item_function();
                    } else if (res.code === 4004) {
                      that.$router.push({path:'register',query:{}})
                    } else {
                      that.$notification.error({title: 'システムメッセージ', message: res.message })
                    }
                  }).catch(err => {
                    that.$notification.error({title: 'システムメッセージ', message: err.message })
                  })
                }
              }
            }
          })
        }
      },
      like_item_function(){
        if(this.nftObj.sellerId == this.userId) {
          this.$notification.error({title: 'システムメッセージ', message: "自分の作品は収蔵できない" })
          return;
        }
        const that = this;
        const loading = this.$loading.service()
        if(that.nftObj.isLiked){
          deleteAction(that.url.like, {groupId:that.nftObj.groupId}).then((res) => {
            if (res.code == 200) {
              that.nftObj.isLiked = false;
            } else {
              that.$message.error(res.message);
            }
          }).catch(res => {
            that.$message.warning(res.message);
          }).finally(res => {
            loading.close();
          })
        }else{
          postAction(that.url.like, {groupId:that.nftObj.groupId}).then((res) => {
            if (res.code == 200) {
              that.nftObj.isLiked = true;
            } else {
              that.$message.error(res.message);
            }
          }).catch(res => {
            that.$message.warning(res.message);
          }).finally(res => {
            loading.close();
          })
        }
      },
      cart_item(){
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.cart_item_function();
        }else{
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'ログイン',
            callback: (action, instance) => {
              if (action === 'confirm') {
                if(typeof window.ethereum === 'undefined') {
                  if (isMobile()) {
                    window.location.href = `https://metamask.app.link/dapp/${process.env.VUE_APP_DOMAIN_NAME}`
                  } else {
                    that.$router.push({path:'/register'})
                  }
                } else {
                  store.dispatch('Login')
                  .then(res => {
                    if (res.code === 200) {
                      that.cart_item_function();
                    } else if (res.code === 4004) {
                      that.$router.push({path:'register',query:{}})
                    } else {
                      that.$notification.error({title: 'システムメッセージ', message: res.message })
                    }
                  }).catch(err => {
                    that.$notification.error({title: 'システムメッセージ', message: err.message })
                  })
                }
              }
            }
          })
        }
      },
      cart_item_buy(){
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.cart_item_function(1);
        }else{
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'ログイン',
            callback: (action, instance) => {
              if (action === 'confirm') {
                if(typeof window.ethereum === 'undefined') {
                  if (isMobile()) {
                    window.location.href = `https://metamask.app.link/dapp/${process.env.VUE_APP_DOMAIN_NAME}`
                  } else {
                    that.$router.push({path:'/register'})
                  }
                } else {
                  store.dispatch('Login')
                  .then(res => {
                    if (res.code === 200) {
                      that.cart_item_function();
                    } else if (res.code === 4004) {
                      that.$router.push({path:'register',query:{}})
                    } else {
                      that.$notification.error({title: 'システムメッセージ', message: res.message })
                    }
                  }).catch(err => {
                    that.$notification.error({title: 'システムメッセージ', message: err.message })
                  })
                }
              }
            }
          })
        }
      },
      cart_item_function(type){
        const that = this;
        if(this.nftObj.sellerId == this.userId) {
          this.$notification.error({title: 'システムメッセージ', message: "自分の作品は購入できません" })
          return;
        }
        if(this.nftObj.sale_status == 'not_on_sale' || this.nftObj.sale_status == 'blocked') {
          that.$message.warning("販売待機中");
          return;
        }
        if(this.nftObj.sale_status == 'sold_out') {
          that.$message.warning("販売完了");
          return;
        }
        const loading = this.$loading.service();
        postAction(that.url.cart, {groupId:that.nftObj.groupId}).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message);
            this.$store.dispatch('UpdateCartNumber');
            if(type == 1) {
              this.$router.push({path:'/cart'})
            }
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          loading.close();
        })
      },
      changeactIndex(ind){
        this.actIndex = ind;
      },
      numberFormat(txt){
        return numeral(txt).format('0,0.000')
      },
      go_home(){
        this.$router.push({path:'home',query:{}})
      },
      loadHl(){
        const that = this;
        getAction(this.url.hl,{}).then((res) => {
          if (res.code == 200) {
            that.hl = res.data.maticToYen;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      loadBanner(type){
        const that = this;
        getAction(this.url.banner,{device_type:type}).then((res) => {
          if (res.code == 200) {
            var list = res.data.contents;
            for(var i = 0; i < list.length; i++){
              list[i].contentUrl = "background-image:url('"+list[i].contentUrl+"')";
            }
            that.banner = list;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      listData(){
        const that = this;
        getAction(this.url.detail+"/"+this.$route.query.id,{}).then((res) => {
          if (res.code == 200) {
            var obj = res.data;
            if(obj.sellerProfileImg != null)
              obj.sellerProfileImg = "background-image:url('" + obj.sellerProfileImg + "')";
            obj.files.thumbnail.urlBG = "background-image:url('"+obj.files.thumbnail.url+"')";
            for(var i = 0;i<obj.files.assistImages.length;i++){
              obj.files.assistImages[i].urlBG = "background-image:url('"+obj.files.assistImages[i].url+"')";
            }
            obj.price = obj.price / 1000;
						obj.uploadSuffix = obj.files.nftContent.url.substring(obj.files.nftContent.url.lastIndexOf("."));
            this.nftObj = obj;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
  .mai{display: flex;align-items:flex-start;justify-content: space-between;}
  .swiperpager{display: flex;position: absolute;height:0;justify-content: space-between;z-index:100;}
  .swiperpager .bg{background: rgba(255,255,255,0.5);padding:0 6px;display: flex;justify-content: center;align-items: center;}
  .swiperpager .swiper-button-prev{position: static;filter: grayscale(100%);transform: scale(0.6);}
  .swiperpager .swiper-button-next{position: static;filter: grayscale(100%);transform: scale(0.6);}
  .swiperpager .swiper-button-prev.swiper-button-disabled {opacity: 0.15;}
  .swiperpager .swiper-button-next.swiper-button-disabled {opacity: 0.15;}
  .mai .left .goodsImgBanner .imgShow{width:100%;background-size: cover;background-position: center;}
  .mai .left .goodsImgBanner .gallery-thumbs {height: 128px;padding: 10px 0;overflow: hidden;}
  .mai .left .goodsImgBanner .gallery-thumbs .cont{width:100%;}
  .mai .left .goodsImgBanner .pager div{position: absolute;margin-top:10px;z-index: 2;display: flex;cursor: pointer;}
  .mai .left .goodsImgBanner .pager div i{background: rgba(255,255,255,0.5);background-repeat: no-repeat;background-position: center;}
  .mai .left .goodsImgBanner .pager .last i{transform: rotate(180deg);}
  .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide {}
  .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img {display: block;width: 100%;object-fit: cover;opacity: 0.4;}
  .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide .act {border:2px solid #000000;opacity: 1;}
  .mai .left h1{font-size: 20px;margin:60px 0 40px 0;font-weight: normal;}
  .mai .left p{font-size:15px;line-height:2;margin-bottom:40px;}
  .mai .left .tit{font-size: 20px;margin-bottom:30px;}
  .mai .left .desc{display: flex;flex-direction: column;word-break: break-all;}
  .mai .left .desc img{max-width: 100%;}
  .mai .left .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
  .mai .right h1{font-size: 24px;line-height:1.8;margin-bottom:16px;font-weight: normal;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .mai .right .userSpan{display: flex;align-items: center;font-size:14px;}
  .mai .right .userSpan i{width:28px;height:28px;background-image: url(../assets/img/userGray_cur.png);border-radius: 50%;background-size:cover;background-position:center;margin-right:10px;}
  .mai .right .link{color: #00C999;text-decoration-line: underline;font-size: 14px;margin:16px 0;}
  .mai .right .roy{color: #4A4A4A;text-decoration-line: underline;font-size: 15px;margin:16px 0;}
  .mai .right .skp{color: #4A4A4A;font-size: 15px;margin:16px 0;}
  .mai .right .tags{display: flex;flex-wrap: wrap;}
  .mai .right .tags span{background: #F7F7F7;border-radius: 2px;padding: 6px 10px;color: #4A4A4A;font-family: 'SF UI Display thin';font-size:14px;margin:0 10px 10px 0;}
  .mai .right .ops{display: flex;align-items: center;margin:16px 0;}
  .mai .right .ops div{width:100%;background-position: left center;background-repeat: no-repeat;padding-left:30px;font-size: 15px;}
  .mai .right .ops .likeSpan{background-image:url(../assets/img/blackHeart.png);cursor: pointer;}
  .mai .right .ops .act{background-image:url(../assets/img/heartAct.png);cursor: pointer;}
  .mai .right .ops .cartSpan{background-image: url(../assets/img/cart.png);cursor: pointer;}
  .mai .right .btn{width:100%;height:56px;background: #00C999;cursor: pointer;margin:30px 0 16px 0;border-radius: 2px;color:#ffffff;display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .mai .right .btn span{font-size: 14px;}
  .mai .right .btn div{display: flex;justify-content: center;align-items: center;font-family: 'SF UI Display thin';}
  .mai .right .btn div h2{font-weight: normal;font-size: 19px;}
  .mai .right .btn div h3{font-weight: normal;font-size: 15px;margin-left:10px;margin-right:4px;}
  .mai .right .btn div h4{font-weight: normal;font-size: 14px;}
  .mai .rightBanner .swiper-slide{display: flex;}
  .mai .rightBanner .img{width:100%;background-size: cover;background-position: center;background-color: #f7f7f7;}
  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.mai{flex-direction: column;width:100vw;}
  	.mai .left .goodsImgBanner{width:100vw;}
  	.mai .left .goodsImgBanner .imgShow{width:100vw;height:304px;}
  	.mai .left .goodsImgBanner .gallery-thumbs{padding:2vw;overflow: hidden;height:auto;}
  	.mai .left .goodsImgBanner .mobile{}
  	.mai .left .goodsImgBanner .mobile div{margin-top: 2vw;}
  	.mai .left .goodsImgBanner .mobile div i{width:30px;height:16.07vw;}
  	.mai .left .goodsImgBanner .mobile .last{left:2vw;}
  	.mai .left .goodsImgBanner .mobile .next{right:2vw;}
    .swiperpager{width:96vw;}
    .swiperpager .bg{padding:0 2px;height:12vw;}
    .swiperpager .swiper-button-prev{transform: scale(0.4);}
    .swiperpager .swiper-button-next{transform: scale(0.4);}
  	.mai .right{width:100vw;}
  	.mai .right h1{margin:20px 4vw;font-size:22px;}
  	.mai .right .userSpan{margin:0 4vw;}
  	.mai .right .link{margin:10px 4vw;}
  	.mai .right .roy{margin:10px 4vw;}
  	.mai .right .skp{margin:10px 4vw;}
  	.mai .right .tags{margin:10px 4vw;}
    .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img{height:12vw;}
  	.mai .right .ops{position: fixed;width:100vw;z-index:1000;bottom:56px;left:0px;background-color: #ffffff;height:40px;justify-content: center;margin:0px;}
  	.mai .right .ops div{width:auto;}
  	.mai .right .ops div:nth-child(1){margin-right:30px;}
  	.mai .right .btn{position: fixed;width:100vw;z-index:1000;bottom:0px;left:0px;height:56px;margin:0px;}
  	.divLine{background: #F0F0F0;height:8px;}
  	.mai .mobileDetail{margin:0px 4vw;}
  	.mai .mobileDetail h1{font-size: 18px;margin:20px 0;font-weight: normal;}
  	.mai .mobileDetail p{font-size:14px;line-height:2;margin:10px 0;}
  	.mai .mobileDetail .tit{font-size: 18px;margin:30px 0 20px 0;}
  	.mai .mobileDetail .desc{margin:20px 0;display: flex;flex-direction: column;word-break: break-all;}
  	.mai .mobileDetail .desc img{max-width: 100%;}
  	.mai .mobileDetail .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
  	.pc{display: none !important;}
  	.content{margin:0;width:100vw;}
  	.bottomDiv{height:100px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mai{padding-top:20px;}
  	.mai .left{width:528px;}
    .swiperpager{width:528px;}
    .swiperpager .bg{height:70px;}
  	.mai .left .goodsImgBanner .imgShow{height:304px;}
    .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img {height:70px;}
  	.mai .left .goodsImgBanner .pager div i{width:30px;height:69.72px;}
  	.mai .left .goodsImgBanner .pager .next{margin-left:498px;}
  	.mai .right .ops div {font-size: 12px;}
  	.mai .right{width:240px;}
  	.mai .rightBanner .img{height:200px;}
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.bottomDiv{height:100px;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mai{padding-top:50px;}
  	.mai .left{width:792px;}
    .swiperpager{width:792px;}
    .swiperpager .bg{height:108px;}
  	.mai .left .goodsImgBanner .imgShow{height:456px;}
    .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img {height:108px;}
  	.mai .left .goodsImgBanner .pager div i{width:40px;height:108px;}
  	.mai .left .goodsImgBanner .pager .next{margin-left:752px;}
  	.mai .right{width:360px;}
  	.mai .rightBanner .img{height:300px;}
  	.content{width:1200px;margin:auto;}
  	.mobile{display: none !important;}
  	.bottomDiv{height:200px;}
  }
</style>
<style>
	.mai .left .desc img{max-width: 100%;}
	.mai .left .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
	.mai .mobileDetail .desc img{max-width: 100%;}
	.mai .mobileDetail .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
</style>
