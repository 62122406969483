<template>
  <div>
    <!--头部-->
    <div class="header pc">
      <div class="headerFix content">
        <div class="logo" @click="go_home">
          <img src="../assets/img/logo.png"/>
        </div>
        <p>作品ギャラリー</p>
        <div class="input"><input id="searchInput" autocomplete="off" v-model="keywords" @keyup.enter="go_explorer_keywords" @click="categoryShow = true" placeholder="大学　キャンパス"/></div>
        <div class="iconDiv">
          <i class="cart" @click="cart_page"><a>{{cartNumber}}</a></i>
          <i class="user" v-if="!isLogin" @click="login"></i>
          <i class="head" v-if="isLogin">
            <div class="body" :style="{'background-image': `url(${getHeadImgUrl})`}">
              <div class="user_menu">
                <div class="menu_header_name">
                  <div class="menu_header">
                    <img :src="getHeadImgUrl"/>
                  </div>
                  <div class="menu_nickname">{{nickname}}</div>
                </div>
                <div class="menu_items">
                    <div class="menu_item" @click="go_page('/wallet')">ウォレット</div>
                    <div class="menu_item" @click="go_page('/prifile')">プロフィール</div>
                    <div class="menu_item" @click="go_page('/collectionCollected')">マイコレクション</div>
                </div>
                <div class="menu_item_end" @click="signOut">サインアウト</div>
              </div>
            </div>
          </i>
          <span v-if="isLogin" @click="go_page('/upload')">
            <i></i>
            アップロード
          </span>
        </div>
      </div>
      <div class="search" v-if="categoryShow">
        <div class="searchBg" @click="categoryShow = false"></div>
        <div class="searchDiv">
          <div class="content main">
            <div v-for="(item,i) in categoryList" :key="i"><a href="javascript:void(0)" @click="go_explorer_categoryId(item.categoryId)">{{item.category}}</a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="headerMobile mobile">
      <div class="headerFix">
        <div class="logo" @click="go_home">
			<img src="../assets/img/logo.png"/>
		</div>
        <div class="iconDiv">
          <i class="searchI" @click="categoryShow = true"></i>
          <i class="cart" @click="cart_page"><a>{{cartNumber}}</a></i>
          <i class="menu" @click="user_menuAct = true"></i>
        </div>
      </div>
      <div class="searchm" v-if="categoryShow">
        <div class="searchBgm" @click="categoryShow = false"></div>
        <div class="searchDivm">
          <div class="input"><input v-model="keywordsM" @keyup.enter="go_explorer_keywordsM" id="searchInput" placeholder="大学　キャンパス"/></div>
        </div>
      </div>
      <div class="user_menu_shar" @click="user_menuAct = false" v-if="user_menuAct"></div>
      <div :class="user_menuAct ? 'user_menu user_menuAct' : 'user_menu'">
        <div class="closeBtn"></div>
        <div class="menu_header_name">
          <div class="menu_header" v-if="!isLogin" @click="login">
            <img src="@/assets/img/userGray_cur.png"/>
          </div>
          <div class="menu_header" v-if="isLogin">
            <img :src="getHeadImgUrl"/>
          </div>
          <div class="menu_nickname">{{nickname}}</div>
        </div>
        <span class="mobile" v-if="isLogin" @click="go_page('/upload')">
          <i></i>
          アップロード
        </span>
        <div class="menu_items">
          <div class="menu_item" @click="go_page('/wallet')">ウォレット</div>
          <div class="menu_item" @click="go_page('/prifile')">プロフィール</div>
          <div class="menu_item" @click="go_page('/collectionCollected')">マイコレクション</div>
        </div>
        <div class="menu_item_end" v-if="isLogin" @click="signOut">サインアウト</div>
      </div>
    </div>
    <!-- 置顶 -->
    <div class="topDiv" @click="toTop" v-show="isToTopShow"></div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getAction } from '@/network/manage'
  import { isMobile } from '@/utils/utils'

  export default {
    name: 'HikHeader',
    data() {
      return {
        url: {
          category: '/category/',
          cartCount:'/cart/basket_count'
        },
        keywords:'',
        keywordsM:'',
        categoryShow:false,
        user_menuAct:false,
        categoryList:null,
        isToTopShow:false
      }
    },
    computed: {
      ...mapGetters([
        'account',
        'token',
        'nickname',
        'headimgurl',
        'cartNumber'
      ]),
      isLogin() {
        return this.token !== null && this.token !== ''
      },
      getHeadImgUrl() {
        return this.headimgurl !== '' ? this.headimgurl : require('assets/img/headimg.jpg')
      }
    },
    created(){
      this.loadCategory();
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
      handleScroll() {
        let clientHeight = 0
        let scrollTop = 0
        if (document.documentElement) {
          clientHeight = document.documentElement.clientHeight
          scrollTop = document.documentElement.scrollTop
        } else if (document.body) {
          clientHeight = document.body.clientHeight
          scrollTop = document.body.scrollTop
        }
        if(scrollTop > clientHeight / 2) {
          this.isToTopShow = true
        } else {
          this.isToTopShow = false
        }
      },
      login() {
        const that = this
        if(typeof window.ethereum === 'undefined') {
          if (isMobile()) {
            window.location.href = `https://metamask.app.link/dapp/${process.env.VUE_APP_DOMAIN_NAME}`
          } else {
            that.$router.push({path:'/register'})
          }
        } else {
          const loading = this.$loading.service()
          this.$store.dispatch('Login')
          .then(res => {
            if (res.code === 200)  {
              that.$notification.success({title: 'success', message: `welcome ${that.nickname}` })
            } else if (res.code === 4004) {
              that.$router.push({path:'/register'})
            } else {
              that.$notification.error({title: 'システムメッセージ', message: res.message })
            }
          })
          .catch(err => {
            that.$notification.error({title: 'システムメッセージ', message: err.message })
          })
          .finally(() => {
            loading.close()
          })
        }
      },
      go_to_upload() {
        this.$router.push({path:'/upload'})
      },
      signOut() {
        this.$store.dispatch('Logout')
        this.$router.push({path:'/'})
      },
      loadCategory(){
        const that = this;
        getAction(this.url.category,{}).then((res) => {
          if (res.code == 200) {
            that.categoryList = res.data;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      cart_page(){
        this.categoryShow = false;
        this.$router.push({path:'/cart'})
      },
      go_explorer_keywords(){
        this.categoryShow = false;
        this.$router.push({path:'/explorer',query:{search_term:this.keywords}})
      },
      go_explorer_keywordsM(){
        this.categoryShow = false;
        this.$router.push({path:'/explorer',query:{search_term:this.keywordsM}})
      },
      go_explorer_categoryId(id){
        this.categoryShow = false;
        this.$router.push({path:'/explorer',query:{category_id:id}})
      },
      go_home(){
        this.categoryShow = false;
        this.$router.push({path:'/home'})
      },
      go_page(url){
        this.categoryShow = false;
        this.user_menuAct = false;
        this.$router.push({path:url})
      },
      toTop(){
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      }
    }
  }
</script>

<style scoped>
  .search{}
  .search .searchBg{background:rgba(0,0,0,0.6);position: fixed;width:100vw;top:80px;height:100vh;z-index:98;left:0;}
  .search .searchDiv{background-color: #ffffff;position: fixed;width:100vw;top:80px;z-index:99;}
  .search .searchDiv .main{display: grid;grid-template-columns: 20% 20% 20% 20% 20%;}
  .search .searchDiv .main a{color:#888888;text-shadow: 3px 3px 5px #bbbbbb;}
  .search .searchDiv .main a:hover{color:#000000;border-bottom:1px solid #000000;}
  .header{width:100%;top:0;left:0;z-index:100;position: fixed;background-color: #ffffff;}
  .header .headerFix{height:80px;display: flex;align-items: center;justify-content: space-between;}
  .header .headerFix .logo{flex-shrink: 0;cursor: pointer;display: flex;}
  .header .headerFix p{font-size:15px;font-weight: 400;flex-shrink: 0;}
  .header .headerFix .input{width:100%;}
  .header .headerFix input{border:none;height:34px;border-bottom:1px solid #F0F0F0;background-image: url('../assets/img/search.png');background-position: right center;background-repeat: no-repeat;}
  .header .headerFix input::-webkit-input-placeholder{color:#D0D0D0;}
  .header .headerFix .iconDiv{display: flex;align-items: center;flex-shrink: 0;}
  .header .headerFix .iconDiv i{height:24px;width:24px;background-repeat: no-repeat;background-position: center;flex-shrink: 0;cursor: pointer;font-style: normal;}
  .header .headerFix .iconDiv i a{position: absolute;color:#00C999;line-height:1.4;font-family:'SF UI Display ultralight';font-size: 18px;}
  .cart{background-image: url('../assets/img/cart.png');background-size:20px;}
  .user{background-image: url('../assets/img/user.png');background-size:24px;}
  .menu{background-image: url('../assets/img/menu.png');}
  .searchI{background-image: url('../assets/img/search.png');background-size:18px;}
  .head{height:28px !important;width:28px !important;}
  .head .body{border-radius:50%;border:1px solid #D0D0D0;height:28px !important;width:28px !important;background-size: cover;background-position: center;}
  .header .headerFix .iconDiv{cursor: pointer;}
  .header .headerFix .iconDiv span{background: #EAFAF6;border-radius: 2px;color:#00C999;display: flex;align-items: center;height:38px;justify-content: center;padding:0 20px;}
  .header .headerFix .iconDiv span i{background-image: url('../assets/img/upload.png');margin:0px 10px 0 0;background-position: center;}
  .head:hover .user_menu {display: block;}
  .user_menu{position: fixed;width:260px;border:1px #D0D0D0 solid;z-index:102;background-color: #FFFFFF;margin-top:27px;margin-left: -232px;display: none;}
  .user_menu_shar{position: fixed;width:100vw;height:100vh;left:0;top:0;background: rgba(0,0,0,0.6);z-index:101;}
  .menu_header_name {display: flex;align-items: center;height: 90px;border-bottom: 1px #F0F0F0 solid;}
  .menu_header {width:90px;display: flex;align-items: center;justify-content: center;flex-shrink: 0;}
  .menu_header img {width:56px;height:56px;border-radius: 50%;}
  .menu_nickname {font-family:'SF UI Display ultralight';font-size: 16px;color: #000000;width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
  .menu_items {padding: 15px 0;}
  .menu_item {line-height: 40px;font-size: 15px;padding-left:17px;}
  .menu_item:hover {color: #00C999;}
  .menu_item_end {font-size: 15px;padding-left:17px;line-height: 80px;border:1px #F0F0F0 solid;}
  .menu_item_end:hover {color: #00C999;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
  	.headerMobile{width:100%;top:0;left:0;z-index:100;position: fixed;background-color: #ffffff;}
  	.headerMobile .headerFix{height:60px;display: flex;align-items: center;justify-content: space-between;padding:0 16px;}
  	.headerMobile .headerFix .logo{flex-shrink: 0;display: flex;}
  	.headerMobile .headerFix .iconDiv{display: flex;}
  	.headerMobile .headerFix .iconDiv i{width:24px;height:24px;background-repeat: no-repeat;background-position: center;margin-left:30px;font-style:normal;}
  	.headerMobile .headerFix .iconDiv i a{position: absolute;color:#00C999;margin-left:26px;line-height: 1.3;font-family:'SF UI Display ultralight';font-size: 18px;}
  	.searchm{display: flex;}
  	.searchm .searchBgm{background:rgba(196, 196, 196, 0.5);position: fixed;width:100vw;top:81px;height:100vh;z-index:98;left:0;}
  	.searchm .searchDivm{background-color: #ffffff;position: fixed;width:100vw;top:60px;z-index:99;padding:10px 0 30px 0;}
  	.searchm .searchDivm div{display: flex;justify-content: center;align-items: center;}
  	.searchm .searchDivm input{border:none;height:34px;width:76vw;border-bottom:1px solid #F0F0F0;background-image: url('../assets/img/search.png');background-position: right center;background-repeat: no-repeat;}
  	.searchm .searchDivm input::-webkit-input-placeholder{color:#D0D0D0;}
  	.user_menu{width:75vw;left:100vw;top:0;margin:0;height:100vh;border:none;display: block;transition: left 0.2s;}
    .user_menuAct{left:25vw !important;}
  	.user_menu .closeBtn{width:24px;height:24px;background-image: url('../assets/img/close_bk.png');display:none;background-position: center;background-repeat: no-repeat;position: fixed;top:10px;right:10px;}
  	.user_menu span{background: #EAFAF6;border-radius: 2px;color:#00C999;display: flex;align-items: center;height:38px;justify-content: center;margin:0 17px;}
  	.user_menu span i{background-image: url('../assets/img/upload.png');margin:0px 10px 0 0;background-position: center;margin-left:0px !important;}
  	.menu_item_end{border-bottom: none;}
  	.menu_header_name {border-bottom: none;}
  	.topDiv{border: 1px solid #F0F0F0;cursor: pointer;background-color: #ffffff;background-image:url('../assets/img/topMobile.png');background-repeat: no-repeat;background-position: center;box-sizing: border-box;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);width:50px;height:50px;position: fixed;z-index:100;bottom:230px;right:20px;border-radius: 50%;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.mobile{display: none !important;}
  	.header .headerFix .logo{margin-right:20px;}
  	.header .headerFix input{margin:0 20px;width:240px;}
  	.header .headerFix .iconDiv{width:272px;}
  	.header .headerFix .iconDiv i{margin:0 10px;}
  	.header .headerFix .iconDiv i a{margin-left:24px;}
  	.header .headerFix .iconDiv span{margin-left:10px;}
  	.search .searchDiv .main a{font-size:13px;text-shadow: 3px 3px 3px #bbbbbb;}
  	.search .searchDiv .main{grid-row-gap: 10px;padding:40px 0;}
  	.topDiv{border: 1px solid #F0F0F0;cursor: pointer;background-size: 30%;background-image: url('../assets/img/top.png');background-repeat: no-repeat;background-position: center;box-sizing: border-box;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);width:40px;height:40px;position: fixed;z-index:100;bottom:130px;right:20px;border-radius: 50%;}
  }
  @media(min-width: 1200px) {
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
  	.header .headerFix p{margin:0 60px;}
  	.header .headerFix input{margin:0 80px 0 20px;width:330px;}
  	.header .headerFix .iconDiv{width:348px;}
  	.header .headerFix .iconDiv i{margin:0 20px;}
  	.header .headerFix .iconDiv i a{margin-left:30px;}
  	.header .headerFix .iconDiv span{margin-left:30px;height:40px;padding: 0 28px;}
  	.bottomDiv{height:200px;}
  	.banner3{padding:100px 0;}
  	.videoDiv h4{line-height:70px;}
  	.videoDiv div .img{width:590px;height:328px;}
  	.goodsList .tool{margin-top:80px;height:70px;margin-bottom:40px;}
  	.goodsList .tool div span{line-height:70px;margin-right:50px;}
  	.banner2{padding-top:40px;}
  	.banner2 h4{line-height:70px;}
  	.search .searchDiv .main a{font-size:15px;text-shadow: 3px 3px 5px #bbbbbb;}
  	.search .searchDiv .main{grid-row-gap: 20px;padding:60px 0;}
  	.topDiv{border: 1px solid #F0F0F0;cursor: pointer;background-size: 40%;background-image: url('../assets/img/top.png');background-repeat: no-repeat;background-position: center;box-sizing: border-box;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);width:50px;height:50px;position: fixed;z-index:100;bottom:130px;right:30px;border-radius: 50%;}
  }
</style>
