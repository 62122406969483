<template>
  <div style="border: 1px solid #ccc;">
      <Toolbar
      style="border-bottom: 1px solid #ccc" 
      :editor="editor" 
      :defaultConfig="toolbarConfig" 
      mode="simple"/>
      <Editor 
      style="overflow-y: hidden;" 
      :style="{'height':activeHeight}"
      v-model="contentHtml" 
      :defaultConfig="editorConfig" 
      mode="simple" 
      @onCreated="onCreated"/>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { i18nChangeLanguage } from '@wangeditor/editor'
import { ACCESS_TOKEN } from '@/store/mutation-types'
i18nChangeLanguage('en')
export default {
  name: 'WangEditor',
  components: { Editor, Toolbar },
  model:{
    prop:'value',
    event:'change'
  },
  props: {
    value: {
      type: String
    },
    activeHeight: {
      type: String,
      default: '300px'
    }
  },
  watch:{
    value(val){
      this.contentHtml = this.value
    },
    contentHtml(val){
      this.$emit('change', val)
    }
  },
  data() {
    return {
      contentHtml: this.value,
      editor: null,
      toolbarConfig: { },
      editorConfig: { 
        placeholder: '内容を入力してください。。。' ,
        MENU_CONF:{
          uploadImage:{
            server: `${process.env.VUE_APP_BASE_API}file/upload`,
            fieldName: 'file',
            timeout: 15 * 1000,
            headers: {
              Authorization: this.$ls.get(ACCESS_TOKEN)
            },
            customInsert(res, insertFn) {
              if(res.code == 200) {
                insertFn(res.data.s3, "", "")
              }
            },
          }
        }
      },
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>