<template>
  <div style="display: flex;justify-content: center;background-color: #f0ebf8;">
      <iframe width="100%" height="800" style="border:none;" src="https://docs.google.com/forms/d/e/1FAIpQLScGyci8kAVzH6t861ghBKHkjM1LKzqnhgbsfWMJAuH7r2dfpw/viewform"></iframe>
  </div>
</template>

<script>
  import { putAction,getAction } from '@/network/manage'
  export default {
    name: 'Page',
    components: {
    },
    data() {
      return {
        url: {
          detail: '/page/detail',
        },
        data:null
      }
    },
    watch: {
    　　
    },
    created(){
    },
    methods:{
      loadDetail(){
        const that = this;
        let linkurl = this.$route.params.linkurl
        getAction(this.url.detail,{url_link:linkurl}).then((res) => {
          if (res.code == 200) {
            that.data = res.data.contents[0];
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      toTop(){
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      },
      goHome() {
        this.$router.push({path:'/home'})
      }
    }
  }
</script>

<style scoped>
body{background: #ffffff;overflow-x: hidden;}
*{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
input,select{outline:none;}
a{text-decoration: none;}
.terms_nr >>>table {border: none;border-collapse: collapse;}
.terms_nr >>>table tr td{
 border: 1px solid #ccc !important;
 padding: 3px 5px;
 min-width: 50px;
 height: 20px;
}
.more{background: #00C999;color:#ffffff;border-radius: 35px;height:44px;width: 148px;margin:60px auto 0 auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}
.paths{display: flex;padding:36px 0;}
.paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
.paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
.paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
.paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
@media (max-width: 800px) {
	body{padding-top:60px;}
	.content{margin:0 16px;font-size: 14px;color:#4A4A4A;}
	.bottomDiv{height:30px;}
	.paths{padding:20px 0;}
	.title {font-size:18px;color: #000000;margin-top: 30px;}
}
@media screen and (min-width: 800px) and (max-width: 1200px){
	body{padding-top:80px;}
	.content{width:800px;margin:auto;font-size: 14px;color:#4A4A4A;}
	.bottomDiv{height:100px;}
	.title {font-size:20px;color: #000000;margin-top: 50px;}
}


@media(min-width: 1200px){
	body{padding-top:80px;}
	.content{width:1200px;margin:auto;font-size: 14px;color:#4A4A4A;}
	.bottomDiv{height:150px;}
	.title {font-size:20px;color: #000000;margin-top: 50px;}
}

</style>
