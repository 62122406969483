import { getAction, postAction } from "./manage"
import { ethers } from 'ethers'

function getNonce() {
  return new Promise((resolve, reject) => {
    getAction('/user/nonce')
    .then(res => {
      if (res.code === 200) {
        resolve(res.data)
      } else {
        reject(res)
      }
    })
    .catch(err => {
      reject(err)
    })
  })
}

export async function signup(data) {
  try {
    const nonce = await getNonce()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const signature = await signer.signMessage(encodeURIComponent(nonce.nonceNumber))
    data.nonceId = nonce.nonceId
    data.signature = signature
    const res = await postAction('/user/signup', data)
    if (res.code === 200) {
      return Promise.resolve(res)
    } else {
      return Promise.reject(res)
    }
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function login(walletAddress) {
  try {
    const nonce = await getNonce()
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const signature = await signer.signMessage(encodeURIComponent(nonce.nonceNumber))
    const data = {}
    data.nonceId = nonce.nonceId
    data.signature = signature
    data.walletAddress = walletAddress
    const res = await postAction('/user/login', data)
    return Promise.resolve(res)
  } catch(err) {
    return Promise.reject(err)
  }
}

export function getUserInfo() {
  return getAction('/user/')
}

export function duplicate(check, element) {
  const data = {check, element}
  return getAction('/user/duplicate', data)
}
