import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { MessageBox,Notification,Loading } from 'element-ui'
import { isMobile } from '@/utils/utils'

NProgress.configure({ showSpinner: false })

const whiteList = ['/404', '/register', '/home', '/page', '/explorer', '/ntfHome','/page/', '/question']
const white_pattern = /^\/page\/{1}\w*\-*\w*$/
router.beforeEach((to, from, next) => {
  NProgress.start()

  if (store.getters.token) {
    /* has token */
    if (to.path === '/register') {
      next({ path: '/' })
      NProgress.done()
    } else {
      store.dispatch('UpdateCartNumber')
      //获取用户信息
      if (store.getters.nickname === '') {
        store.dispatch('GetContracts')
        store.dispatch('GetUserInfo')
        .catch(err => {
          Notification.error({title: 'システムメッセージ', message: err.message })
        })
        .finally(() => {
          next()
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || white_pattern.test(to.path)) {
      next()
    } else {
      NProgress.done()
      MessageBox.confirm('ログインしてください。', 'システムメッセージ', {
        confirmButtonText: 'ログイン',
        callback: (action, instance) => {
          if (action === 'confirm') {
            if(typeof window.ethereum === 'undefined') {
              if (isMobile()) {
                next(false)
                window.location.href = `https://metamask.app.link/dapp/${process.env.VUE_APP_DOMAIN_NAME}`
              } else {
                next({path: '/register'})
              }
            } else {
              const loading = Loading.service()
              store.dispatch('Login')
              .then(res => {
                if (res.code === 200) {
                  next()
                } else if (res.code === 4004) {
                  next({path: '/register'})
                } else {
                  Notification.error({title: 'システムメッセージ', message: res.message })
                }
              })
              .catch(err => {
                Notification.error({title: 'システムメッセージ', message: err.message })
              })
              .finally(() => {
                loading.close()
              })
            }
          } else {
            next(false)
          }
        }
      })
    }
  }
})
router.afterEach(() => {
  NProgress.done()
})
