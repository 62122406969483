<template>
  <div>
    <!-- goodsList -->
    <div class="content goodsList" v-loading="mainLoading">
    	<div class="tool">
    		<div>
          <span @click="go_page('CollectionCreated')">生成</span>
          <span class="pc" @click="go_page('CollectionActivity')">アクティビティ</span>
          <span class="act" @click="go_page('CollectionFavorited')">お気に入り</span>
          <span @click="go_page('CollectionCollected')">コレクション</span>
    		</div>
    		<div class="addOption pc" @click="go_add_page">
    			アイテム追加
    		</div>
    	</div>
      <div class="emptyInfo" v-if="list.length === 0">
        カートが空いてます
      </div>
    	<div class="list">
    		<div class="item" v-for="(item,i) in list" :key="i" @click="go_detail_page(item.groupId)">
    			<div class="img" :style="item.url"></div>
    			<h5>{{item.title}}</h5>
    			<div class="price">
    				<div>
    					<h4>{{numberFormat(item.price)}} MATIC</h4>
    					<span>( ≈ ¥ {{numberFormat(item.price * hl)}})</span>
    				</div>
    			</div>
    			<div class="options">
    				<div class="edit" @click.stop="cart_item(item.groupId)">カートへ</div>
    				<div class="heart"></div>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="content pager pc">
      <pagination
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="change_page"
        :total="total">
      </pagination>
    </div>
    <div class="more mobile" @click="nextPage" v-if="isHaveNext">もっと見る</div>
    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home" @click="go_home"></i>
    	<i class="jt"></i>
    	<a>お気に入り</a>
    </div>
  </div>
</template>

<script>
  import { Pagination } from 'element-ui'
  import { putAction,getAction,postAction } from '@/network/manage'
  import { isMobile } from '@/utils/utils'
  import store from '@/store'
  import numeral from 'numeral'
  export default {
    name: 'CollectionFavorited',
    components: {
      Pagination
    },
    data() {
      return {
        url: {
          list: '/like/',
          hl:'/matic_conversion/',
          cart:'/cart/'
        },
        list:[],
        hl:1,
        page:1,
        isHaveNext:false,
        mainLoading:true,
        total:0,
        pageSize:8
      }
    },
    created(){
      this.loadHl();
      this.listData();
    },
    methods:{
      go_page(url){
        this.$router.push({path:url,query:{}})
      },
      go_add_page(){
        this.$router.push({path:'upload',query:{}});
      },
      go_detail_page(id){
        this.$router.push({path:'ntfHome',query:{id:id}})
      },
      go_home(){
        this.$router.push({path:'home',query:{}})
      },
      change_page(e){
        this.list=[];
        this.page = e;
        this.listData();
      },
      cart_item(id){
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.cart_item_function(id);
        }else{
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'ログイン',
            callback: (action, instance) => {
              if (action === 'confirm') {
                if(typeof window.ethereum === 'undefined') {
                  if (isMobile()) {
                    window.location.href = `https://metamask.app.link/dapp/${process.env.VUE_APP_DOMAIN_NAME}`
                  } else {
                    that.$router.push({path:'/register'})
                  }
                } else {
                  store.dispatch('Login')
                  .then(res => {
                    if (res.code === 200) {
                      that.cart_item_function(id);
                    } else if (res.code === 4004) {
                      that.$router.push({path:'register',query:{}})
                    } else {
                      that.$notification.error({title: 'システムメッセージ', message: res.message })
                    }
                  }).catch(err => {
                    that.$notification.error({title: 'システムメッセージ', message: err.message })
                  })
                }
              }
            }
          })
        }
      },
      cart_item_function(id){
        const that = this;
        const loading = this.$loading.service();
        postAction(that.url.cart, {groupId:id}).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message);
            this.$store.dispatch('UpdateCartNumber');
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          loading.close();
        })
      },
      numberFormat(txt){
        return numeral(txt).format('0,0.000')
      },
      nextPage(){
        this.page = this.page + 1;
        this.listData();
      },
      loadHl(){
        const that = this;
        getAction(this.url.hl,{}).then((res) => {
          if (res.code == 200) {
            that.hl = res.data.maticToYen;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      listData(){
        const that = this;
        that.mainLoading = true;
        getAction(this.url.list,{page:this.page,size:that.pageSize}).then((res) => {
          if (res.code == 200) {
            var obj = res.data.contents;
            for(var i = 0;i<obj.length;i++){
              obj[i].price = obj[i].price / 1000;
              obj[i].url = "background-image:url('"+obj[i].url+"')";
            }
            that.list = that.list.concat(obj);
            that.total = res.data.page.totalElements;
            if(res.data.page.currentPage < res.data.page.totalPage)
              that.isHaveNext = true;
            else
              that.isHaveNext = false;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.mainLoading = false;
        })
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
  .goodsList .tool{display: flex;align-items: center;color:#888888;justify-content: space-between;}
  .goodsList .tool a{background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right center;padding-right:20px;}
  .goodsList .tool div{display: flex;}
  .goodsList .tool div span{cursor: pointer;}
  .goodsList .tool div .act{color:#00C999;}
  .goodsList .tool .addOption{cursor: pointer;color: #1192CA;padding-left:30px;background-image: url(../assets/img/addBlue.png);background-repeat: no-repeat;background-position: left top 3px;}
  .goodsList .list{display: grid;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .zz{background: rgba(0, 0, 0, 0.4);position: absolute;}
  .goodsList .list .item .zz .zzCon{display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .goodsList .list .item .zz p{color:#ffffff;font-size:15px;margin-top:10px;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;display: flex;justify-content: flex-end;align-items: flex-start;padding:7px 12px;}
  .goodsList .list .item .img .tg{background: rgba(255, 255, 255, 0.7);border-radius: 20px;font-size:12px;padding:2px 16px;color:#4A4A4A;}
  .goodsList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;justify-content: space-between;}
  .goodsList .list .item .price div{display: flex;flex-direction: column;}
  .goodsList .list .item .price h4{font-family: 'SF UI Display bold';line-height:1;}
  .goodsList .list .item .price span{font-family: 'SF UI Display bold';line-height:1;color:#888888;font-size:15px;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/heart.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/heartAct.png);}
  .goodsList .list .item .options{border-top:1px solid #F0F0F0;padding:16px;display: flex;justify-content: space-between;align-items: center;}
  .goodsList .list .item .options .heart{width:21px;height:21px;padding:0px;margin:0px;background-image: url(../assets/img/heartAct.png);background-repeat: no-repeat;background-position: right center;}
  .goodsList .list .item .options .edit{padding-left:30px;background-position:left center;background-repeat: no-repeat;background-image: url(../assets/img/cart.png);font-size:15px;}
  .goodsList .list .item .options .edit:hover{background-image: url(../assets/img/cart_act.png) !important;color:#00C999;}
  .pager{display: flex;justify-content: center;align-items: center;margin-top:50px !important;}
  .title{line-height: 80px;font-size:20px;}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:140px 0;border-bottom: 1px solid #F0F0F0;margin-bottom:20px;}
  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
    .content{margin:0 16px;}
    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:13px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:12px;}
    .goodsList .tool{margin-top:10px;height:50px;margin-bottom:10px;width:100%;}
    .goodsList .tool div{width:100%;justify-content: space-between;}
    .goodsList .tool div span{line-height:50px;height:48px;margin-right:0px;display: flex;flex-direction: column;align-items: center;}
    .goodsList .tool div .act::after{content: '';width:30px;background-color:#00C999;height:1px;position: absolute;margin-top:38px;}
    .goodsList .mobile{padding:30px 0 60px 0;color:#888888;background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right top 37px;padding-right:16px;}
    .goodsList .list .item .zz{width:44.2vw;height:24.25vw;}
    .goodsList .list .item .zz .zzBG{height:143.5px;position: absolute;width:44.2vw;background: rgba(0, 0, 0, 0.4);padding:0px;margin:0px;}
    .goodsList .list .item .zz .zzCon{height:24.25vw;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .paths {padding: 20px 0;}
    .emptyInfo{padding:20vw 0;}
    .more{height:30px;background: #00C999;color:#ffffff;border-radius: 35px;width: 148px;margin:30px auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
    .content{width:800px;margin:auto;}
    .goodsList .list{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
    .goodsList .list .item .img{width:191px;height:105px;}
    .goodsList .list .item .price i{width:30px;height:30px;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price h4{font-size:14px;}
    .goodsList .list .item .price span{font-size:14px;}
    .goodsList .list .item .zz{width:191px;height:245px;}
    .goodsList .list .item .zz .zzCon{height:105px;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .bottomDiv{height:100px;}
    .goodsList .tool{margin-top:0px;height:50px;margin-top:20px;}
    .goodsList .tool div span{line-height:50px;margin-right:30px;}
    .emptyInfo{padding:80px 0;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
  	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodsList .list .item .img{width:285px;height:156px;}
  	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodsList .list .item h5{height:48px;}
  	.goodsList .list .item .zz{width:285px;height:319px;}
  	.goodsList .list .item .zz .zzCon{height:156px;}
  	.goodsList .list .item .zz img{height:60px;}
    .bottomDiv{height:200px;}
    .goodsList .tool{margin-top:0px;height:70px;margin-top:40px;}
    .goodsList .tool div span{line-height:70px;margin-right:50px;}
    .emptyInfo{padding:80px 0;}
  }
</style>
