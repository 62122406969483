import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { Notification, MessageBox } from 'element-ui'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  timeout: 500000
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    switch (error.response.status) {
      case 403:
        Notification.error({ title: 'システムメッセージ', message: 'サーバのアクセス拒否' })
        break
      case 500:
        Notification.error({ title: 'システムメッセージ', message: '内部サーバエラー' })
        break
      case 404:
        Notification.error({ title: 'システムメッセージ', message: '申し訳ありませんが、要求されたリソースが見つかりません' })
        break
      case 504:
        Notification.error({ title: 'システムメッセージ', message: 'リクエストがタイムアウトしました。後で再試行してください' })
        break
      case 401:
        MessageBox.confirm('申し訳ありませんが、ログインステータスは期限切れです。再ログインしてください', 'ログインステータスが期限切れです', {
          okText: '再ログイン',
          type: 'error'
        }).then(() => {
          return store.dispatch('Logout')
        }).then(() => {
          store.dispatch('Login')
        })
        break
      default:
        Notification.error({title: 'システムメッセージ', message: error.message })
        break
    }
  }
  return Promise.reject(error)
}

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response.data
}, err)

export default service
