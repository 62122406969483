<template>
  <div>
    <div class="content mai">
    	<h1>カート</h1>
    	<div class="table" v-loading="listLoading">
    		<div class="item bord pc">
    			<i @click="select_all" class="radio" :class="{'checked': isAllChecked}"></i>
    			<div class="info">
    				<label @click="select_all">全て選択</label>
    				<span class="span" :class="{'act': checkedList.length > 0}" @click="remove_select">選択項目を削除</span>
    			</div>
    			<div class="ops">価格</div>
    		</div>
    		<div class="item" v-for="(item,i) in list" :key="i" >
          <i class="radio" :class="{'checked': item.check}" @click="check_item(item)"></i>
    			<div class="info">
    				<div class="img" :style="item.thumbnail + ';cursor: pointer;'" @click="ntfHome(item.groupId)"></div>
    				<div class="sp">
    					<h2 style="cursor: pointer;" @click="ntfHome(item.groupId)">{{item.title}}</h2>
    					<div class="mobile">
    						<span v-if="item.status == 'on_sale'">{{item.price}} MATIC<a>( ≈ ¥ {{formatYen(item.price)}})</a></span>
                <span v-if="item.status == 'not_on_sale'" style="color: #888888;">販売待機中</a></span>
                <span v-if="item.status == 'blocked'" style="color: #888888;">販売待機中</a></span>
                <span v-if="item.status == 'sold_out'" style="color: #888888;">販売完了</a></span>
    						<i class="del" @click.stop="remove_item(item)"></i>
    					</div>
    				</div>
    			</div>
    			<div class="ops pc">
    				<h2 v-if="item.status == 'on_sale'">{{item.price}} MATIC</h2>
    				<h3 v-if="item.status == 'on_sale'">( ≈ ¥ {{formatYen(item.price)}})</h3>
            <h3 v-if="item.status == 'not_on_sale'">販売待機中</h3>
            <h3 v-if="item.status == 'blocked'">販売待機中</h3>
            <h3 v-if="item.status == 'sold_out'">販売完了</h3>
    			</div>
    		</div>
        <div class="emptyInfo" v-if="list.length === 0">
          カートが空いてます
        </div>
    	</div>
    	<div v-if="list.length > 0" class="button pc pcBtn" :class="{'ok': get_can_buy()}" @click="showSubmitBox()">購入する</div>
    	<div class="button mobile mobileBtn" :class="{'ok': get_can_buy()}" @click="showSubmitBox()">
    		<p>{{checkedList.length}} つ選択</p>
    		<div>
    			<h2>{{totalPrice}}</h2>
    			<h3>MATIC</h3>
    			<h4>( ≈ {{formatYen(totalPrice)}} YEN)</h4>
    		</div>
    	</div>
    	<div class="goodsList pc" v-if="goodsList.length > 0">
    		<div class="til">オススメ</div>
    		<div class="list">
    			<div class="item" v-for="(item,i) in goodsList" :key="i" @click="ntfHome(item.groupId)">
    				<div class="img" :style="item.thumbnail"></div>
    				<h5>{{item.title}}</h5>
    				<div class="price">
    					<div>
    						<h4>{{item.price}} MATIC</h4>
    						<span>( ≈ ¥ {{formatYen(item.price)}})</span>
    					</div>
    					<i :class="item.isLiked ? 'heartAct' : ''"></i>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="openWindow" v-show="isShow">
    	<div class="window">
    		<i class="closeBtn1" @click="isShow = false"></i>
    		<div class="cen">
    			<h1>請求書をご確認ください</h1>
          <div v-loading="walletLoading">
            <p>あなたのウォレット</p>
            <div class="address border">
              <span>住所 </span>
              <h3>{{walletAddress}}</h3>
            </div>
            <!-- <p>Matic</p> -->
            <div class="balance border" style="margin-top:10px;">
              <div>
                <h2>Matic</h2>
                <h3>Polygon</h3>
              </div>
              <h4>{{forMatic(walletBalance)}} Matic</h4>
            </div>
          </div>
    			<p>購入<span>({{checkedList.length}}件選択)</span></p>
          <div class="swiper-father" v-loading="itemLoading">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="(item,i) in checkedList" :key="`bill${i}`">
                <div class="">
                  <div class="itemTitle"><span>Item {{i + 1}}</span><div>{{item.title}}</div></div>
                  <div class="bill border">
                    <div class="bill1">
                      <div>
                        <span>NFT発行手数料</span>
                        <span>ネットワーク手数料</span>
                      </div>
                      <div>
                        <span>{{item.price}} Matic</span>
                        <span>{{item.gasFee ? item.gasFee : 0}} Matic</span>
                      </div>
                    </div>
                    <div class="bill2">
                      <div>
                        小計
                      </div>
                      <div>
                        <h2>{{item.price + (item.gasFee ? item.gasFee : 0)}} Matic</h2>
                        <h3>( ≈ {{formatYen(item.price + (item.gasFee ? item.gasFee : 0))}} YAN)</h3>
                      </div>
                    </div>
                    <div class="bill2 bill3">
                      <div>
                        合計<br/>({{checkedList.length}}件)
                      </div>
                      <div>
                        <h2>{{totalPrice + totalGasFee}} Matic</h2>
                        <h3>( ≈ {{formatYen(totalPrice + totalGasFee)}} YAN)</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
    			<div class="check">
    				<i :class="{'che': readChecked}" @click="readChecked = !readChecked"></i>
    				<p><a target="_blank" href="https://www.3dhik.com/page/terms">利用規約</a>およびプライバシーポリシーを読み、同意します。</p>
    			</div>
    			<div class="btn" :class="{'ok': isCanConfirm}" @click="isCanConfirm && toBuy()">CONFIRM</div>
    		</div>
    	</div>
    </div>
    <div class="openWindow" v-show="isShowSign">
    	<div class="window">
        <i class="closeBtn1" v-if="!isSigning" @click="!isSigning && closeSign()"></i>
    		<div class="cen">
    			<h1>購入フラグ</h1>
          <p>NOTE: 署名したアイテムはキャンセルできません。</p>
          <div class="signBox">
            <div class="signItem" v-for="(item,i) in buyingList" :key="`sign${i}`">
              <div class="left">Item {{i + 1}}. {{item.title}}</div>
              <div class="right" @click="singleBuy(item)">
                <i :class="itemStateIcon(item.signState)"></i>
              </div>
            </div>
          </div>
    			<div class="btn" :class="!isSigning?'ok':'no_ok'" @click="!isSigning && closeSign()">Cancel</div>
    		</div>
    	</div>
    </div>
    <div class="bottomDiv pc"></div>
  </div>
</template>
<script>
  import { swiper, swiperSlide} from "vue-awesome-swiper"
  import 'swiper/css/swiper.css'
  import { putAction,getAction,deleteAction } from '@/network/manage'
  import { get_wallet, get_eth_chainId, get_eth_account, toEther, initItem, signItem } from '@/utils/utils'
  import numeral from 'numeral'

  export default {
    name: 'Cart',
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        url: {
          list: '/cart/',
          hl: '/matic_conversion/',
          goodsList: '/nft/explorer'
        },
        swiperOptions: {
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        },
        listLoading: false,
        list: [],
        goodsList: [],
        hl: 1,
        buyingList: [],
        isShow: false,
        walletLoading: false,
        walletAddress: '',
        walletBalance: 0,
        itemLoading: false,
        readChecked: true,
        walletChecked: false,
        itemChecked: false,
        isShowSign: false,
        isSigning: true,
				canBuy: true,
        buyNumber:0
      }
    },
    created() {
      this.loadHl()
      this.listData()
      this.loadGoodsList()
    },
    computed: {
      formatYen() {
        return function(price) {
          return numeral(price * this.hl).format('0,0.00')
        }
      },
      forMatic() {
        return function(matic) {
          return numeral(matic).format('0,0.0000')
        }
      },
      checkedList() {
        return this.list.filter(item => item.check)
      },
      isAllChecked() {
        return this.list.length > 0 && this.checkedList.length === this.list.length
      },
      totalPrice() {
        return this.checkedList.reduce((val, item) => val + item.price, 0)
      },
      totalGasFee() {
        return this.checkedList.reduce((val, item) => val + (item.gasFee ? item.gasFee : 0), 0)
      },
      isCanConfirm() {
        return this.readChecked && this.walletChecked && this.itemChecked
      },
      itemStateIcon() {
        return function(state) {
          return !state || state === 'notSigned' ? 'el-icon-refresh' : (state === 'finished' ? 'el-icon-finished' : 'el-icon-loading')
        }
      }
    },
    methods: {
      getWallet() {
        this.walletChecked = false
        this.walletLoading = true
        const that = this
        get_wallet().then(res => {
          this.walletChecked = true
          that.walletAddress = res.address
          that.walletBalance = res.ether
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          that.walletLoading = false
        })
      },
      initPurchasingItem() {
        const that = this
        let promiseArr = []
        that.checkedList.forEach((item, i) => {
          promiseArr[i] = new Promise((resolve, reject) => {
            initItem(item.groupId, item.price)
            .then(res => {
              that.$set(item, 'metadata_url', res.metadata_url)
              that.$set(item, 'gasFee', res.gasFee)
              resolve(res)
            })
            .catch(err => {
							if(err.data != null) {
									that.$notification.error({title: 'システムメッセージ', message: `[item:${i+1}] ${err.data.message}`})
							} else {
									that.$notification.error({title: 'システムメッセージ', message: `[item:${i+1}] ${err}`})
							}
              reject(err)
            })
          })
        })
        that.itemChecked = false
        that.itemLoading = true
        Promise.all(promiseArr)
        .then(() => {that.itemChecked = true})
        .catch(err => err)
        .finally(() => {that.itemLoading = false})
      },
      ntfHome(id) {
        this.$router.push({path:'ntfHome', query: {id: id}})
      },
      get_can_buy() {
        if(this.checkedList.length > 0
        && this.checkedList.filter(item => item.status != 'on_sale').length == 0) {
          return true
        }
        return false
      },
      deleteCart(cartItems) {
        const that = this
        let promiseArr = []
        cartItems.forEach((item, i) => {
          promiseArr[i] = new Promise((resolve, reject) => {
            deleteAction(that.url.list + item.cartId)
            .then(res => {
              if (res.code === 200) {
                resolve(res)
              } else {
                reject(res)
              }
            }).catch(err => {
              reject(err)
            })
          })
        })
        return Promise.all(promiseArr)
      },
      remove_item(item) {
        const that = this;
        that.$messageBox.confirm('本当に選択したアイテムを削除しますか？', 'warning', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
          callback: (action, instance) => {
            if (action === 'confirm') {
              const loading = that.$loading.service()
              that.deleteCart([item])
              .then(res => {
                that.listData()
                that.$store.dispatch('UpdateCartNumber')
              })
              .catch(err => {
                that.$message.error(err.message)
              })
              .finally(() => {
                loading.close()
              })
            }
          }
        })
      },
      remove_select() {
        const that = this
        if(that.checkedList.length === 0) {
          that.$notification.error({title: 'システムメッセージ', message: 'Please select the item to delete'})
        } else {
          that.$messageBox.confirm('本当に選択したアイテムを削除しますか？', 'warning', {
            confirmButtonText: '削除',
            cancelButtonText: 'キャンセル',
            callback: (action, instance) => {
              if (action === 'confirm') {
                const loading = that.$loading.service()
                that.deleteCart(that.checkedList)
                .then(res => {
                  that.listData()
                  that.$store.dispatch('UpdateCartNumber')
                })
                .catch(err => {
                  that.$message.error(err.message)
                })
                .finally(() => {
                  loading.close()
                })
              }
            }
          })
        }
      },
      select_all() {
        let state = !this.isAllChecked
        this.list.forEach(item => {
          this.$set(item, 'check', state)
        })
      },
      check_item(item) {
        this.$set(item, 'check', !item.check)
      },
      loadHl() {
        const that = this;
        getAction(this.url.hl).then(res => {
          if (res.code == 200) {
            that.hl = res.data.maticToYen
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        })
      },
      listData() {
        this.listLoading = true
        const that = this
        getAction(this.url.list, {size:1000, page:1})
        .then(res => {
          if (res.code == 200) {
            res.data.contents.forEach(item => {
              item.thumbnail = `background-image:url('${item.thumbnail}')`
              item.price = item.price / 1000.00
            })
            that.list = res.data.contents
          } else {
            that.$message.error(res.message)
          }
        })
        .catch(err => {
          that.$message.error(err.message)
        })
        .finally(() => {
          that.listLoading = false
        })
      },
      loadGoodsList() {
        const that = this;
        getAction(this.url.goodsList,{size:4,sortBy:'HOT'}).then((res) => {
          if (res.code == 200) {
            let obj = res.data.contents;
            for(let i = 0; i < obj.length; i++){
              obj[i].thumbnail = `background-image:url('${obj[i].thumbnail}')`
              obj[i].price = obj[i].price * 0.001
            }
            that.goodsList = obj
          } else {
            that.$message.error(res.message)
          }
        }).catch(res => {
          that.$message.error(res.message)
        })
      },
      async showSubmitBox() {
        const that = this
        if(!this.get_can_buy()) {
          that.$notification.error({title: 'システムメッセージ', message: "購入できない作品が含まれています。"})
          return;
        }
        if (that.checkedList.length > 0) {
          for(let item of that.checkedList) {
            if(item.status == 'not_on_sale') {
              that.$notification.error({title: 'システムメッセージ', message: "作品 ：" + item.title + "販売待機中"})
              return;
            }
            if(item.status == 'sold_out') {
              that.$notification.error({title: 'システムメッセージ', message: "作品 ：" + item.title + "販売完了"})
              return;
            }
          }
          const loading = that.$loading.service()
          const wallet = await get_wallet()
          if (wallet.ether < that.totalPrice) {
            loading.close()
            that.$notification.warning({title: 'システムメッセージ', message: '申し訳ありませんが、残高が不足しています'})
            return;
          }
          Promise.all([get_eth_chainId(), get_eth_account()])
          .then(res => {
            if (res[0] !== process.env.VUE_APP_CHAIN_ID) {
              that.$alert(`ネットワークを変更してください ${process.env.VUE_APP_NETWORK}.`, 'ネットワークをご確認ください。', {type: 'error'})
            } else {
              that.isShow = true
              that.readChecked = true
              that.getWallet()
              that.initPurchasingItem()
            }
          })
          .catch(err => {
            that.$notification.error({title: 'システムメッセージ', message: err.message})
          })
          .finally(() => {
            loading.close()
          })
        } else {
          that.$notification.warning({title: 'システムメッセージ', message: 'お求めの商品をお選びください'})
        }
      },
      async toBuy() {
        const that = this
        if (that.walletBalance >= that.totalPrice) {
          that.buyingList = that.checkedList
          that.buyNumber = that.buyingList.length
          that.isShow = false
          that.isShowSign = true
					that.isSigning = true
					that.canBuy = false
					for(let item of that.buyingList) {
						await that.itemSign(item)
					}
					that.canBuy = true
          that.isSigning = false
          if(that.buyNumber == 0) {
            this.$router.replace('/CollectionCollected')
          }
        } else {
          that.$notification.warning({title: 'システムメッセージ', message: '申し訳ありませんが、残高が不足しています'})
        }
      },
      itemSign(item) {
        const that = this
        return new Promise((resolve, reject) => {
          that.$set(item, 'signState', 'signing')
          signItem(item)
          .then(() => {
            that.$notification.success({title: 'システムメッセージ', message: '購入に成功しました'})
            that.buyNumber = that.buyNumber-1
            that.deleteCart([item]).then(res => {
              that.listData()
              that.$store.dispatch('UpdateCartNumber')
            })
            that.$set(item, 'signState', 'finished')
            resolve()
          })
          .catch(err => {
						console.log(err);
						that.$set(item, 'signState', 'notSigned')
						if(err.error != null) {
							if(err.error.data.code < 0) {
								that.$notification.warning({title: 'システムメッセージ', message: "ネットワークエラーが発生しました。しばらく経ってからもう一度お試しください。"})
							} else {
								that.$notification.warning({title: 'システムメッセージ', message: err.error.data.message})
							}
						} else if(err.message != undefined) {
							that.$notification.error({title: 'システムメッセージ', message: err.message})
						} else {
							that.$notification.error({title: 'システムメッセージ', message: err})
						}
            resolve()
          })
        })
      },
      singleBuy(item) {
				const that = this
				if(that.canBuy) {
					if (!item.signState || item.signState === 'notSigned') {
					  that.isSigning = true
					  that.itemSign(item)
					  .finally(() => {
					    that.isSigning = false
					  })
					}
				} else {
					that.$notification.warning({title: 'システムメッセージ', message: "最後の取引はまだ完了していませんので、お待ちください。"})
				}
      },
      closeSign() {
        this.isShowSign = false
      }
    }
  }
</script>

<style scoped>
  .mai .button{height:40px;margin:50px auto;background: #D0D0D0;border-radius: 2px;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;}
  .ok{background: #00C999 !important;cursor: pointer;}
  .no_ok {cursor: no-drop;}
  .mai h1{font-size: 20px;font-weight: normal;margin:30px 0 20px 0;}
  .table{width:100%;}
  .table .item{display: flex;align-items: center;padding:18px 0;border-bottom:1px solid #F0F0F0;}
  .table .bord i{height:30px !important;}
  .table .item i{flex-shrink: 0;height:50px;width:40px;background-image: url(../assets/img/radioUnChecked.png);background-position:left center;background-repeat: no-repeat;}
  .table .item .checked{background-image: url(../assets/img/select_check.png);}
  .table .item .info{width:100%;display: flex;align-items: center;}
  .table .item .info .img{width:100px;height:57px;background-size: cover;background-position: center;flex-shrink: 0;}
  .table .item .info .sp{margin-left:30px;display: flex;flex-direction: column;}
  .table .item .info .sp h2{font-weight: normal;font-size: 15px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .table .item .info .span{margin-left:30px;font-size: 13px;color:#999999;}
  .table .item .info .act{cursor: pointer;color:#000000;}
  .table .item .info label{font-size: 13px;cursor: pointer;}
  .table .item .ops{flex-shrink: 0;display: flex;flex-direction: column;align-items: flex-end;justify-content: center;color:#888888;font-size: 13px;}
  .table .item .ops h2{font-size: 15px;font-family: 'SF UI Display bold';color:#000000;}
  .table .item .ops h3{font-size: 15px;color: #888888;font-family: 'SF UI Display bold';}
  .openWindow{width:100vw;height:100vh;position: fixed;left:0px;top:0px;z-index:101;display: flex;justify-content: center;align-items: center;}
  .openWindow .window{background-color: #ffffff;border: 2px solid #888888;border-radius: 15px;display: flex;flex-direction: column;}
  .openWindow .window .closeBtn1{position:fixed;width:50px;height:50px;background-image: url(../assets/img/close_btn.png);cursor: pointer;background-repeat: no-repeat;background-position: center;margin-top:20px;}
  .openWindow .window .cen{display: flex;width:100%;margin:auto;flex-direction: column;align-items: center;justify-content: center;}
  .openWindow .window .cen h1{font-weight: normal;color: #4A4A4A;}
  .openWindow .window .cen p{margin:6px 0;}
  .openWindow .window .cen p span{color:#00C999;margin-left:5px;}
  .openWindow .window .cen .border{border: 1px solid #F0F0F0;border-radius: 20px;width:100%;}
  .openWindow .window .cen .btn{padding:10px 20px;border-radius: 10px;font-size: 15px;font-family: 'SF UI Display thin';background: #D0D0D0;color:#ffffff;margin:16px 0 20px 0;}
  .openWindow .window .cen .address{display: flex;align-items: center;height:50px;width:440px;}
  .openWindow .window .cen .address span{flex-shrink: 0;}
  .openWindow .window .cen .address h3{width:100%;text-align: center;color: #888888;font-size: 14px;font-weight: normal;font-family: 'SF UI Display thin';word-break: break-all;}
  .openWindow .window .cen .balance{display: flex;height:50px;width:440px;align-items: center;padding:0px 20px 0px 70px;background-image: url(../assets/img/link.png);background-repeat: no-repeat;background-position: left 20px center;}
  .openWindow .window .cen .balance div{flex-shrink: 0;display: flex;flex-direction: column;}
  .openWindow .window .cen .balance div h2{color: #4A4A4A;font-weight: normal;line-height: 1;margin-bottom:3px;}
  .openWindow .window .cen .balance div h3{font-size: 14px;color: #00C999;font-weight: normal;font-family: 'SF UI Display thin';line-height: 1;}
  .openWindow .window .cen .balance h4{width:100%;text-align: right;font-family: 'SF UI Display thin';color: #888888;font-weight: bold;}
  .openWindow .window .cen .bill .bill1{display: flex;justify-content: space-between;border-bottom:1px solid #F0F0F0;padding:16px 20px;}
  .openWindow .window .cen .bill .bill1 div{display: flex;flex-direction: column;color: #888888;line-height:1.4;}
  .openWindow .window .cen .bill .bill1 div span{font-family: 'SF UI Display thin';font-weight: bold;text-align: right;}
  .openWindow .window .cen .bill .bill1 div:nth-child(1){color: #4A4A4A;}
  .openWindow .window .cen .bill .bill2{display: flex;justify-content: space-between;align-items: center;padding:16px 20px;}
  .openWindow .window .cen .bill .bill2 div{color: #00C999;line-height: 1.4;}
  .openWindow .window .cen .bill .bill2 div:nth-child(2){display: flex;flex-direction: column;align-items: flex-end;justify-content: center;color: #888888;}
  .openWindow .window .cen .bill .bill2 div h2{font-family: 'SF UI Display thin';}
  .openWindow .window .cen .bill .bill2 div h3{font-size: 14px;font-family: 'SF UI Display thin';}
  .openWindow .window .cen .bill .bill3{border-top:1px solid #F0F0F0;}
  .openWindow .window .cen .check{display: flex;align-items: center;padding: 5px 25px;}
  .openWindow .window .cen .check i{width:26px;height:26px;margin-right:10px;background-position: center;background-repeat: no-repeat;border: 1px solid #D0D0D0;border-radius: 5px;}
  .openWindow .window .cen .check .che{background-image: url(../assets/img/check.png);}
  .openWindow .window .cen .check a{color:#1192CA;}
  .openWindow .itemTitle{display: flex;padding:10px 20px;color:#1192CA;}
  .openWindow .itemTitle span{flex-shrink: 0;margin-right:10px;}
  .openWindow .itemTitle div{width: 100%;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;}

  .openWindow .window .cen .signBox{border:1px solid #F0F0F0;border-radius: 20px;width:600px;margin:20px 0;line-height:1.5;color: #888888;}
  .openWindow .window .cen .signBox .signItem{border-bottom:1px solid #f0f0f0;display: flex;padding:10px 20px;align-items: center;}
  .openWindow .window .cen .signBox .signItem .right{width:50px;flex-shrink: 0;margin-left:20px;font-size: 24px;text-align: center;cursor: pointer;}
  .openWindow .window .cen .signBox .signItem .left{width:100%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
  .openWindow .window .cen .signBox .signItem:last-child{border-bottom:none;}

  .swiper-container{}
  .swiper-father{margin:auto;position: relative;}
  .swiper-button-prev{transform:scale(0.8);filter: grayscale(100%);}
  .swiper-button-next{transform:scale(0.8);filter: grayscale(100%);}
  .goodsList .list{display: grid;}
  .goodsList .til{font-size: 18px;text-align: center;margin-bottom:40px;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;}
  .goodsList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodsList .list .item .price div{width:100%;display: flex;flex-direction: column;}
  .goodsList .list .item .price h4{font-family: 'SF UI Display bold';line-height:1;}
  .goodsList .list .item .price span{font-family: 'SF UI Display bold';line-height:1;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/heart.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/heartAct.png);}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:140px 0;border-bottom: 1px solid #F0F0F0;margin-bottom:20px;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
  	.table{border-top:1px solid #F0F0F0;}
  	.table .item i{background-image: url(../assets/img/select_mobile.png);width:8vw;}
  	.table .item .checked{background-image: url(../assets/img/select_check_black.png);}
  	.table .item .info .sp{width:100%;margin-left:3vw;}
  	.table .item .info .sp div{display: flex;align-items:center;}
  	.table .item .info .sp div span{font-size:14px;width:100%;}
  	.table .item .info .sp div span a{font-size:12px;margin-left:5px;}
  	.table .item .info .sp div .del{flex-shrink: 0;background-image: url(../assets/img/delete_black.png);background-repeat: no-repeat;background-position: right bottom;width:20px;height:20px;}
  	.table .item .info .sp h2{font-size:13px;margin-bottom:5px;}
    .mai .button{height:auto;background: #D0D0D0;padding:10px 0;color:#ffffff;display: flex;flex-direction: column;justify-content: center;align-items: center;position: fixed;bottom:0;left:0;width:100vw;margin:0;}
    .mai .button p{font-size:14px;}
    .mai .button div{display: flex;align-items: flex-end;justify-content: center;font-family: 'SF UI Display light';}
    .mai .button div h2{font-size:19px;line-height:1;font-weight: normal;}
    .mai .button div h3{font-size:15px;line-height:1;margin:0px 10px;font-weight: normal;}
    .mai .button div h4{font-size:14px;line-height:1;font-weight: normal;}
    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .til{margin-bottom:20px;font-size:16px;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:15px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:12px;}
    .openWindow .window{width:100vw;height:100vh;border:none;border-radius: 0;}
    .openWindow .window .cen .address{width:86%;}
    .openWindow .window .cen .balance{width:86%;}
    .openWindow .window .closeBtn1{}
    .openWindow .window .cen h1{font-size: 18px;margin-bottom:12px;}
    .openWindow .window .cen .address span{font-size: 12px;margin:0 17px;}
    .openWindow .window .cen .address h3{font-size:12px;}
    .openWindow .window .cen .balance div h2{font-size: 12px;}
    .openWindow .window .cen .balance h4{font-size: 12px;}
    .openWindow .window .cen .bill .bill1{padding: 10px 20px;}
    .openWindow .window .cen .bill .bill2{padding: 10px 20px;}
    .openWindow .window .cen .bill .bill1 div:nth-child(1){font-size: 12px;}
    .openWindow .window .cen .bill .bill1 div{font-size: 12px;}
    .openWindow .window .cen .bill .bill2 div{font-size: 12px;}
    .openWindow .window .cen .bill .bill2 div h2{font-size: 12px;}
    .swiper-button-prev{transform:scale(0.6);left:0px;}
    .swiper-button-next{transform:scale(0.6);right:0px;}
    .swiper-father{width:100%;}
    .swiper-container{width:84%;margin:auto;}
  	.content{margin:0 4vw;}
    .emptyInfo{padding:20vw 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.openWindow .window{width:700px;}
  	.openWindow .window .closeBtn1{margin-left:620px;}
  	.openWindow .window .cen h1{font-size: 18px;margin-bottom:12px;}
    .openWindow .window .cen .address{width:380px;}
    .openWindow .window .cen .balance{width:380px;}
  	.openWindow .window .cen .address span{font-size: 12px;margin:0 17px;}
  	.openWindow .window .cen .balance div h2{font-size: 12px;}
  	.openWindow .window .cen .balance h4{font-size: 12px;}
    .openWindow .window .cen .bill .bill1{padding: 10px 20px;}
    .openWindow .window .cen .bill .bill2{padding: 10px 20px;}
  	.openWindow .window .cen .bill .bill1 div:nth-child(1){font-size: 12px;}
  	.openWindow .window .cen .bill .bill1 div{font-size: 12px;}
  	.openWindow .window .cen .bill .bill2 div{font-size: 12px;}
  	.openWindow .window .cen .bill .bill2 div h2{font-size: 12px;}
    .mai .button{width:380px;height:40px;}
    .swiper-father{width:480px;}
    .swiper-container{width:380px;margin:auto;}
  	.bottomDiv{height:100px;}
    .emptyInfo{padding:80px 0;}
  }
  @media(min-width: 1200px){
  	.openWindow .window{width:800px;}
  	.openWindow .window .closeBtn1{margin-left:720px;}
  	.openWindow .window .cen h1{font-size: 26px;}
  	.openWindow .window .cen .address span{font-size: 18px;margin:0 17px;}
  	.openWindow .window .cen .balance div h2{font-size: 18px;}
  	.openWindow .window .cen .balance h4{font-size: 18px;}
  	.openWindow .window .cen .bill .bill1 div:nth-child(1){font-size: 18px;}
  	.openWindow .window .cen .bill .bill1 div{font-size: 18px;}
  	.openWindow .window .cen .bill .bill2 div{font-size: 18px;}
  	.openWindow .window .cen .bill .bill2 div h2{font-size: 18px;}
  	.openWindow .window .cen .check{margin-top:0px;}
  	.content{width:1200px;margin:auto;}
  	.mobile{display: none !important;}
  	.mai .button{width:400px;}
    .swiper-father{width:540px;}
    .swiper-container{width:440px;margin:auto;}
  	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodsList .list .item .img{width:285px;height:156px;}
  	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodsList .list .item h5{height:48px;}
  	.bottomDiv{height:200px;}
  }
</style>
