<template>
  <div>
    <div class="content heard_url">
    	<img :src="headimgurl"/>
    </div>
    <div class="content wallet_money">
    	￥ {{wallet_money(rate * matic)}}
    </div>
    <div class="content">
    	<div class="wallet_address">
    		<div class="wallet_address_item_inp span" v-if="data">
    			{{data.walletAddress[0]}}
    		</div>
    		<div class="wallet_address_item_copy copy" @click="copyAddress()">
    			<img src="../assets/img/copy.png"/> <span class="pc" style="flex-shrink: 0;">コピー</span>
    		</div>
    	</div>
    </div>

    <div class="content">
    	<div class="wallet_but">
    		<div class="wallet_but_item pc" @click="showQr()">
    			<img src="../assets/img/receive.png"/>
    			<div class="wallet_but_font">受信</div>
    		</div>
        <div class="wallet_but_item mobile" @click="open_qrcode()">
          <img src="../assets/img/receive.png"/>
          <div class="wallet_but_font">受信</div>
        </div>
    		<div class="wallet_but_item" @click="purchase()">
    			<img src="../assets/img/purchase.png"/>
    			<div class="wallet_but_font">購入</div>
    		</div>
        <div class="wallet_but_item" @click="swap()">
        	<img src="../assets/img/swap.png"/>
        	<div class="wallet_but_font">交換</div>
        </div>
    	</div>
    </div>
    <div class="content">
    	<div class="wallet_list_item">
    		<div class="wallet_icon"><img src="../assets/img/link.png"/></div>
    		<div class="wallet_name">Polygon</div>
    		<div class="wallet_number">{{show_matic()}}</div>
    		<div class="wallet_unit">MATIC</div>
    		<div>
    			<div class="wallet_set" @click="show_tip()">
    			</div>
    			<div class="wallet_menu" v-if="tipShow">
    				<div class="wallet_menu_item wallet_border_bottom" @click="purchase()">
    					<img src="../assets/img/wallet_car.png"/>
    					<div class="wallet_menu_item_til">購入</div>
    				</div>
    				<div class="wallet_menu_item wallet_border_bottom" @click="show_tans()">
    					<img src="../assets/img/wallet_book.png"/>
    					<div class="wallet_menu_item_til">アクティビティ</div>
    				</div>
    				<div class="wallet_menu_item" @click="swap()">
    					<img src="../assets/img/wallet_bridge.png"/>
    					<div class="wallet_menu_item_til">交換</div>
    				</div>
    			</div>
    		</div>

    	</div>

    	<div class="activity_maxHeight" v-if="is_show_trans" v-loading="listLoading">

    		<div class="wallet_activity" v-for="(item,i) in transDatas" :key="i">
    			<div :class="item.icon"></div>
    			<div class="activity_type_time">
    				<div class="act_type">{{item.type}}</div>
    				<div class="act_time">{{item.time}}</div>
    			</div>
    			<div class="activity_money">{{item.symbol}}{{item.maticAmount}}</div>
    			<div class="activity_unit">MATIC</div>
    		</div>

    	</div>
    </div>

    <div class="receive_container pc" v-if="isShow">
    	<div class="receive_container_ce">
    		<img class="close_btn" src="../assets/img/close_btn.png" @click="showQr()"/>
    		<div class="receive_til">受信</div>
    		<div class="receive_address" v-if="data">
    			<div class="address_til">住所</div>
    			<div class="address_info">{{data.walletAddress[0]}}</div>
    			<div class="address_copy" @click="copyAddress()"></div>
    		</div>
    		<div class="qr_code">QRコード</div>
    		<div class="qrcode_img">
    			<img :src="qrcode" />
    		</div>
    	</div>
    </div>

    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home" @click="goHome()"></i>
    	<i class="jt"></i>
    	<a>ウォレット</a>
    </div>
  </div>
</template>

<script>
  import { putAction,getAction } from '@/network/manage'
  import { ethers } from 'ethers'
  import numeral from 'numeral'
  import qrcode from 'qrcode'
  import { get_eth_chainId } from '@/utils/utils'
  import axios from "@/utils/requestExternal"
  import moment from 'moment'
  export default {
    name: 'Wallet',
    components: {
    },
    data() {
      return {
        url: {
          user: '/user/',
          rate: '/matic_conversion/'
        },
        data:null,
        headimgurl:null,
        matic:0,
        rate:0,
        isShow:false,
        tipShow:false,
        qrcode:'',
        transDatas:[],
        is_show_trans:false,
        listLoading:false
      }
    },
    computed: {

    },
    created(){
      this.loadDetail();
    },
    methods:{
      loadDetail(){
        const that = this
        get_eth_chainId().then(res => {
        if (res !== process.env.VUE_APP_CHAIN_ID) {
            that.$alert(`ネットワークを変更してください ${process.env.VUE_APP_NETWORK}.`, 'ネットワークをご確認ください。', {type: 'error'})
            that.$router.back()
          }
        })
        this.initUserInfo()
        //查询余额
        this.initBalance()
        //查询汇率
        this.initRate()
      },
      open_qrcode() {
        this.$router.push({path:'qrcode'})
      },
      initUserInfo() {
        const that = this;
        getAction(this.url.user,{}).then((res) => {
          if (res.code == 200) {
            this.headimgurl = res.data.profileImage !== null ? res.data.profileImage : require('assets/img/headimg.jpg')
            that.data = res.data
            qrcode.toDataURL(res.data.walletAddress[0], (err,url) => {
                that.qrcode = url
            })
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      async initBalance() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const address = await signer.getAddress()
        const balance = await provider.getBalance(address)
        //转成matic
        const costEther = await ethers.utils.formatEther(balance)
        this.matic = costEther
      },
      initRate() {
        const that = this;
        getAction(this.url.rate,{}).then((res) => {
          if (res.code == 200) {
            that.rate = res.data.maticToYen
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      wallet_money(txt) {
        return numeral(txt).format('0,0.00')
      },
			wallet_record(txt) {
			  return numeral(txt).format('0,0.00000000')
			},
      show_matic() {
        return numeral(this.matic).format('0,0.0000')
      },
      toTop(){
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      },
      goHome() {
        this.$router.push({path:'home'})
      },
      copyAddress() {
        var oInput = document.createElement('input')
        oInput.value = this.data.walletAddress[0]
        document.body.appendChild(oInput)
        oInput.select()
        document.execCommand("Copy")
        oInput.style.display='none'
        this.$message.success('コピーしました。')
      },
      showQr() {
        this.isShow = !this.isShow
      },
      show_tans() {
        this.is_show_trans = !this.is_show_trans
        if(this.is_show_trans == true) {
            this.transactionRecord();
        }
      },
      purchase() {
        window.open("https://app.bitbank.cc/dealer/matic")
      },
      swap() {
        window.open("https://wallet.polygon.technology/")
      },
      show_tip() {
        this.tipShow =  !this.tipShow
      },
      async transactionRecord() {
        this.transDatas = []
        const that = this
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const address = await signer.getAddress()
        const module = 'account'
        const action = 'txlist'
        const startblock = '0'
        const endblock = '99999999'
        const page = '1'
        const offset = '200'
        const sort = 'desc'
        const apikey = '2MI7KAREBY8H5HFPZE983BX687YD7KRFYU'
        const url = "https://api.polygonscan.com/api?module=" + module
         + "&action=" + action + "&address=" + address + "&startblock=" + startblock
         + "&endblock=" + endblock + "&page=" + page + "&offset=" + offset + "&sort=" + sort
         + "&apikey=" + apikey
         this.listLoading = true

       axios({
         url: url,
         method:'get'
       }).then(res => {
            for(let i=0;i < res.result.length; i++) {
              //翻译时间
              res.result[i].time = moment(res.result[i].timeStamp * 1000).format('YYYY.MM.DD HH:mm')
              //类型判断符号判断
              if(res.result[i].to.toLowerCase() === address.toLowerCase()) {
                //接收  加
                res.result[i].type = 'Receive'
                res.result[i].icon = 'activity_type receive'
                res.result[i].symbol = ''
              } else if(res.result[i].from.toLowerCase() === address.toLowerCase()){
                //发出 减
                res.result[i].type = 'Send'
                res.result[i].icon = 'activity_type send'
                res.result[i].symbol = '-'
              }
              //matic转换
              res.result[i].maticAmount = that.wallet_money(ethers.utils.formatEther(res.result[i].value))
							if(res.result[i].maticAmount > 0)
								that.transDatas.push(res.result[i])
            }
            that.listLoading = false
        })
      }
    }
  }
</script>

<style scoped>
body{background: #ffffff;overflow-x: hidden;}
*{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
input,select{outline:none;}
a{text-decoration: none;}
.more{background: #00C999;color:#ffffff;border-radius: 35px;height:44px;width: 148px;margin:60px auto 0 auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}
.paths{display: flex;padding:36px 0;}
.paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
.paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
.paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
.paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
@media (max-width: 800px) {
	body{padding-top:60px;}
	.content{margin:0 16px;}
	.bottomDiv{height:100px;}
  .pc{display: none !important;}
  .heard_url {text-align: center;padding: 30px 0 5px 0;}
  .heard_url img {width:80px;height:80px;border-radius: 50%;}
  .wallet_money {font-family: SF UI Display light;font-size: 18px;color: #888888;text-align: center;}
  .wallet_address {margin:auto;width:260px;display: flex;justify-content:space-between;margin-top:10px;}
  .span {font-size: 13px;font-family: SF UI Display light;color: #888888;letter-spacing:-0.6px;width:100%;word-break: break-all;}
  .wallet_address_item_copy img {width:17px;height: 17px;margin-right:8px;}
  .wallet_address_item_copy {font-size: 14px;color: #00C999;margin-left:5px;display: flex;align-items: center;width:20px;flex-shrink: 0;}
  .wallet_but {display: flex;width:250px;margin:auto;justify-content:space-around;margin-top:30px;}
  .wallet_but_item {display: flex;flex-direction:column;align-items: center;}
  .wallet_but_item img {width:50px;}
  .wallet_but_font {
  	font-size: 13px;
  	font-family: SF UI Display light;
  	color: #888888;
  	letter-spacing:-0.6px;
  	line-height: 36px;
  }
  .wallet_list_item {
  	width:330px;
  	margin: auto;
  	display: flex;
  	align-items: center;
  	padding: 10px 0;
  	margin-top: 10px;
  	border-bottom: 2px solid #F0F0F0;
  }
  .wallet_icon {
  	width:40px;
  	height:40px;
  	border:1px solid #D0D0D0;
  	border-radius: 50%;
  	display: flex;
  	align-items: center;
  	justify-content:center;
  	flex-shrink: 0;
  }
  .wallet_icon img{
  	width:28px;
  }

  .wallet_name {
  	width:70px;
  	margin-left: 10px;
  	font-size: 14px;
  	font-family: SF UI Display light;
  	color: #888888;
  	flex-shrink: 0;
  }
  .wallet_number {
  	width:100%;
  	font-family:'SF UI Display ultralight';
  	font-size: 15px;
  	color: #000000;
  	text-align: right;
  }
  .wallet_unit {
  	width:50px;
  	margin-left:10px;
  	font-family:'SF UI Display light';
  	font-size: 14px;
  	color: #4A4A4A;
  	flex-shrink: 0;
  }
  .wallet_set {
  	width:40px;
  	height: 18px;
  	flex-shrink: 0;
  	background-image:url(../assets/img/remark.png);
  	background-repeat: no-repeat;
  	background-position: right center;
  }

  .act {
  	background-image:url(../assets/img/remarkAct.png) !important;
  }

  .wallet_menu {
  	width:120px;
  	background-color: #F0F0F0;
  	position: absolute;
  	margin-top:5px;
  	margin-left:-80px;
  }

  .wallet_menu_item {
  	display: flex;
  	line-height: 33px;
  	align-items: center;
  }

  .wallet_menu_item img{
  	width: 10px;
  	height: 11px;
  	flex-shrink: 0;
  	margin-left: 10px;
  }

  .wallet_menu_item_til {
  	width:100%;
  	font-size: 10px;
  	font-family: SF UI Display light;
  	color: #000000;
  	margin-left: 10px;
  }

  .wallet_border_bottom {
  	border-bottom: 1px #FFFFFF solid;
  }

  .activity_maxHeight {
  	width:340px;
    height:250px;
  	max-height:250px;
  	overflow-y: auto;
  	margin:auto;
  	overflow-x: hidden;
  	margin-top: 20px;
  }

  .wallet_activity {
  	display: flex;
  	width:330px;
  	margin:auto;
  	align-items: center;
  	margin-top: 0px;
  }

  .activity_type {
  	width:30px;
  	height:50px;
  	background-repeat: no-repeat;
  	background-position: center center;
  	flex-shrink: 0;
  }

  .receive {
  	background-image: url(../assets/img/downloadGray.png);
  }

  .purchase {
  	background-image: url(../assets/img/cartGray.png);
  }

  .bridge {
  	background-image: url(../assets/img/money.png);
  }

  .send {
  	background-image: url(../assets/img/upjt.png);
  }

  .activity_type_time {
  	margin-left: 15px;
  	width: 120px;
  	flex-shrink: 0;
  }

  .activity_type_time .act_type {
  	font-family: SF UI Display light;
  	color: #888888;
  	font-size: 14px;
  }

  .activity_type_time .act_time {
  	font-family: SF UI Display light;
  	color: #888888;
  	font-size: 14px;
  }

  .activity_money {
  	width: 100%;
  	font-family: 'SF UI Display ultralight';
  	font-size: 15px;
  	color: #000000;
  	text-align: right;
  }

  .activity_unit {
  	width: 60px;
  	margin-left: 15px;
  	font-family: 'SF UI Display light';
  	font-size: 14px;
  	color: #4A4A4A;
  	flex-shrink: 0;
  }

  .receive_til {
  	margin-top:30px;
  	text-align: center;
  	font-size: 20px;
  	color: #4A4A4A;
  	font-family: 'SF UI Display blod';
  }

  .receive_address {
  	width:340px;
  	margin: auto;
  	border:1px #F0F0F0 solid;
  	margin-top:40px;
  	border-radius: 20px;
  	height: 51px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  }

  .address_info {
  	text-align: right;
  	font-family: 'SF UI Display light';
  	font-size: 13px;
  	color: #888888;
  }

  .address_copy {
  	margin-left:15px;
  	width:21px;
  	height:20px;
  	background-image: url(../assets/img/copy.png);
  	background-repeat: no-repeat;
  	background-position: center center;
    cursor: pointer;
  }

  .qrcode_img {
  	width:250px;
  	height:250px;
  	border:1px #f0f0f0 solid;
  	margin: auto;
  	border-radius: 20px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	margin-top:15px;
  }

  .qrcode_img img {
  	width:230px;
  }

  .bridge_til {
  	margin:50px auto 25px auto;
  	font-size: 20px;
  	color: #4A4A4A;
  	letter-spacing: -0.6px;
  	font-family: 'SF UI Display blod';
  	text-align: center;
  }

  .bridge_max {
  	width:340px;
  	text-align: right;
  	margin: auto;
  	color: #1192CA;
  	font-size: 16px;
  	font-family: 'SF UI Display light';
  }

  .bridge_max span {
  	cursor: pointer;
  }

  .bridge_from {
  	width:340px;
  	height: 50px;
  	border:1px #D0D0D0 solid;
  	border-radius: 10px;
  	margin: auto;
  	display: flex;
  	align-items: center;
  }

  .bridge_from .bridge_icon {
  	width: 60px;
  	height: 40px;
  	border-radius: 50%;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	margin-left:20px;
  	flex-shrink: 0;
  }

  .bridge_info {
  	width: 60px;
  	margin-left:10px;
  	font-family: 'SF UI Display light';
  	font-size: 16px;
  	color: #888888;
  	flex-shrink: 0;
  }

  .bridge_money {
  	width: 100%;
  }
  .bridge_money input {
  	border:none;
  	text-align: right;
  	font-family: 'SF UI Display light';
  	font-size: 16px;
  	color: #888888;
  	width: 90%;
  }

  .to {
  	width:335px;
  	margin: auto;
  	color: #00C999;
  	font-size: 16px;
  	font-family: 'SF UI Display light';
  	padding-left: 5px;
  }

  .bridge_tip {
  	width:335px;
  	margin: auto;
  	font-size: 15px;
  	color: #888888;
  	font-family: 'SF UI Display light';
  	margin-bottom: 20px;
  	padding-left: 5px;
  }

  .comfirm_btn {
  	width:80px;
  	height: 40px;
  	background-color: #00C999;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	border-radius: 10px;
  	font-family: 'SF UI Display semibold';
  	font-size: 15px;
  	color: #FFFFFF;
  	margin: auto;
  	margin-top: 20px;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px){
	body{padding-top:80px;}
  .mobile{display: none !important;}
	.content{width:800px;margin:auto;}
	.bottomDiv{height:200px;}
	.heard_url {text-align: center;padding: 40px 0;}
	.heard_url img {width:80px;height:80px;border-radius: 50%;}
	.wallet_money {font-family: SF UI Display light;font-size: 18px;color: #888888;text-align: center;}
	.wallet_address {margin:auto;width:360px;display: flex;justify-content:space-between;margin-top:40px;}
	.span {font-size: 15px;font-family: SF UI Display light;color: #888888;letter-spacing:-0.6px;}
	.wallet_address_item_copy img {width:20px;height: 20px;margin-right:8px;}
	.wallet_address_item_copy {font-size: 14px;color: #00C999;margin-left:15px;display: flex;align-items: center;}
	.copy {cursor: pointer;}
	.wallet_but {display: flex;width:250px;margin:auto;justify-content:space-around;margin-top:40px;}
	.wallet_but_item {display: flex;flex-direction:column;align-items: center;}
	.wallet_but_item img {width:50px;cursor: pointer;}
	.wallet_but_font {
		font-size: 15px;
		font-family: SF UI Display light;
		color: #888888;
		letter-spacing:-0.6px;
		line-height: 36px;
		cursor: pointer;
	}
	.wallet_list_item {
		width:500px;
		margin: auto;
		display: flex;
		align-items: center;
		padding: 10px 0;
		margin-top: 40px;
		border-bottom: 1px solid #F0F0F0;
	}
	.wallet_icon {
		width:40px;
		height:40px;
		border:1px solid #D0D0D0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content:center;
		flex-shrink: 0;
	}
	.wallet_icon img{
		width:28px;
	}

	.wallet_name {
		width:100px;
		margin-left: 10px;
		font-size: 15px;
		font-family: SF UI Display light;
		color: #888888;
		flex-shrink: 0;
	}
	.wallet_number {
		width:100%;
		font-family:'SF UI Display ultralight';
		font-size: 15px;
		color: #000000;
		text-align: right;
	}
	.wallet_unit {
		width:50px;
		margin-left:10px;
		font-family:'SF UI Display light';
		font-size: 14px;
		color: #4A4A4A;
		flex-shrink: 0;
	}
	.wallet_set {
		width:40px;
		height: 18px;
		flex-shrink: 0;
		background-image:url(../assets/img/remark.png);
		background-repeat: no-repeat;
		background-position: right center;
		cursor: pointer;
	}
	.act {
		background-image:url(../assets/img/remarkAct.png) !important;
	}

	.wallet_set:hover {
		background-image:url(../assets/img/remarkAct.png) !important;
	}

	.wallet_menu {
		width:120px;
		height:101px;
		background-color: #F0F0F0;
		position: absolute;
		margin-top:0px;
		margin-left:-80px;
	}

	.wallet_menu_item {
		display: flex;
		line-height: 33px;
		align-items: center;
	}

	.wallet_menu_item img{
		width: 10px;
		height: 11px;
		flex-shrink: 0;
		margin-left: 10px;
	}

	.wallet_menu_item_til {
		width:100%;
		font-size: 10px;
		font-family: SF UI Display light;
		color: #000000;
		margin-left: 10px;
	}

	.wallet_border_bottom {
		border-bottom: 1px #FFFFFF solid;
	}

	.wallet_menu_item_til:hover {
		font-family: SF UI Display bold;
		cursor: pointer;
	}

	.activity_maxHeight {
		width:502px;
		max-height:250px;
    height:250px;
		overflow-y: auto;
		margin:auto;
		margin-top: 20px;
	}

	.activity_maxHeight::-webkit-scrollbar {width : 2px;}
	.activity_maxHeight::-webkit-scrollbar-thumb {border-radius   : 10px;background-color: #C4C4C4;}
	.activity_maxHeight::-webkit-scrollbar-track {box-shadow:inset 0 0 5px #ffffff;background:#ffffff;border-radius: 10px;}

	.wallet_activity {
		display: flex;
		width:500px;
		margin:auto;
		align-items: center;
	}

	.activity_type {
		width:40px;
		height:50px;
		background-repeat: no-repeat;
		background-position: center center;
		flex-shrink: 0;
	}

	.receive {
		background-image: url(../assets/img/downloadGray.png);
	}

	.purchase {
		background-image: url(../assets/img/cartGray.png);
	}

	.bridge {
		background-image: url(../assets/img/money.png);
	}

	.send {
		background-image: url(../assets/img/upjt.png);
	}

	.activity_type_time {
		margin-left: 10px;
		width: 120px;
		flex-shrink: 0;
	}

	.activity_type_time .act_type {
		font-family: SF UI Display light;
		color: #888888;
		font-size: 14px;
	}

	.activity_type_time .act_time {
		font-family: SF UI Display light;
		color: #888888;
		font-size: 14px;
	}

	.activity_money {
		width: 100%;
		font-family: 'SF UI Display ultralight';
		font-size: 15px;
		color: #000000;
		text-align: right;
	}

	.activity_unit {
		width: 90px;
		margin-left: 10px;
		font-family: 'SF UI Display light';
		font-size: 14px;
		color: #4A4A4A;
		flex-shrink: 0;
	}

	.receive_container {
		position: absolute;
		width:100%;
		height:100%;
		top: 0;
	}

	.receive_container_ce {
		width: 650px;
		height:550px;
		border:2px #888888 solid;
		background-color: #FFFFFF;
		border-radius: 15px;
		margin: auto;
		margin-top:230px;
	}

	.close_btn {
		position: absolute;
		cursor: pointer;
		margin-top: 25px;
		margin-left: 600px;
	}

	.receive_til {
		margin-top:30px;
		text-align: center;
		font-size: 30px;
		color: #4A4A4A;
	}

	.receive_address {
		width:550px;
		margin: auto;
		border:1px #F0F0F0 solid;
		margin-top:40px;
		border-radius: 20px;
		height: 51px;
		display: flex;
		align-items: center;
	}

	.address_til {
		width:100px;
		text-align: center;
		font-size: 18px;
		flex-shrink: 0;
	}

	.address_info {
		width:100%;
		text-align: right;
		font-family: 'SF UI Display light';
		font-size: 16px;
		color: #888888;
	}

	.address_copy {
		width:60px;
		height:36px;
		background-image: url(../assets/img/copy.png);
		background-repeat: no-repeat;
		background-position: center center;
		flex-shrink: 0;
    cursor: pointer;
	}

	.qr_code {
		font-size: 18px;
		text-align: center;
		margin-top: 60px;
	}

	.qrcode_img {
		width:250px;
		height:250px;
		border:1px #f0f0f0 solid;
		margin: auto;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top:15px;
	}

	.qrcode_img img {
		width:230px;
	}

	.bridge_container {
		display: none;
		position: absolute;
		width:100%;
		height:100%;
		top: 0;
	}
	.bridge_container_ce {
		width: 400px;
		height:500px;
		border:2px #888888 solid;
		background-color: #FFFFFF;
		border-radius: 15px;
		margin: auto;
		margin-top:230px;
	}

	.bridge_close_btn {
		position: absolute;
		cursor: pointer;
		margin-top: 25px;
		margin-left: 350px;
	}

	.bridge_til {
		margin:20px 0px 75px 30px;
		font-size: 28px;
		color: #4A4A4A;
		letter-spacing: -0.6px;
		font-family: 'SF UI Display light';
	}

	.bridge_max {
		width:340px;
		text-align: right;
		margin: auto;
		color: #1192CA;
		font-size: 16px;
		font-family: 'SF UI Display light';
	}

	.bridge_max span {
		cursor: pointer;
	}

	.bridge_from {
		width:340px;
		height: 65px;
		border:1px #D0D0D0 solid;
		border-radius: 10px;
		margin: auto;
		display: flex;
		align-items: center;
	}

	.bridge_from .bridge_icon {
		width: 40px;
		height: 40px;
		border: 1px solid #D0D0D0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left:20px;
		flex-shrink: 0;
	}

	.bridge_info {
		width: 60px;
		margin-left:10px;
		font-family: 'SF UI Display light';
		font-size: 16px;
		color: #888888;
		flex-shrink: 0;
	}

	.bridge_money {
		width: 100%;
	}
	.bridge_money input {
		border:none;
		text-align: right;
		font-family: 'SF UI Display light';
		font-size: 16px;
		color: #888888;
		width: 90%;
	}

	.to {
		width:335px;
		margin: auto;
		color: #00C999;
		font-size: 16px;
		font-family: 'SF UI Display light';
		padding-left: 5px;
	}

	.bridge_tip {
		width:335px;
		margin: auto;
		font-size: 15px;
		color: #888888;
		font-family: 'SF UI Display light';
		margin-bottom: 20px;
		padding-left: 5px;
	}

	.comfirm_btn {
		width:80px;
		height: 40px;
		background-color: #00C999;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		font-family: 'SF UI Display semibold';
		font-size: 15px;
		color: #FFFFFF;
		margin: auto;
		margin-top: 50px;
	}
}


@media(min-width: 1200px){
  .mobile{display: none !important;}
	body{padding-top:80px;}
	.content{width:1200px;margin:auto;}
	.bottomDiv{height:200px;}
	.heard_url {text-align: center;padding: 40px 0 20px 0;}
	.heard_url img {width:100px;height:100px;border-radius: 50%;}
	.wallet_money {font-family: SF UI Display light;font-size: 18px;color: #888888;text-align: center;}
	.wallet_address {margin:auto;width:360px;display: flex;justify-content:space-between;margin-top:40px;}
	.span {font-size: 15px;font-family: SF UI Display light;color: #888888;letter-spacing:-0.6px;}
	.wallet_address_item_copy img {width:20px;height: 20px;margin-right:8px;}
	.wallet_address_item_copy {font-size: 14px;color: #00C999;margin-left:15px;display: flex;align-items: center;}
	.copy {cursor: pointer;}
	.wallet_but {display: flex;width:400px;margin:auto;justify-content:space-around;margin-top:40px;}
	.wallet_but_item {display: flex;flex-direction:column;align-items: center;}
	.wallet_but_item img {width:50px;cursor: pointer;}
	.wallet_but_font {
		font-size: 15px;
		font-family: SF UI Display light;
		color: #888888;
		letter-spacing:-0.6px;
		line-height: 36px;
		cursor: pointer;
	}
	.wallet_list_item {
		width:500px;
		margin: auto;
		display: flex;
		align-items: center;
		padding: 10px 0;
		margin-top: 40px;
		border-bottom: 1px solid #F0F0F0;
	}
	.wallet_icon {
		width:40px;
		height:40px;
		border:1px solid #D0D0D0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content:center;
		flex-shrink: 0;
	}
	.wallet_icon img{
		width:28px;
	}

	.wallet_name {
		width:100px;
		margin-left: 10px;
		font-size: 15px;
		font-family: SF UI Display light;
		color: #888888;
		flex-shrink: 0;
	}
	.wallet_number {
		width:100%;
		font-family:'SF UI Display ultralight';
		font-size: 15px;
		color: #000000;
		text-align: right;
	}
	.wallet_unit {
		width:50px;
		margin-left:10px;
		font-family:'SF UI Display light';
		font-size: 14px;
		color: #4A4A4A;
		flex-shrink: 0;
	}
	.wallet_set {
		width:40px;
		height: 18px;
		flex-shrink: 0;
		background-image:url(../assets/img/remark.png);
		background-repeat: no-repeat;
		background-position: right center;
		cursor: pointer;
	}

	.act {
		background-image:url(../assets/img/remarkAct.png) !important;
	}

	.wallet_set:hover {
		background-image:url(../assets/img/remarkAct.png) !important;
	}

	.wallet_menu {
		width:120px;
		background-color: #F0F0F0;
		position: absolute;
		margin-top: 0px;
		margin-left:-80px;
	}

	.wallet_menu_item {
		display: flex;
		line-height: 33px;
		align-items: center;
	}

	.wallet_menu_item img{
		width: 10px;
		height: 11px;
		flex-shrink: 0;
		margin-left: 10px;
	}

	.wallet_menu_item_til {
		width:100%;
		font-size: 10px;
		font-family: SF UI Display light;
		color: #000000;
		margin-left: 10px;
	}

	.wallet_border_bottom {
		border-bottom: 1px #FFFFFF solid;
	}

	.wallet_menu_item_til:hover {
		font-family: SF UI Display bold;
		cursor: pointer;
	}

	.activity_maxHeight {
		width:502px;
		max-height:250px;
    height:250px;
		overflow-y: auto;
		margin:auto;
		margin-top: 20px;
	}

	.activity_maxHeight::-webkit-scrollbar {width : 2px;}
	.activity_maxHeight::-webkit-scrollbar-thumb {border-radius   : 10px;background-color: #C4C4C4;}
	.activity_maxHeight::-webkit-scrollbar-track {box-shadow:inset 0 0 5px #ffffff;background:#ffffff;border-radius: 10px;}

	.wallet_activity {
		display: flex;
		width:500px;
		margin:auto;
		align-items: center;
	}

	.activity_type {
		width:40px;
		height:50px;
		background-repeat: no-repeat;
		background-position: center center;
		flex-shrink: 0;
	}

	.receive {
		background-image: url(../assets/img/downloadGray.png);
	}

	.purchase {
		background-image: url(../assets/img/cartGray.png);
	}

	.bridge {
		background-image: url(../assets/img/money.png);
	}

	.send {
		background-image: url(../assets/img/upjt.png);
	}

	.activity_type_time {
		margin-left: 10px;
		width: 120px;
		flex-shrink: 0;
	}

	.activity_type_time .act_type {
		font-family: SF UI Display light;
		color: #888888;
		font-size: 14px;
	}

	.activity_type_time .act_time {
		font-family: SF UI Display light;
		color: #888888;
		font-size: 14px;
	}

	.activity_money {
		width: 100%;
		font-family: 'SF UI Display ultralight';
		font-size: 15px;
		color: #000000;
		text-align: right;
	}

	.activity_unit {
		width: 90px;
		margin-left: 10px;
		font-family: 'SF UI Display light';
		font-size: 14px;
		color: #4A4A4A;
		flex-shrink: 0;
	}

	.receive_container {
		position: absolute;
		width:100%;
		height:100%;
		top: 0;
	}

	.receive_container_ce {
		width: 650px;
		height:550px;
		border:2px #888888 solid;
		background-color: #FFFFFF;
		border-radius: 15px;
		margin: auto;
		margin-top:230px;
	}

	.close_btn {
		position: absolute;
		cursor: pointer;
		margin-top: 25px;
		margin-left: 600px;
	}

	.receive_til {
		margin-top:30px;
		text-align: center;
		font-size: 30px;
		color: #4A4A4A;
	}

	.receive_address {
		width:550px;
		margin: auto;
		border:1px #F0F0F0 solid;
		margin-top:40px;
		border-radius: 20px;
		height: 51px;
		display: flex;
		align-items: center;
	}

	.address_til {
		width:100px;
		text-align: center;
		font-size: 18px;
		flex-shrink: 0;
	}

	.address_info {
		width:100%;
		text-align: right;
		font-family: 'SF UI Display light';
		font-size: 16px;
		color: #888888;
	}

	.address_copy {
		width:60px;
		height:36px;
		background-image: url(../assets/img/copy.png);
		background-repeat: no-repeat;
		background-position: center center;
		flex-shrink: 0;
    cursor: pointer;
	}

	.qr_code {
		font-size: 18px;
		text-align: center;
		margin-top: 60px;
	}

	.qrcode_img {
		width:250px;
		height:250px;
		border:1px #f0f0f0 solid;
		margin: auto;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top:15px;
	}

	.qrcode_img img {
		width:230px;
	}

	.bridge_container {
		display: none;
		position: absolute;
		width:100%;
		height:100%;
		top: 0;
	}
	.bridge_container_ce {
		width: 400px;
		height:500px;
		border:2px #888888 solid;
		background-color: #FFFFFF;
		border-radius: 15px;
		margin: auto;
		margin-top:230px;
	}

	.bridge_close_btn {
		position: absolute;
		cursor: pointer;
		margin-top: 25px;
		margin-left: 350px;
	}

	.bridge_til {
		margin:20px 0px 75px 30px;
		font-size: 28px;
		color: #4A4A4A;
		letter-spacing: -0.6px;
		font-family: 'SF UI Display light';
	}

	.bridge_max {
		width:340px;
		text-align: right;
		margin: auto;
		color: #1192CA;
		font-size: 16px;
		font-family: 'SF UI Display light';
	}

	.bridge_max span {
		cursor: pointer;
	}

	.bridge_from {
		width:340px;
		height: 65px;
		border:1px #D0D0D0 solid;
		border-radius: 10px;
		margin: auto;
		display: flex;
		align-items: center;
	}

	.bridge_from .bridge_icon {
		width: 40px;
		height: 40px;
		border: 1px solid #D0D0D0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left:20px;
		flex-shrink: 0;
	}

	.bridge_info {
		width: 60px;
		margin-left:10px;
		font-family: 'SF UI Display light';
		font-size: 16px;
		color: #888888;
		flex-shrink: 0;
	}

	.bridge_money {
		width: 100%;
	}
	.bridge_money input {
		border:none;
		text-align: right;
		font-family: 'SF UI Display light';
		font-size: 16px;
		color: #888888;
		width: 90%;
	}

	.to {
		width:335px;
		margin: auto;
		color: #00C999;
		font-size: 16px;
		font-family: 'SF UI Display light';
		padding-left: 5px;
	}

	.bridge_tip {
		width:335px;
		margin: auto;
		font-size: 15px;
		color: #888888;
		font-family: 'SF UI Display light';
		margin-bottom: 20px;
		padding-left: 5px;
	}

	.comfirm_btn {
		width:80px;
		height: 40px;
		background-color: #00C999;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		font-family: 'SF UI Display semibold';
		font-size: 15px;
		color: #FFFFFF;
		margin: auto;
		margin-top: 50px;
	}
}
</style>
