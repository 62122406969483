<template>
  <div id="home" v-loading="mainLoading">
    <!-- goodsList -->
    <div class="content goodsList">
    	<div class="content pc title">検索</div>
    	<div class="tool" v-if="isShowToobar">
    		<div>
    			<span :class="sortBy == 'ALL' ? 'act' : ''" @click="searchByType('ALL')">すべて</span>
    			<span :class="sortBy == 'NEW' ? 'act' : ''" @click="searchByType('NEW')">新着</span>
    			<span :class="sortBy == 'HOT' ? 'act' : ''" @click="searchByType('HOT')">人気</span>
    		</div>
    	</div>
    	<div class="list">
    		<div class="item" v-for="(item,i) in list" :key="i" @click="ntfHome(item.groupId)">
    			<div class="img" :style="item.thumbnail"></div>
    			<h5>{{item.title}}</h5>
    			<div class="price">
    				<div>
    					<h4>{{numberFormat(item.price)}} MATIC</h4>
    					<span>( ≈ ¥ {{numberFormat(item.price*hl)}})</span>
    				</div>
    				<i :class="item.isLiked ? 'heartAct' : ''"></i>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="more" @click="nextPage" v-if="isHaveNext">もっと見る</div>
    <div class="bottomDiv"></div>
  </div>
</template>

<script>
  import { putAction,getAction } from '@/network/manage'
  import numeral from 'numeral'
  export default {
    name: 'Explorer',
    components: {
    },
    data() {
      return {
        url: {
          list: '/nft/explorer',
          searchList:'/nft/search',
          hl:'/matic_conversion/'
        },
        list:[],
        hl:1,
        page:1,
        sortBy:'ALL',
        isHaveNext:false,
        mainLoading:true,
        toolType:0,
        isShowToobar:true
      }
    },
    watch: {
  　　'$route': function (to, from) {
        this.page = 1;
        this.list = [];
        this.loadHl();
        this.listData();
  　　}
    },
    created(){
      this.loadHl();
      this.listData();
    },
    methods:{
      ntfHome(id){
        this.$router.push({path:'ntfHome',query:{id:id}})
      },
      searchByType(sortType){
        this.page = 1;
        this.list = [];
        this.sortBy = sortType;
        this.listData();
      },
      numberFormat(txt){
        return numeral(txt).format('0,0.000')
      },
      nextPage(){
        this.page = this.page + 1;
        this.listData();
      },
      loadHl(){
        const that = this;
        getAction(this.url.hl,{}).then((res) => {
          if (res.code == 200) {
            that.hl = res.data.maticToYen;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      listData(){
        const that = this;
        var category_id = this.$route.query.category_id;
        var search_term = this.$route.query.search_term;
        var url = this.url.list;
        var data = {
          page:this.page,
          sortBy:this.sortBy
        }
        this.isShowToobar = true;
        if(category_id != null){
          data={
            page:this.page,
            sortBy:this.sortBy,
            category_id:category_id
          }
          url=this.url.searchList;
          this.isShowToobar = false;
        }
        if(search_term != null){
          data={
            page:this.page,
            sortBy:this.sortBy,
            search_term:search_term
          }
          url=this.url.searchList;
          this.isShowToobar = false;
        }
        that.mainLoading = true;
        getAction(url,data).then((res) => {
          if (res.code == 200) {
            var obj = res.data.contents;
            for(var i = 0;i<obj.length;i++){
              if(obj[i].priceMatic != null)
                obj[i].price = obj[i].priceMatic
              obj[i].price = obj[i].price / 1000;
              obj[i].thumbnail = "background-image:url('"+obj[i].thumbnail+"')";
            }
            that.list = that.list.concat(obj);
            if(res.data.page.currentPage < res.data.page.totalPage)
              that.isHaveNext = true;
            else
              that.isHaveNext = false;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.mainLoading = false;
        })
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .goodsList .tool{display: flex;border-bottom:1px solid #F0F0F0;align-items: center;color:#888888;justify-content: space-between;}
  .goodsList .tool a{background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right center;padding-right:20px;}
  .goodsList .tool div{display: flex;}
  .goodsList .tool div span{cursor: pointer;}
  .goodsList .tool div .act{color:#000000;border-bottom: 2px solid #000000;}
  .goodsList .list{display: grid;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;}
  .goodsList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodsList .list .item .price div{width:100%;display: flex;flex-direction: column;}
  .goodsList .list .item .price h4{font-family: 'SF UI Display bold';line-height:1;}
  .goodsList .list .item .price span{font-family: 'SF UI Display bold';line-height:1;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/heart.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/heartAct.png);}
  .title{line-height: 80px;font-size:20px;}
  .more{background: #00C999;color:#ffffff;border-radius: 35px;height:44px;width: 148px;margin:60px auto 0 auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}
  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
  	.content{margin:0 16px;}
  	.goodsList{display: flex;flex-direction: column;align-items: center;}
  	.goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
  	.goodsList .list .item .img{width:44.2vw;height:24.25vw;}
  	.goodsList .list .item h5{font-size:14px;height:40px;}
  	.goodsList .list .item .price{padding: 0px 12px 10px 16px}
  	.goodsList .list .item .price h4{font-size:15px;line-height: 1.5;}
  	.goodsList .list .item .price span{font-size:12px;}
  	.goodsList .tool{margin-top:10px;height:50px;margin-bottom:20px;width:100%;}
  	.goodsList .tool div span{line-height:50px;height:48px;margin-right:30px;}
  	.goodsList .mobile{padding:30px 0 60px 0;color:#888888;background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right top 37px;padding-right:16px;}
  	.bottomDiv{height:100px;}
    .more{height:30px;margin:30px auto;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.goodsList .list{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
  	.goodsList .list .item .img{width:191px;height:105px;}
  	.goodsList .list .item .price i{width:30px;height:30px;}
  	.goodsList .list .item h5{font-size:14px;height:40px;}
  	.goodsList .list .item .price h4{font-size:14px;}
  	.goodsList .list .item .price span{font-size:14px;}
    .bottomDiv{height:100px;}
    .goodsList .tool{margin-top:0px;height:50px;margin-bottom:20px;}
    .goodsList .tool div span{line-height:50px;margin-right:30px;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
  	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodsList .list .item .img{width:285px;height:156px;}
  	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodsList .list .item h5{height:48px;}
    .bottomDiv{height:200px;}
    .goodsList .tool{margin-top:0px;height:70px;margin-bottom:40px;}
    .goodsList .tool div span{line-height:70px;margin-right:50px;}
  }
</style>
