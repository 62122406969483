<template>
  <div>
    <div class="content mai">
			<img src="@/assets/img/ok.png"/>
			<h1>NFT発行申請を受け付けました。</h1>
			<p>
				当該NFTは管理者審査（48時間以内）後に発行されます。<br/>
				発行済みのNFTはマイコレクションで確認できます。
			</p>
			<div class="btn ok" @click="go_page">確認</div>
		</div>
    <div class="bottomDiv"></div>
  </div>
</template>

<script>
  import { putAction,getAction } from '@/network/manage'
  export default {
    name: 'UploadFinish',
    components: {
    },
    data() {
      return {
        url: {
          list: '/nft/explorer',
          hl:'/matic_conversion'
        },
        list:[],
        hl:1,
        page:1,
        sortBy:'ALL',
        isHaveNext:false,
        toolType:0
      }
    },
    created(){

    },
    methods:{
      go_page(){
        this.$router.push({path:'collectionCreated',query:{}})
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
  .paths .home{background-image: url(../assets/img/home.png);}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
  .mai{display: flex;flex-direction: column;align-items: center;}
  .mai img{margin-top:100px;}
  .mai h1{margin:50px 0 30px 0;font-family:'SF UI Display light';font-size:30px;}
  .mai p{color: #9E9E9E;font-size:20px;line-height:2;text-align: center;font-family:'SF UI Display light';}
  .mai .btn{margin:80px auto 0 auto;width:280px;height:46px;color:#ffffff;background: #D0D0D0;border-radius: 2px;font-family: 'SF UI Display light';font-size:15px;display: flex;justify-content: center;align-items: center;}
  .mai .ok{background: #00C999;cursor: pointer;}
  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
    .bottomDiv{height:100px;}
  	.content{margin:0 4vw;}
    .mai img{margin-top:50px;width:60px;height:60px;}
    .mai h1{font-size:18px;margin:50px 0 30px 0;}
    .mai p{color: #9E9E9E;font-size:16px;}
    .mai .btn{margin:80px auto 0 auto;width:60vw;height:46px;color:#ffffff;background: #D0D0D0;border-radius: 2px;font-family: 'SF UI Display light';font-size:15px;display: flex;justify-content: center;align-items: center;}
    .mai .ok{background: #00C999;cursor: pointer;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.mai .imgs{grid-template-columns: 152px 152px 152px 152px 152px;}
  	.mai .imgs .item{height:87.6px;}
  	.bottomDiv{height:100px;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.content{width:1200px;margin:auto;}
  	.mobile{display: none !important;}

  	.bottomDiv{height:200px;}
  }
</style>
