<template>
  <div>

    <div class="content heard_url">
      <upload
        class="avatar-uploader"
        :action="getUploadUrl"
        :headers="headers"
        :show-file-list="false"
        :multiple="false"
        accept="image/*"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <img v-if="headimgurl" :src="headimgurl" class="avatar">
      </upload>

    </div>

    <div class="content">
    	<div class="form_profile_item">
    		<div class="form_profile_item_til">
    			E-mail
    		</div>
    		<div class="form_profile_item_inp" v-if="data">
    			<input v-model="data.email" @blur="emailCheck"/>
    		</div>
        <div class="form_profile_item_tip error_info" v-if="emailCheckMsg">
        	{{emailCheckMsg}}
        </div>
    	</div>

    	<div class="form_profile_item">
    		<div class="form_profile_item_til">
    			ニックネーム
    		</div>
        <div class="form_profile_item_inp" v-if="data">
          <input v-model="data.nickName" @blur="nicknameCheck"/>
        </div>
        <div class="form_profile_item_tip error_info" v-if="nicknameCheckMsg">
          {{nicknameCheckMsg}}
        </div>
    	</div>
    	<div class="form_profile_item">
    		<div class="form_profile_item_til">
    			ウォレット住所
    		</div>
    		<div class="copy_tip" v-if="data">
    			<div class="form_profile_item_inp span">
    				{{data.walletAddress[0]}}
    			</div>
    			<div class="form_profile_item_copy copy" @click="copyAddress()">
    				<img src="../assets/img/copy.png"/> コピー
    			</div>
    		</div>
    	</div>
    </div>

    <div class="content">
    	<div class="form_but" @click="update_user">更新</div>
    </div>

    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home" @click="goHome()"></i>
    	<i class="jt"></i>
    	<a>プロフィール</a>
    </div>
  </div>
</template>

<script>
  import { Upload } from 'element-ui'
  import { putAction,getAction } from '@/network/manage'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import { duplicate } from "network/user"
  export default {
    name: 'Prifile',
    components: {
      Upload
    },
    data() {
      return {
        url: {
          user: '/user/',
          update: '/user/update',
          upload: 'file/upload'
        },
        data:null,
        headimgurl:null,
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        emailCheckRes: true,
        nicknameCheckRes: true,
        emailCheckMsg:'',
        nicknameCheckMsg:'',
        loding:null,
        oldEmail:'',
        oldNickname:''
      }
    },
    computed: {
      getUploadUrl() {
        return process.env.VUE_APP_BASE_API + this.url.upload
      }
    },
    created(){
      this.loadDetail();
    },
    methods:{
      loadDetail(){
        const that = this;
        getAction(this.url.user,{}).then((res) => {
          if (res.code == 200) {
            this.headimgurl = res.data.profileImage !== null ? res.data.profileImage : require('assets/img/headimg.jpg')
            that.data = res.data
            this.oldEmail = res.data.email
            this.oldNickname = res.data.nickName
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      goHome() {
        this.$router.push({path:'home'})
      },
      //上传成功后的监听
      handleAvatarSuccess(res, file) {
        this.loding.close()
        this.headimgurl = res.data.s3
        this.$message.success('アップロードしました。')
      },
      //上传前的监听
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('アップロードサイズ：5MBまで！')
        } else {
          this.loding = this.$loading.service()
        }
        return isLt5M
      },
      copyAddress() {
        var oInput = document.createElement('input')
        oInput.value = this.data.walletAddress[0]
        document.body.appendChild(oInput)
        oInput.select()
        document.execCommand("Copy")
        oInput.style.display='none'
        this.$message.success('コピーしました。')
      },
      async emailCheck() {
        let result = false
        if(this.oldEmail !== this.data.email) {
          if (this.data.email === '') {
            this.emailCheckMsg = 'Eメールを入力してください。'
          } else if (!new RegExp(/^[a-z0-9_\+-]+(\.[a-z0-9_\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]{2,4})$/).test(this.data.email)) {
            this.emailCheckMsg = 'Eメールを正しく入力してください。'
          } else {
            try {
              const duplicateResult = await duplicate('EMAIL', this.data.email)
              if (duplicateResult.code === 200 && !duplicateResult.data.isDuplicated) {
                this.emailCheckMsg = ''
                result = true
              } else {
                this.emailCheckMsg = 'このメールはすでに使われています。'
              }
            } catch(err) {
              this.$notification.error({title: 'システムメッセージ', message: err.message })
            }
          }
          this.emailCheckRes = result
        } else {
          this.emailCheckMsg = ''
          this.emailCheckRes = true
        }
      },
      async nicknameCheck() {
        let result = false
        if(this.oldNickname !== this.data.nickName) {
          if (this.data.nickName === '') {
            this.nicknameCheckMsg = 'ニックネーム入力してください。'
          } else {
            try {
              const duplicateResult = await duplicate('NICKNAME', this.data.nickName)
              if (duplicateResult.code === 200 && !duplicateResult.data.isDuplicated) {
                this.nicknameCheckMsg = ''
                result = true
              } else {
                this.nicknameCheckMsg = 'このニックネームはすでに使われています。'
              }
            } catch(err) {
              this.$notification.error({title: 'システムメッセージ', message: err.message })
            }
          }
          this.nicknameCheckRes = result
        } else {
          this.nicknameCheckMsg = ''
          this.nicknameCheckRes = true
        }
      },
      async update_user() {
        await this.nicknameCheck()
        await this.emailCheck()
        if(this.emailCheckRes && this.nicknameCheckRes) {
          const that = this;
          let loding = this.$loading.service()
          this.data.profile_image = this.headimgurl
          this.data.nickname = this.data.nickName
          putAction(this.url.update,this.data).then((res) => {
            loding.close()
            if (res.code == 200) {
              that.$message.success('修正しました。')
              that.$store.dispatch('GetUserInfo')
              that.loadDetail()
            } else {
              if(res.code === 4003) {
                that.$message.error('ニックネームまたはEメールがすでに使われています。');
              }
            }
          }).catch(res => {
            that.$message.warning(res.message);
          })
        }
      }
    }
  }
</script>

<style scoped>
body{background: #ffffff;overflow-x: hidden;}
*{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
input,select{outline:none;}
a{text-decoration: none;}
.more{background: #00C999;color:#ffffff;border-radius: 35px;height:44px;width: 148px;margin:60px auto 0 auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}
.paths{display: flex;padding:36px 0;}
.paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
.paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
.paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
.paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
.error_info {color:red !important;}
@media (max-width: 800px) {
  .content{margin:0 16px;}
  .bottomDiv{height:100px;}
  .paths{padding:20px 0;}
  .heard_url {text-align: center;padding: 60px 0 60px 0;}
  .heard_url img {width:100px;height:100px;border-radius: 50%;}
  .nick_name {text-align: center;font-family: SF UI Display semibold;font-size: 14px;margin-bottom: 60px;}
  .form_profile_item {display: flex;flex-direction: column;border-bottom: 1px solid #F0F0F0;padding:10px 0;}
  .form_profile_item .form_profile_item_til {font-size: 13px;font-family: Hiragino Kaku Gothic Pro;color: #888888;line-height: 25px}
  .form_profile_item .form_profile_item_inp {width:100%;}
  .form_profile_item .form_profile_item_inp input{font-size: 14px;font-family: SF UI Display ultralight;color: #000000;border: none;line-height: 30px;width: 100%;}
  .nickname_dis_tip {display: flex;align-items:center;justify-content:space-between;}
  .form_profile_item .form_profile_item_inp .input_nickname {font-family: Noto Sans CJK JP;color: #000000;letter-spacing:6px;width: 180px;}
  .form_profile_item .form_profile_item_tip {font-size: 14px;color: #1192CA;letter-spacing:-0.45px;}
  .copy_tip {display: flex;align-items:center;padding:10px 0px;}
  .form_profile_item .span {font-size: 15px;font-family: SF UI Display light;color: #888888;letter-spacing:-0.6px;word-break: break-all;word-wrap: break-word;}
  .form_profile_item .form_profile_item_copy img {width:20px;height: 20px;margin-right:8px;}
  .form_profile_item .form_profile_item_copy {width:80px;font-size: 16px;color: #00C999;margin-left:15px;display: flex;align-items: center;flex-shrink: 0;}
  .form_but {background-color: #00C999;color:#FFFFFF;text-align: center;width:100%;line-height: 55px;margin: auto;margin-top: 30px;cursor: pointer;border-radius: 3px; margin-bottom: 20px;}
  .copy {cursor: pointer;}
}
@media screen and (min-width: 800px) and (max-width: 1200px){
  .content{width:800px;margin:auto;}
  .bottomDiv{height:100px;}
  .heard_url {text-align: center;padding: 80px 0;}
	.heard_url img {width:80px;height:80px;border-radius: 50%;}
	.nick_name {text-align: center;font-family: SF UI Display semibold;font-size: 14px;margin-bottom: 60px;}
	.form_profile_item {display: flex;border-bottom: 1px solid #F0F0F0;padding:30px 0;align-items:center;}
	.form_profile_item .form_profile_item_til {width:240px;font-size: 16px;font-family: SF UI Display light;color: #888888;line-height: 40px;flex-shrink: 0;}
	.nickname_dis_tip {display: flex;align-items:center;justify-content:space-between;}
	.copy_tip {display: flex;align-items:center;}
	.form_profile_item .form_profile_item_inp input{font-size: 16px;font-family: SF UI Display ultralight;color: #000000;border: none;width:300px;line-height: 40px;}
	.form_profile_item .form_profile_item_inp .input_nickname {font-family: SF UI Display light;color: #888888;letter-spacing:6px;width: 300px;}
	.form_profile_item .form_profile_item_tip {font-size: 16px;color: #1192CA;letter-spacing:-0.5px;}
	.form_profile_item  .span {font-size: 16px;font-family: SF UI Display light;color: #888888;letter-spacing:-0.3px;}
	.form_profile_item .form_profile_item_copy img {width:20px;height: 20px;margin-right:8px;}
	.form_profile_item .form_profile_item_copy {font-size: 16px;color: #00C999;margin-left:35px;display: flex;align-items: center;}
	.form_but {background-color: #00C999;color:#FFFFFF;text-align: center;width:300px;line-height: 50px;margin: auto;margin-top: 60px;cursor: pointer;}
	.copy {cursor: pointer;}
}


@media(min-width: 1200px){
  .content{width:1200px;margin:auto;}
  .bottomDiv{height:200px;}
  .heard_url {text-align: center;padding: 100px 0 100px 0;}
  .heard_url img {width:110px;height:110px;border-radius: 50%;}
  .nick_name {text-align: center;font-family: SF UI Display semibold;font-size: 14px;margin-bottom: 60px;}
  .form_profile_item {display: flex;border-bottom: 1px solid #F0F0F0;padding:30px 0;align-items:center;}
  .form_profile_item .form_profile_item_til {width:240px;font-size: 16px;font-family: SF UI Display light;color: #888888;line-height: 40px}
  .nickname_dis_tip {display: flex;align-items:center;justify-content:space-between;}
  .copy_tip {display: flex;align-items:center;}
  .form_profile_item .form_profile_item_inp input{font-size: 16px;font-family: SF UI Display ultralight;color: #000000;border: none;width:300px;line-height: 40px;}
  .form_profile_item .form_profile_item_inp .input_nickname {font-family: SF UI Display light;color: #888888;letter-spacing:6px;width: 300px;}
  .form_profile_item .form_profile_item_tip {font-size: 16px;color: #1192CA;letter-spacing:-0.5px;}
  .form_profile_item .span {font-size: 16px;font-family: SF UI Display light;color: #888888;letter-spacing:-0.3px;}
  .form_profile_item .form_profile_item_copy img {width:20px;height: 20px;margin-right:8px;}
  .form_profile_item .form_profile_item_copy {font-size: 16px;color: #00C999;margin-left:35px;display: flex;align-items: center;}
  .form_but {background-color: #00C999;color:#FFFFFF;text-align: center;width:300px;line-height: 50px;margin: auto;margin-top: 60px;cursor: pointer;}
  .copy {cursor: pointer;}
}
</style>
