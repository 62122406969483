<template>
  <div>
    <!-- goodsList -->
    <div class="content mai">
    	<form>
        <h3 v-if="item.approvedStatus=='denied'">NFT mintingが行われませんでした。</h3>
        <h4 v-if="item.approvedStatus=='denied'">{{item.decisionReason}}</h4>
    		<h1 v-loading="formLoading">アップロード</h1>
    		<div class="ti">
    			タイプ
    			<span>必須</span>
    		</div>
    		<div class="type">
    			<span :class="item.isYc?'act':''">原著作物</span>
    			<span :class="item.isYc?'':'act'">二次的著作物</span>
    		</div>
    		<p class="remark" v-if="item.isYc">思想又は感情を創作的に表現したもので二次的著作物のもとになった著作物。原著作物に加工・編集を行った場合は二次的著作物を選択してください。</p>
        <p class="remark" v-else>二次的著作物の場合、原著作物のNFTを指定してください。</p>
    		<div class="goodDiv" v-if="!item.isYc" v-loading="collectionLoading">
    			<div class="til">
    				<div class="ti">
    					タイプ
    					<span>必須</span>
    				</div>
    				<input  v-model="keywords" @keyup.enter="go_explorer_keywords" placeholder="大学　キャンパス"/>
    			</div>
    			<div class="conDivs" v-if="nftItem.length > 0">
    				<div class="conDiv">
    					<div :class="item.originalNftId == itemNft.tokenId?'item act':'item'" @click="item.originalNftId = itemNft.tokenId" v-for="(itemNft,i) in nftItem" :key="itemNft.tokenId">
    						<div class="zzDiv"></div>
    						<div class="img" :style="itemNft.url"></div>
    						<h5>{{itemNft.title}}</h5>
    						<div class="info">
    							<i class="headImg" :style="itemNft.sellerProfileImage"></i>
    							<span>{{itemNft.sellerNickName}}</span>
    						</div>
    					</div>
    				</div>
    			</div>
    			<div class="conDivEmpty" v-if="nftItem.length == 0">
    				アイテムがありません。
    			</div>
    		</div>
    		<div class="ti">
    			タイトル
    			<span>必須</span>
    		</div>
    		<div class="input">
    			<input maxlength="24" :disabled="realDisabled" v-model="item.title"/>
    			<span><a>{{item.title.length}}</a>/24</span>
    		</div>
    		<div class="ti">
    			詳細
    			<span>必須</span>
    		</div>
    		<div class="textarea">
    			<textarea v-model="item.description" :disabled="realDisabled" maxlength="100"></textarea>
    			<span><a>{{item.description.length}}</a>/100</span>
    		</div>
    		<div class="ti">
    			カテゴリー
    			<span>必須</span>
    		</div>
    		<div class="radio">
          <label v-for="(itemC,i) in categoryList" :key="i">
          	<input type="checkbox" v-model="itemC.checked" @change="value => change_category(value, i)" :disabled="realDisabled"/>
          	{{itemC.category}}
          </label>
    		</div>
    		<div class="ti">
    			代表イメージ
    			<span>必須</span>
    			<label>画像サイズ：横661px、縦380px／JPGまたはPNG形式（2MBまで）</label>
    		</div>

        <el-upload
          :action="getUploadUrl"
          :disabled="item.approvedStatus=='approved'"
          :headers="headers"
          :show-file-list="false"
          :multiple="false"
          accept="image/*"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <div class="fm" v-loading="listLoading" :style="headimgurlBG"></div>
        </el-upload>

    		<div class="ti">
    			サブイメージ
    			<label>画像サイズ：横661px、縦380px／JPGまたはPNG形式（2MBまで）</label>
    		</div>
        <div class="imgs" v-loading="listLoadingImgs">
          <div class="item" v-for="(iteml,i) in imgs" :key="i" v-if="i<10" v-loading="iteml.isLoading" :style="iteml.url">
			  <span @click="removeFile(i)" class="del" v-if="item.approvedStatus!='approved' && item.approvedStatus!='pending'">×</span>
			  </div>
          <el-upload class="item" v-if="imgs.length < 10 && item.approvedStatus!='approved' && item.approvedStatus!='pending'"
            :action="getUploadUrl"
            :headers="headers"
            :show-file-list="false"
            :multiple="true"
            accept="image/*"
            :on-success="handleAvatarSuccessImgs"
            :before-upload="beforeAvatarUploadImgs">
            <div class="item"></div>
          </el-upload>
        </div>
    		<div class="ti">
    			ファイル
    			<span>必須</span>
    			<label>zip、.skp、.clip、.png、.psd、.sut形式（1 ファイルのみ）</label>
    		</div>
    		<div class="file">
          <el-upload
            :action="getUploadUrl"
            v-if="item.approvedStatus =='denied'"
            :headers="headers"
            :show-file-list="false"
            :multiple="false"
            accept=".zip,.skp,.clip,.png,.psd,.sut"
            :before-upload="beforeAvatarUploadFile"
            :on-success="handleAvatarSuccessFile">
            <div class="but" v-loading="listLoadingFile">ファイルの追加</div>
          </el-upload>
    			<span v-if="fileSize != null">ファイルをアップロードしました。</span>
    		</div>
    		<div class="ti">
    			ファイルタイプの選択
    			<span>必須</span>
    			<label>4개까지 중복 선택 가능</label>
    		</div>
    		<div class="tag">
    			<span v-for="(item,i) in labels" @click="changeLabel(i)" :key="i" :class="labelsCheck[i]?'act':''">{{item}}</span>
    		</div>
    		<div class="ti">
    			紹介文
    			<span>必須</span>
    		</div>
    		<div class="edit">
          <div v-html="item.introduction" v-if="realDisabled"></div>
          <wang-editor v-model="item.introduction" v-if="!realDisabled" activeHeight="400px"></wang-editor>
    		</div>

        <div class="ti">
        	制作時参考PC仕様
        </div>
        <div class="textarea">
          <textarea style="height:140px;" v-model="item.pc_official" maxlength="400"></textarea>
          <span><a>{{item.pc_official.length}}</a>/400</span>
        </div>

    		<div class="ti">
    			出品件数
    			<span>必須</span>
    		</div>
    		<div class="lineInput">
    			<div>
    				<input :disabled="realDisabled" v-model="item.createVolumes" class="lineInput"/>
    				件
    			</div>
    		</div>
    		<div class="ti">
    			価格
    			<span>必須</span>
    			<label>「0」の場合は無料出品となります。</label>
    		</div>
    		<div class="lineInput">
    			<div>
    				<input v-model="item.price" class="lineInput"/>
    				MATIC
    			</div>
    			( ≈ {{numberFormat(item.price * hl)}} YEN)
    		</div>
    		<div class="ti an" v-if="item.isYc">
    			<h5>再編集を許可</h5>
    			<img v-if="!item.allowEdit" @click="item.allowEdit = realDisabled ? item.allowEdit : true" src="../assets/img/btn.png"/>
          <img v-if="item.allowEdit" @click="item.allowEdit = realDisabled ? item.allowEdit : false" src="../assets/img/btn_open.png"/>
    		</div>
    		<div class="ti" v-if="item.isYc && item.allowEdit">
    			ロイヤリティー
    			<span>必須</span>
    		</div>
    		<div class="radio radioInline" v-if="item.isYc && item.allowEdit">
    			<label>
    				<input type="radio" v-model="item.royalty" :disabled="realDisabled" value="0"/>
    				なし
    			</label>
    			<label v-for="(items,i) in 5" :key="i" v-if="i > 0">
    				<input type="radio" v-model="item.royalty" :disabled="realDisabled" :value="i*5"/>
    				{{i*5}}%
    			</label>
    		</div>
    		<div v-if="item.approvedStatus != 'pending'" :class="validateBtn?'btn ok':'btn'" @click="submit_form" v-loading="formLoading">審査用アップロード</div>
    	</form>
    </div>
    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home"></i>
    	<i class="jt"></i>
    	<a>アップロード</a>
    </div>
  </div>
</template>

<script>
  import { putAction,getAction,postAction } from '@/network/manage'
  import { Upload } from 'element-ui'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import WangEditor from '@/components/wangEditor/WangEditor'
  import { DatePicker } from 'element-ui'
  import moment from 'moment'
	import numeral from 'numeral'
  import { toWei, setSalePrice, setDateTime, setItemDownShelf } from '@/utils/utils'

  export default {
    name: 'UploadEdit',
    components: {
      elUpload:Upload,
      WangEditor,
      DatePicker
    },
    data() {
      return {
        url: {
          list: '/category/',
          hl:'/matic_conversion/',
          upload: 'file/upload',
          update: '/mint/',
          collection: '/collection/',
          updateReal: '/nft/'
        },
        hl:1,
        nftItem:[],
        formLoading:false,
        headimgurl:null,
        headimgurlBG:null,
        categoryList:[],
        item:{allowEdit:false,isYc:true,title:'',description:'',introduction:'',royalty:0,originalNftId:0, pc_official: ''},
        fileUrl:null,
        fileSize:null,
        labels:['Sketchup','Blender','Sculptris','ZBrush','cs3o','obj/fbx','psd','ai','jpg','jpeg'],
        labelsCheck:[false,false,false,false,false,false,false,false,false,false],
        imgs:[],
        listLoading:false,
        listLoadingImgs:false,
        listLoadingFile:false,
        realDisabled:false,
        collectionLoading:false,
        keywords:'',
        false_test: false,
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now()
          }
        }
      }
    },
    created(){
			this.loadHl();
      const that = this;
      that.formLoading = true;
      getAction(this.url.update + this.$route.query.requestId,{}).then((res) => {
        if (res.code == 200) {
          var itemNow = res.data;
          if(itemNow.isOnSale || itemNow.soldCount > 0) {
            this.$router.push({path:'collectionCreated',query:{}})
          }
          itemNow.isYc = res.data.isOriginalNFT;
          itemNow.createVolumes = res.data.volumes;
          itemNow.allowEdit = res.data.isAllowedEdit;
          itemNow.originalNftId = res.data.referenceNFTId;
          itemNow.price = res.data.price / 1000;
          that.fileUrl = res.data.files.nftContent.url;
          that.fileSize =  res.data.files.nftContent.size;
          that.headimgurl = res.data.files.thumbnail.url;
          that.headimgurlBG = 'background-image:url("'+res.data.files.thumbnail.url+'")';
          for(var i = 0;i<itemNow.files.assistImages.length;i++)
            that.imgs[that.imgs.length]={rUrl:itemNow.files.assistImages[i].url,url:'background-image:url("'+itemNow.files.assistImages[i].url+'")'};
          for(var i = 0;i<that.labels.length;i++){
            for(var k = 0;k<itemNow.files.nftContent.extensions.length;k++){
              if(itemNow.files.nftContent.extensions[k] === that.labels[i])
                this.$set(that.labelsCheck, i, true);
            }
          }
          if(itemNow.soldCount>0)
            that.realDisabled = true;
          itemNow.royalty = itemNow.royalty * 100;
          if(itemNow.royalty < 5) {
            itemNow.royalty = 0
          }
          that.item = itemNow;
          if(that.item.pc_official == null) {
            that.item.pc_official = ''
          }

          that.formLoading = false;
          getAction(this.url.list,{}).then((res) => {
            if (res.code == 200) {
              that.categoryList = res.data;
              for(let cate of that.categoryList) {
                cate.checked = false
                for(let c of that.item.category) {
                  if(cate.categoryId == c) {
                    cate.checked = true
                  }
                }
              }
            }
          })
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })

      that.go_explorer_keywords();
    },

    computed: {
      getUploadUrl() {
        return process.env.VUE_APP_BASE_API + this.url.upload
      },
      validateBtn:function(){
        return this.validateForm()===''?true:false;
      }
    },
    methods:{
      change_category(val, i) {
        if(this.realDisabled) {
          return;
        }
        this.false_test = !this.false_test
        // this.$set(this.categoryList[i], "checked", val.target.checked);
      },
      go_explorer_keywords(){
        const that = this;
        that.collectionLoading = true;
        getAction(this.url.collection,{page:1,size:1000,forReIssue:'true',searchTerm:this.keywords}).then((res) => {
          if (res.code == 200) {
            var obj = res.data.contents;
            for(var i = 0;i<obj.length;i++){
              obj[i].sellPriceMatic = obj[i].sellPriceMatic / 1000;
              obj[i].sellerProfileImage = "background-image:url('"+obj[i].sellerProfileImage+"')";
              obj[i].url = "background-image:url('"+obj[i].thumbnail+"')";
            }
            that.nftItem = obj;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res =>{
          that.collectionLoading = false;
        })
      },
			loadHl(){
			  const that = this;
			  getAction(this.url.hl,{}).then((res) => {
			    if (res.code == 200) {
			      that.hl = res.data.maticToYen;
			    } else {
			      that.$message.error(res.message);
			    }
			  }).catch(res => {
			    that.$message.warning(res.message);
			  })
			},
			numberFormat(txt){
			  return numeral(txt).format('0,0.000')
			},
      handleAvatarSuccess(res, file) {
        this.headimgurl = res.data.s3
        this.headimgurlBG = 'background-image:url("'+res.data.s3+'")';
        this.$message.success('アップロードしました。')
        this.listLoading = false;
      },
      handleAvatarSuccessImgs(res, file) {
        const loadingImg = this.imgs.filter(img => img.isLoading)[0];
        this.$set(loadingImg, 'url', `background-image:url('${res.data.s3}')`);
        this.$set(loadingImg, 'rUrl', `${res.data.s3}`);
        this.$set(loadingImg, 'isLoading', false);
      },
      removeFile(i){
        this.imgs.splice(i,1);
      },
      handleAvatarSuccessFile(res, file){
        this.fileUrl = res.data.s3
        this.fileSize = (file.size/1024/1024).toFixed(2)
        this.listLoadingFile = false;
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('アップロードサイズ：2MBまで!')
        } else {
          this.listLoading = true;
        }
        return isLt2M
      },
      beforeAvatarUploadImgs(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('アップロードサイズ：2MBまで!')
          return isLt2M;
        }
        if(this.imgs.length > 10){
          this.$message.warning(`10枚まで`);
          return false;
        }else{
          this.imgs = this.imgs.concat([{url:'',isLoading:true}]);
          return true;
        }
      },
      beforeAvatarUploadFile(file) {
        this.listLoadingFile = true;
      },
      changeLabel(i){
        if(!this.realDisabled){
          if(!this.labelsCheck[i]){
            const loadingImg = this.labelsCheck.filter(label => label);
            if(loadingImg.length > 3){
              this.$message.warning(`最大4つまで繰り返し選択可能`);
              return;
            }
          }
          this.$set(this.labelsCheck, i, !this.labelsCheck[i]);
        }
      },
      isIntegral(obj){
        return obj%1 === 0;
      },
      validateForm(){
        var las = [];
        if(this.false_test){}
        for(var i = 0;i<this.labels.length;i++){
          if(this.labelsCheck[i])
            las[las.length] = this.labels[i];
        }
        var msg = '';
        if(this.item.title === '')
          msg = 'タイトルを入力してください。';
        else if(this.item.description === '')
          msg = '詳細を入力してください。';
        else if(this.headimgurl == null)
          msg = 'サムネイルは必須です。';
        else if(this.imgs.length == 0)
          msg = 'サブイメージを登録してください。';
        else if(this.fileUrl == null)
          msg = 'NFTコンテンツファイルを登録してください。';
        else if(las.length == 0)
          msg = 'NFT拡張モジュールが必要です。';
        else if(this.item.introduction === '')
          msg = '説明文を入力してください。';
        else if(this.item.createVolumes === '')
          msg = '販売件数を入力してください。';
        else if(isNaN(this.item.createVolumes) || this.item.createVolumes <= 0 || !this.isIntegral(this.item.createVolumes))
          msg = '販売件数を入力してください';
        else if(this.item.price === '')
          msg = '価格を登録してください。';
        else if(isNaN(this.item.price) || this.item.price <= 0 || !this.isIntegral(this.item.price * 1000))
          msg = '価格を登録してください。';
        else if(!this.item.isYc && this.item.originalNftId == 0)
          msg = '現著作物のNFTを登録してください。';

        let un = 0
        for(let ca of this.categoryList) {if(ca.checked) {un++}}
        if(un == 0) {msg = 'カテゴリーを選択してください。'}

        return msg;
      },
      submit_form() {
        const that = this;
        if(this.formLoading) {
          return;
        }
        var msg = that.validateForm();
        if(msg != ''){
          that.$message.warning(msg);
          return;
        }
        this.formLoading = true;
        var formData = Object.assign({}, this.item)
        formData.thumbnail = this.headimgurl;
        formData.nftContents = this.fileUrl;
        formData.nftSize = this.fileSize;
        formData.royalty = formData.royalty * 0.01;
        if(!formData.allowEdit)
          formData.royalty = 0;

        let category = []
        for(let cate of this.categoryList) {
          if(cate.checked) {
            category.push(cate.categoryId)
          }
        }
        formData.category = category

        var las = [];
        for(var i = 0;i<this.labels.length;i++){
          if(this.labelsCheck[i])
            las[las.length] = this.labels[i];
        }
        formData.nftExtensions = las;
        var imgs = [];
        for(var i = 0;i<this.imgs.length;i++){
          if(i<10){
            imgs[imgs.length] = this.imgs[i].rUrl
          }
        }
        formData.assistImages = imgs;
        const price = formData.price
        formData.price = parseFloat(formData.price) * 1000;
        formData.referenceNFTId = formData.originalNftId;
        that.handleDataSubmit(formData)
        .then(() => {
					that.$router.push({path:'uploadFinish',query:{}});
        })
        .catch(err => {
					if(err.code == 'SERVER_ERROR') {
						that.$message.warning("ネットワークエラーが発生しました。しばらく経ってからもう一度お試しください。")
					} else {
						that.$message.warning(err.message)
					}
        })
        .finally(() => {
          that.formLoading = false
        })
      },
      async handleDataSubmit(data) {
        try{
          const res = await putAction(this.url.update + this.$route.query.requestId, data)
          if (res.code === 200) {
            return Promise.resolve()
          } else {
            return Promise.reject(res)
          }
          return Promise.resolve()
        } catch(err) {
          return Promise.reject(err)
        }
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select,textarea{outline:none;}
  a{text-decoration: none;}
  h3{color:#00C999;margin:30px 0;font-family:'SF UI Display ultralight';font-size:20px;}
  h4{color:#888888;font-size:18px;font-weight: normal;margin-bottom:50px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
  .paths .home{background-image: url(../assets/img/home.png);}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
  .mai{display: flex;flex-direction: column;}
  .mai h1{font-size:22px;margin:30px 0;font-weight: normal;}
  .mai .ti{display: flex;align-items: center;font-size:18px;margin:40px 0 20px 0;}
  .mai .ti span{color:#EC2222;font-size:13px;margin-left:20px;}
  .mai .ti label{color:#888888;font-size:14px;margin-left:30px;}
  .mai .remark{color: #888888;font-size:14px;margin:20px 0;}
  .mai .type{display:flex;margin-top:20px;}
  .mai .type span{font-size:14px;color:#4A4A4A;cursor: pointer;border: 2px solid #D0D0D0;border-radius: 20px;height:36px;width:150px;display: flex;justify-content: center;align-items: center;margin-right:30px;}
  .mai .type .act{border:2px solid #00C999;}
  .mai .goodDiv{margin:40px 0;}
  .mai .input{display: flex;align-items: flex-end;}
  .mai .input input{width:100%;height:40px;border: 1px solid #D0D0D0;border-radius: 2px;padding-left:16px;}
  .mai .input span{flex-shrink: 0;color:#888888;font-family: 'SF UI Display thin';width:50px;text-align: right;}
  .mai .input span a{font-family: 'SF UI Display thin';color:#000000;}
  .mai .textarea{display: flex;align-items: flex-end;}
  .mai .textarea textarea{width:100%;height:70px;border: 1px solid #D0D0D0;border-radius: 2px;padding:10px 16px;}
  .mai .textarea span{flex-shrink: 0;color:#888888;font-family: 'SF UI Display thin';width:50px;text-align: right;}
  .mai .textarea span a{font-family: 'SF UI Display thin';color:#000000;}
  .mai .radio{display: grid;grid-template-columns: 20% 20% 20% 20% 20%;grid-row-gap: 20px;}
  .mai .radio label{display: flex;align-items: center;font-size:14px;cursor: pointer;}
  .mai .radio input{margin-right:10px;appearance: none;position: relative;outline: none;}
  .mai .radio input:before {content: "";width: 24px;height: 24px;border: 1px solid #D0D0D0;display: inline-block;border-radius: 50%;vertical-align: middle;}
  .mai .radio input:checked:before {content: "";width: 24px;height: 24px;background-color: #000000;border: 1px solid #000000;display: inline-block;border-radius: 50%;}
  .mai .radio input:checked:after {content: "";width: 10px;height: 10px;text-align: center;background: #ffffff;border-radius: 50%;display: block;position: absolute;top: 8px;left: 8px;}
  .mai .fm{background-position: center;background-size: cover;height:234px;width:406px;background-image: url(../assets/img/noImg.jpg);}
  .mai .imgs{display: grid;grid-row-gap: 10px;grid-column-gap: 10px;}
  .mai .imgs .item{width:100%;background-image: url(../assets/img/noImg.jpg);background-position: center;background-size: cover;display: flex;justify-content: flex-end;}
  .mai .imgs .item .del{width:20px;height:20px;cursor: pointer;background-color: #C4C4C4;color:#ffffff;display: flex;align-items: center;justify-content: center;font-size:18px;}
  .mai .file .but{background: #000000;border-radius: 2px;height:46px;width:200px;display: flex;justify-content: center;align-items: center;background-image: url(../assets/img/pager.png);background-repeat: no-repeat;background-position: left 20px center;color:#ffffff;font-size: 15px;}
  .mai .file span{color:#00C999;font-size:13px;margin-top:10px;}
  .mai .tag{display: flex;flex-wrap: wrap;}
  .mai .tag .act{background: #EAFAF6;color: #00C999;}
  .mai .tag span{background: #F7F7F7;cursor: pointer;border-radius: 2px;margin:0 20px 10px 0;padding:6px 16px;color:#4A4A4A;font-family: 'SF UI Display thin';}
  .mai .lineInput{display: flex;align-items: center;font-family: 'SF UI Display light';font-size:14px;}
  .mai .lineInput div{display: flex;align-items: center;border-bottom:1px solid #000000;border-top:1px solid #ffffff;font-size:15px;height:40px;width:260px;font-family: 'SF UI Display medium';margin-right:10px;}
  .mai .lineInput div input{border:none;width:100%;font-family: 'SF UI Display ultralight';font-size:15px;}
  .mai .an{align-items: flex-start;}
  .mai .an img{margin-left:30px;}
  .mai .radioInline{display: flex;}
  .mai .radioInline label{margin-right:30px;font-family:'SF UI Display thin';cursor: pointer;}
  .mai .select{width:100%;}
  .mai .btn{margin:80px auto 0 auto;width:280px;height:46px;color:#ffffff;background: #D0D0D0;border-radius: 2px;font-family: 'SF UI Display medium';font-size:15px;display: flex;justify-content: center;align-items: center;}
  .mai .ok{background: #00C999;cursor: pointer;}
  .goodDiv .conDiv{display: grid;}
  .goodDiv .til{display: flex;align-items: center;justify-content: space-between;}
  .goodDiv .til input{border:none;border-bottom:1px solid #F0F0F0;width:300px;height:36px;background-image: url(../assets/img/search.png);background-repeat: no-repeat;background-position: right center;}
  .goodDiv .til input::-webkit-input-placeholder{color:#D0D0D0;font-size:15px;}
  .goodDiv .conDivs{width:100%;overflow-x: hidden;padding-bottom:10px;}
  .goodDiv .conDivEmpty{display: flex;justify-content: center;align-items: center;width:100%;font-size:20px;color: #888888;text-decoration-line: underline;}
  .goodDiv .conDivs::-webkit-scrollbar {width : 2px;}
  .goodDiv .conDivs::-webkit-scrollbar-thumb {border-radius   : 10px;background-color: #C4C4C4;}
  .goodDiv .conDivs::-webkit-scrollbar-track {box-shadow:inset 0 0 5px #ffffff;background:#ffffff;border-radius: 10px;}
  .goodDiv .conDiv .item{background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodDiv .conDiv .item .img{width:100%;background-position: center;background-size: cover;}
  .goodDiv .conDiv .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodDiv .conDiv .item .info{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodDiv .conDiv .item .info .headImg{height:30px;width:30px;margin-right:10px;border-radius: 50%;border: 1px solid #D0D0D0;background-size: cover;background-position: center;}
  .goodDiv .conDiv .item .info span{color: #4A4A4A;font-size:15px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
  .goodDiv .conDiv .act .zzDiv{background: #00C999;opacity: 0.2;position: relative;}
  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
  	.mai h1{font-size:18px;margin:20px 0;}
  	.mai .ti{font-size:13px;margin:30px 0 20px 0;flex-wrap: wrap;width:100%;}
  	.mai .ti span{font-size:12px;margin-left:10px;}
  	.mai .ti label{font-size:12px;margin-left:0;margin-top:10px;width:100%;}
  	.mai .remark{font-size:13px;margin:20px 0;line-height:1.8;}
  	.mai .input{flex-direction: column;align-items: flex-start;}
  	.mai .input span{font-size:13px;text-align: left;margin-top:10px;}
  	.mai .textarea{flex-direction: column;align-items: flex-start;}
  	.mai .textarea span{font-size:13px;text-align: left;margin-top:10px;}
  	.mai .radio{grid-template-columns: 50% 50%;grid-row-gap: 20px;}
  	.mai .radio label{font-size:13px;}
  	.mai .radio input{margin-right:5px;transform: scale(0.8);}
  	.mai .fm{height:53vw;width:92vw;}
  	.mai .imgs{display: flex;flex-wrap: wrap;justify-content: space-between;grid-row-gap: 2vw;grid-column-gap: 0px;}
  	.mai .imgs .item{width:45vw;height:25.94vw;margin-bottom:0px;}
    .mai .imgs .item div{height:25.94vw;width:45vw;}
  	.mai .file .but {height: 40px;width: 160px;background-position: left 16px center;font-family: 'Hiragino Kaku Gothic Pro';justify-content: flex-start;padding-left:36px;font-size: 13px;}
  	.mai .tag span{margin:0 16px 10px 0;padding:6px 10px;font-size: 14px;}
  	.mai .lineInput{font-size:12px;}
  	.mai .lineInput div{font-size:13px;height:30px;width:200px;}
  	.mai .btn {margin: 50px auto 0 auto;width: 60vw;height: 46px;}
  	.mai .select div select {padding: 10px 0;}
  	.mai .select{flex-wrap: wrap;}
  	.mai .select div {width:80vw;}
  	.mai .select span {margin:0 0 0 20px;}
  	.mai .select div select:nth-child(2) {width: 40%;margin: 0 10px;}
  	.mai .radioInline {flex-wrap: wrap;}
  	.mai .radioInline label {width:23vw;margin-right:0px;}
  	.mai .an h5{padding-top:6px;font-weight: normal;}
  	.paths {padding: 20px 0;}
  	.bottomDiv {height: 30px;}
  	.menu_item_end{border-bottom: none;}
  	.menu_header_name {border-bottom: none;}
  	.goodDiv .til{width:100%;}
  	.goodDiv .til .ti{margin: 20px 0 !important;}
  	.goodDiv .til input{width:40vw;height:28px;font-size:12px;}
  	.goodDiv .til input::-webkit-input-placeholder{font-size:12px;}
  	.goodDiv{display: flex;flex-direction: column;align-items: center;}
  	.goodDiv .til{margin-bottom:20px;font-size:16px;}
  	.goodDiv .conDivs{height:400px;overflow-y: auto;padding-bottom:0px;}
  	.goodDiv .conDiv{display: flex;flex-wrap: wrap;justify-content: space-between;}
  	.goodDiv .conDiv .item{width:44.2vw;margin-bottom:20px;}
  	.goodDiv .conDiv .item .img{width:44.2vw;height:24.25vw;}
  	.goodDiv .conDiv .item h5{font-size:14px;height:40px;}
    .goodDiv .conDiv .act .img{margin-top:calc(-24.25vw - 100px);}
    .goodDiv .conDiv .act .zzDiv{height:calc( 24.25vw + 100px);}
  	.content{margin:0 4vw;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.mai .imgs{grid-template-columns: 152px 152px 152px 152px 152px;}
  	.mai .imgs .item{height:87.6px;}
    .mai .imgs .item div{height:87.6px;width:152px;}
  	.goodDiv .conDivs{height:210px;overflow-y: auto;}
  	.goodDiv .conDivEmpty{height:210px;}
  	.goodDiv .conDiv{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
  	.goodDiv .conDiv .item .img{width:191px;height:105px;}
  	.goodDiv .conDiv .item h5{font-size:14px;height:40px;}
  	.goodDiv .conDiv .act .img{margin-top:-205px;}
  	.goodDiv .conDiv .act .zzDiv{height:205px;}
  	.bottomDiv{height:100px;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.content{width:1200px;margin:auto;}
  	.mobile{display: none !important;}
  	.mai .imgs{grid-template-columns: 232px 232px 232px 232px 232px;}
    .mai .imgs .item div{height:133.7px;width:232px;}
  	.mai .imgs .item{height:133.7px;}
  	.goodDiv .conDivs{height:280px;overflow-y: auto;}
  	.goodDiv .conDivEmpty{height:280px;}
  	.goodDiv .conDiv{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodDiv .conDiv .item .img{width:285px;height:156px;}
  	.goodDiv .conDiv .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodDiv .conDiv .item h5{height:48px;}
  	.goodDiv .conDiv .act .img{margin-top:-264px;}
  	.goodDiv .conDiv .act .zzDiv{height:264px;}
  	.bottomDiv{height:200px;}
  }
</style>
