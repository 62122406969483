import Vue from 'vue'
import { ACCESS_TOKEN } from "@/store/mutation-types"
const getters = {
  account: state => state.user.account,
  token: state => {
    state.user.token = Vue.ls.get(ACCESS_TOKEN)
    return state.user.token
  },
  userId: state => state.user.userId,
  nickname: state => state.user.nickname,
  headimgurl: state => state.user.headimgurl,
  cartNumber: state => state.cart.number,
  whiteListsAddress: state => state.contracts.whiteListsAddress,
  purchaseAddress: state => state.contracts.purchaseAddress,
  whiteListsAbi: state => state.contracts.whiteListsAbi,
  purchaseAbi: state => state.contracts.purchaseAbi
}

export default getters
