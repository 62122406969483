<template>
  <div>
    <!-- goodsList -->
    <div class="content goodsList" v-loading="mainLoading">
    	<div class="tool">
    		<div>
    			<span @click="go_page('CollectionCreated')">生成</span>
    			<span class="pc act" @click="go_page('CollectionActivity')">アクティビティ</span>
    			<span @click="go_page('CollectionFavorited')">お気に入り</span>
    			<span @click="go_page('CollectionCollected')">コレクション</span>
    		</div>
    		<div class="addOption pc" @click="go_add_page">
    			アイテム追加
    		</div>
    	</div>
      <div class="emptyInfo" v-if="list.length === 0">
        カートが空いてます
      </div>
    	<div class="list" v-if="list.length > 0">
    		<div class="listHeader">
    			<div></div>
    			<div>アイテム</div>
    			<div>価格</div>
    			<div>数量</div>
    			<div>送信者</div>
    			<div>受信者</div>
    			<div>タイム</div>
    		</div>
    		<div class="item" v-for="(item,i) in list" :key="item.activityId">
          <div class="other" v-if="item.type != 'sold' && item.type != 'minted'
          && item.type != 'mint_requested' && item.type != 'purchased'
          && item.type != 'royalty' && item.type != 'sold_deposit'">{{item.type}}</div>
          <div class="sale" v-if="item.type == 'sold'">完売</div>
          <div class="minted" v-if="item.type == 'minted'">ミント完了</div>
          <div class="minted" v-if="item.type == 'mint_requested'">ミント申請</div>
          <div class="purchase" v-if="item.type == 'purchased'">購入</div>
          <div class="entry" v-if="item.type == 'royalty'">Royalty</div>
          <div class="entry" v-if="item.type == 'sold_deposit'">販売金</div>
    			<div>
    				<div class="img" :style="item.url"></div>
    				<div class="info">
    					<p>{{item.groupOwnerNickName}}</p>
    					<span style="text-align: left;">{{item.title}}</span>
    				</div>
    			</div>
    			<div>
    				<span>{{numberFormat(item.price)}}</span>
    				<span>¥ {{numberFormat(item.price*hl)}}</span>
    			</div>
    			<div>
    				{{item.volume}}
    			</div>
    			<div>
    				{{item.fromUserNickName}}
    			</div>
    			<div>
    				{{item.toUserNickName}}
    			</div>
    			<div>
    				<span>{{item.dayStr}}</span>
    				<span>{{item.timeStr}}</span>
    			</div>
    		</div>

    	</div>
    </div>
    <div class="content pager pc">
    	<pagination
    	  layout="prev, pager, next"
    	  :page-size="pageSize"
    	  @current-change="change_page"
    	  :total="total">
    	</pagination>
    </div>
    <div class="more mobile" @click="nextPage" v-if="isHaveNext">もっと見る</div>
    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home" @click="go_home"></i>
    	<i class="jt"></i>
    	<a>アクティビティ</a>
    </div>
  </div>
</template>

<script>
  import { Pagination } from 'element-ui'
  import { putAction,getAction } from '@/network/manage'
  import numeral from 'numeral'
  import moment from 'moment'
  export default {
    name: 'CollectionActivity',
    components: {
      Pagination
    },
    data() {
      return {
        url: {
          list: '/activity/',
          hl:'/matic_conversion/'
        },
        list:[],
        hl:1,
        page:1,
        isHaveNext:false,
        mainLoading:true,
        total:0,
        pageSize:8
      }
    },
    created(){
      this.loadHl();
      this.listData();
    },
    methods:{
      go_page(url){
        this.$router.push({path:url,query:{}})
      },
      go_add_page(){
        this.$router.push({path:'upload',query:{}});
      },
      go_home(){
        this.$router.push({path:'home',query:{}})
      },
      change_page(e){
        this.list=[];
        this.page = e;
        this.listData();
      },
      numberFormat(txt){
        return numeral(txt).format('0,0.000')
      },
      nextPage(){
        this.page = this.page + 1;
        this.listData();
      },
      loadHl(){
        const that = this;
        getAction(this.url.hl,{}).then((res) => {
          if (res.code == 200) {
            that.hl = res.data.maticToYen;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      listData(){
        const that = this;
        that.mainLoading = true;
        getAction(this.url.list,{page:this.page,size:that.pageSize}).then((res) => {
          if (res.code == 200) {
            var obj = res.data.contents;
            for(var i = 0;i<obj.length;i++){
              obj[i].url = "background-image:url('"+obj[i].thumbnail+"')";
              obj[i].price = obj[i].price / 1000;
              if(obj[i].datetime != null){
                obj[i].dayStr = moment(obj[i].datetime).format('YY-MM-DD');
                obj[i].timeStr = moment(obj[i].datetime).format('HH:mm:ss');
              }
            }
            that.list = that.list.concat(obj);
            that.total = res.data.page.totalElements;
            if(res.data.page.currentPage < res.data.page.totalPage)
              that.isHaveNext = true;
            else
              that.isHaveNext = false;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.mainLoading = false;
        })
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
  .goodsList .tool{display: flex;align-items: center;color:#888888;justify-content: space-between;}
  .goodsList .tool a{background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right center;padding-right:20px;}
  .goodsList .tool div{display: flex;}
  .goodsList .tool div span{cursor: pointer;}
  .goodsList .tool div .act{color:#00C999;}

  .goodsList .tool .addOption{cursor: pointer;color: #1192CA;padding-left:30px;background-image: url(../assets/img/addBlue.png);background-repeat: no-repeat;background-position: left top 3px;}
  .goodsList .list{}
  .goodsList .list .listHeader{display: flex;border-top: 1px solid #D0D0D0;border-bottom: 1px solid #D0D0D0;padding:22px 0;margin-bottom:20px;}
  .goodsList .list .listHeader div{color: #4A4A4A;text-align: center;}
  .goodsList .list .listHeader div:nth-child(1){width:250px;flex-shrink: 0;}
  .goodsList .list .listHeader div:nth-child(2){width:100%;text-align: left;}
  .goodsList .list .listHeader div:nth-child(3){width:100px;flex-shrink: 0;}
  .goodsList .list .listHeader div:nth-child(4){width:100px;flex-shrink: 0;}
  .goodsList .list .listHeader div:nth-child(5){width:120px;flex-shrink: 0;}
  .goodsList .list .listHeader div:nth-child(6){width:120px;flex-shrink: 0;}
  .goodsList .list .listHeader div:nth-child(7){width:100px;flex-shrink: 0;text-align: right;}
  .goodsList .list .item{display: flex;padding:22px 0;align-items: center;}
  .goodsList .list .item *{color: #4A4A4A;text-align: center;}
  .goodsList .list .item div:nth-child(1){width:250px;flex-shrink: 0;text-align: left;padding-left:60px;background-position: left 20px center;background-repeat: no-repeat;}
  .goodsList .list .item .other{background-image: url(../assets/img/other.png);background-size: 21px 21px;}
  .goodsList .list .item .sale{background-image: url(../assets/img/tag.png);}
  .goodsList .list .item .minted{background-image: url(../assets/img/addBlack.png);}
  .goodsList .list .item .purchase{background-image: url(../assets/img/cartBlack.png);}
  .goodsList .list .item .entry{background-image: url(../assets/img/entry.png);}
  .goodsList .list .item div:nth-child(2){width:100%;display: flex;align-items: center;}
  .goodsList .list .item div:nth-child(2) .img{padding:0;width:40px;height:40px;background-size: cover;background-position: center;margin-right:5px;}
  .goodsList .list .item div:nth-child(2) .info{display: flex;flex-direction: column;align-items: flex-start;}
  .goodsList .list .item div:nth-child(2) .info p{font-size:14px;color: #888888;}
  .goodsList .list .item div:nth-child(3){width:100px;flex-shrink: 0;display: flex;flex-direction: column;align-items: flex-end;padding-right:20px;}
  .goodsList .list .item div:nth-child(3) span:nth-child(1){background-image: url(../assets/img/link.png);background-repeat: no-repeat;background-position: left top 5px;background-size: 20px;padding-left:30px;}
  .goodsList .list .item div:nth-child(3) span:nth-child(2){color: #888888;font-size:12px;}
  .goodsList .list .item div:nth-child(4){width:100px;flex-shrink: 0;font-size:18px;}
  .goodsList .list .item div:nth-child(5){width:120px;flex-shrink: 0;}
  .goodsList .list .item div:nth-child(6){width:120px;flex-shrink: 0;}
  .goodsList .list .item div:nth-child(7){width:100px;flex-shrink: 0;display: flex;flex-direction: column;align-items: flex-end;}
  .goodsList .list .item div:nth-child(7) span:nth-child(1){font-size:14px;}
  .goodsList .list .item div:nth-child(7) span:nth-child(2){color: #888888;font-size:12px;}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:140px 0;border-bottom: 1px solid #F0F0F0;margin-bottom:20px;}
  .pager{display: flex;justify-content: center;align-items: center;margin-top:50px !important;}
  .pager i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;margin:0px 20px;}
  .pager .lastPage{background-image: url(../assets/img/right.png);transform: rotate(180deg);cursor: pointer;}
  .pager .nextPage{background-image: url(../assets/img/right.png);cursor: pointer;}
  .pager a{width:18px;height:18px;line-height:18px;font-family:'SF UI Display thin';color:#888888;margin:0px 15px;cursor: pointer;}
  .pager .act{color:#000000;font-family:'SF UI Display bold'}
  .title{line-height: 80px;font-size:20px;}
  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
    .content{margin:0 16px;}
    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .tool{margin-top:10px;height:50px;margin-bottom:10px;width:100%;}
    .goodsList .tool div{width:100%;justify-content: space-between;}
    .goodsList .tool div span{line-height:50px;height:48px;margin-right:0px;display: flex;flex-direction: column;align-items: center;}
    .goodsList .tool div .act::after{content: '';width:30px;background-color:#00C999;height:1px;position: absolute;margin-top:38px;}
    .goodsList .list{}
    .paths {padding: 20px 0;}
    .emptyInfo{padding:20vw 0;}
    .more{height:30px;background: #00C999;color:#ffffff;border-radius: 35px;width: 148px;margin:30px auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
    .content{width:800px;margin:auto;}
    .goodsList .list{min-height:500px;}
    .goodsList .list *{font-size:14px;}
    .goodsList .list .listHeader{padding:22px 0;margin-bottom:20px;}
    .goodsList .list .listHeader div:nth-child(1){width:120px;}
    .goodsList .list .listHeader div:nth-child(2){width:100%;}
    .goodsList .list .listHeader div:nth-child(3){width:100px;}
    .goodsList .list .listHeader div:nth-child(4){width:80px;}
    .goodsList .list .listHeader div:nth-child(5){width:80px;}
    .goodsList .list .listHeader div:nth-child(6){width:80px;}
    .goodsList .list .listHeader div:nth-child(7){width:80px;}
    .goodsList .list .item{padding:22px 0;}
    .goodsList .list .item div:nth-child(1){width:120px;padding-left:40px;background-position: left 10px center;}
    .goodsList .list .item div:nth-child(3){width:100px;padding-right:20px;}
    .goodsList .list .item div:nth-child(3) span:nth-child(1){background-position: left top 5px;background-size: 20px;padding-left:30px;}
    .goodsList .list .item div:nth-child(4){width:80px;font-size:16px;}
    .goodsList .list .item div:nth-child(5){width:80px;}
    .goodsList .list .item div:nth-child(6){width:80px;}
    .goodsList .list .item div:nth-child(7){width:80px;}
    .bottomDiv{height:100px;}
    .goodsList .tool{margin-top:0px;height:50px;margin-top:20px;}
    .goodsList .tool div span{line-height:50px;margin-right:30px;}
    .emptyInfo{padding:80px 0;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.goodsList .list{min-height:600px;}
    .content{width:1200px;margin:auto;}
    .bottomDiv{height:200px;}
    .goodsList .tool{margin-top:0px;height:70px;margin-top:40px;}
    .goodsList .tool div span{line-height:70px;margin-right:50px;}
    .emptyInfo{padding:80px 0;}
  }
</style>
