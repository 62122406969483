import Vue from 'vue'
import Router from 'vue-router'
import Home from 'views/Home'
import Register from 'views/Register'
import RegisterFinish from 'views/RegisterFinish'
import Explorer from 'views/Explorer'
import NtfHome from 'views/NtfHome'
import CollectionCreated from 'views/CollectionCreated'
import CollectionActivity from 'views/CollectionActivity'
import CollectionFavorited from 'views/CollectionFavorited'
import CollectionCollected from 'views/CollectionCollected'
import Page from 'views/Page'
import Prifile from 'views/Prifile'
import Wallet from 'views/Wallet'
import Qrcode from 'views/Qrcode'
import Upload from 'views/Upload'
import UploadEdit from 'views/UploadEdit'
import UploadFinish from 'views/UploadFinish'
import NftBlocked from 'views/NftBlocked'
import Cart from 'views/Cart'
import PageQuestion from 'views/PageQuestion'

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/explorer',
    component: Explorer
  },
  {
    path: '/ntfHome',
    component: NtfHome
  },
  {
    path: '/collectionCreated',
    component: CollectionCreated
  },
  {
    path: '/collectionActivity',
    component: CollectionActivity
  },
  {
    path: '/collectionFavorited',
    component: CollectionFavorited
  },
  {
    path: '/collectionCollected',
    component: CollectionCollected
  },
  {
    path: '/upload',
    component: Upload
  },
  {
    path: '/uploadEdit',
    component: UploadEdit
  },
  {
    path: '/uploadFinish',
    component: UploadFinish
  },
  {
    path: '/cart',
    component: Cart
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/registerFinish',
    component: RegisterFinish
  },
  {
    path: '/page/:linkurl',
    name: "page",
    component: Page
  },
  {
    path: '/prifile',
    component: Prifile
  },
  {
    path: '/wallet',
    component: Wallet
  },
  {
    path: '/qrcode',
    component: Qrcode
  },
  {
    path: '/nftBlocked',
    name: "nftBlocked",
    component: NftBlocked
  },
  {
    path: '/question',
    component: PageQuestion
  },
  {
    path: '/404',
    component: () => import('views/exception/404')
  },
  {
    path: '*',
    redirect: '/404',
  }
]
const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 })
})
export default router
