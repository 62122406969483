<template>
  <div>
    <div class="content goodsList" v-loading="mainLoading">
    	<div class="tool">
    		<div>
          <span class="act" @click="go_page('CollectionCreated')">生成</span>
          <span class="pc" @click="go_page('CollectionActivity')">アクティビティ</span>
          <span @click="go_page('CollectionFavorited')">お気に入り</span>
          <span @click="go_page('CollectionCollected')">コレクション</span>
    		</div>
    		<div class="addOption pc" @click="go_add_page">
    			アイテム追加
    		</div>
    	</div>
      <div class="emptyInfo" v-if="list.length === 0">
        カートが空いてます
      </div>
    	<div class="list">
    		<div class="item" v-for="(item,i) in list" :key="item.requestId">
    			<div class="zz" v-if="item.isApproved == 'pending'" @click="go_edit(item)">
    				<div class="zzCon">
    					<img src="../assets/img/zz1.png"/>
    					<p>NFT is minting...</p>
    				</div>
    				<div class="zzBG mobile"></div>
    			</div>
          <div class="zz" v-if="item.isApproved == 'rejected'" @click="go_edit(item)">
          	<div class="zzCon">
          		<img src="../assets/img/zz2.png"/>
          		<p>Rejected</p>
          	</div>
          	<div class="zzBG mobile"></div>
          </div>
          <div class="zz" v-if="item.isApproved == 'approved' && item.sale_status == 'blocked'" @click="go_edit(item)">
          	<div class="zzCon">
          		<img src="../assets/img/blocked.png"/>
          		<p>Blocked</p>
          	</div>
          	<div class="zzBG mobile"></div>
          </div>
    			<div class="img" :style="item.url" @click="go_edit(item)">
    				<div class="tg" v-if="item.isApproved == 'approved' && item.sale_status == 'on_sale'">販売中</div>
            <div class="tg" v-if="item.isApproved == 'approved' && item.sale_status == 'not_on_sale'">販売待ち</div>
            <div class="tg" v-if="item.isApproved == 'approved' && item.sale_status == 'sold_out'">販売完了</div>
    			</div>
    			<h5 @click="go_edit(item)">{{item.title}}</h5>
    			<div class="price" @click="go_edit(item)">
    				<div>
    					<h4>{{numberFormat(item.sellPriceMatic)}} MATIC</h4>
    					<span>( ≈ ¥ {{numberFormat(item.sellPriceMatic * hl)}})</span>
    				</div>
    				<div>
    					<h4>{{item.soldCount}} 件販売</h4>
    					<span>({{item.createdVolumes}} 件)</span>
    				</div>
    			</div>
    			<div class="options">
    				<div class="edit" @click="show_info(item)">販売管理</div>
    				<div class="heart pc" style="color:#4A4A4A;font-family:'SF UI Display thin'">{{item.likeCount}}</div>
    				<!-- <div class="heart mobile"></div> -->
    			</div>
    		</div>
    	</div>
    </div>
    <div class="content pager pc">
    	<pagination
    	  layout="prev, pager, next"
    	  :page-size="pageSize"
    	  @current-change="change_page"
    	  :total="total">
    	</pagination>
    </div>
    <div class="more mobile" @click="nextPage" v-if="isHaveNext">もっと見る</div>
    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home" @click="go_home"></i>
    	<i class="jt"></i>
    	<a>生成</a>
    </div>
    <div class="layerDiv" v-if="status_tc_flag">
      <div class="cont">
        <div class="layTil">
          販売管理
          <img src="../assets/img/close_bk.png" style="cursor: pointer;" @click="status_tc_flag = false"/>
        </div>
        <div class="fle" style="align-items: flex-start;">
          <span>作品名</span>
          <p style="word-break:break-all;">{{select_item.title}}</p>
        </div>
        <div class="fle">
          <span>販売数量</span>
          <p>{{select_item.soldCount}} / {{select_item.createdVolumes}}<a>EA</a></p>
        </div>
        <div class="fle">
          <span>販売価格</span>
          <p>{{numberFormat(select_item.sellPriceMatic)}}<a>MATIC</a></p>
        </div>
        <div class="fle">
          <span>総販売額</span>
          <p>{{numberFormat(select_item.sellPriceMaticSummary)}}<a>MATIC</a></p>
        </div>
        <div class="fle">
          <span>販売状態</span>
          <p style="cursor: pointer;">
            <img v-if="item_status == false" @click="item_status = true" src="../assets/img/btn.png" style="width:50px;padding-top: 6px;"/>
            <img v-if="item_status == true" @click="item_status = false" src="../assets/img/btn_open.png" style="width:50px;padding-top: 6px;"/>
            <span v-if="item_status == false" style="margin-left:5px;">販売待機中</span>
            <span v-if="item_status == true" style="color:#00C999;margin-left:5px;">販売中</span>
          </p>
        </div>
        <div class="tils">注意事項</div>
        <div class="p">
          * 販売待機状態の作品はホームページに表示されません。<br/>
          * 販売が行われていない作品の場合、販売待機転換後に作品の修正が可能です。<br/>
          * すでに販売が行われているNFT作品は修正できません。
        </div>
        <div class="btn" @click="set_sale_status(select_item)">保存する</div>
      </div>
    </div>

    <div class="layerDiv" v-if="sold_tc_flag">
      <div class="cont">
        <div class="layTil">
          販売管理
          <img src="../assets/img/close_bk.png" style="cursor: pointer;" @click="sold_tc_flag = false"/>
        </div>
        <div class="fle" style="align-items: flex-start;">
          <span>作品名</span>
          <p style="word-break:break-all;">{{select_item.title}}</p>
        </div>
        <div class="fle">
          <span>販売数量</span>
          <p>{{select_item.soldCount}} / {{select_item.createdVolumes}}<a>EA</a></p>
        </div>
        <div class="fle">
          <span>販売価格</span>
          <p>{{numberFormat(select_item.sellPriceMatic)}}<a>MATIC</a></p>
        </div>
        <div class="fle">
          <span>総販売額</span>
          <p>{{numberFormat(select_item.sellPriceMaticSummary)}}<a>MATIC</a></p>
        </div>
        <div class="fle">
          <span>販売状態</span>
          <p>
            <span style="color:#00C999;">販売完了</span>
          </p>
        </div>
        <div class="p">
          </br>
          * 詳細な販売内訳はActivityで確認できます。
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import { Pagination } from 'element-ui'
  import { putAction,getAction } from '@/network/manage'
  import moment from 'moment'
  import numeral from 'numeral'
  export default {
    name: 'CollectionCreated',
    components: {
      Pagination
    },
    data() {
      return {
        url: {
          list: '/created/',
          hl:'/matic_conversion/'
        },
        list:[],
        hl:1,
        page:1,
        isHaveNext:false,
        total:0,
        mainLoading:true,
        pageSize:8,
        status_tc_flag: false,
        sold_tc_flag: false,
        item_status: false,
        select_item: {}
      }
    },
    created(){
      this.loadHl();
      this.listData();
    },
    methods:{
      set_sale_status(item) {
        this.mainLoading = true
        putAction("/nft/my_nft_group/status/" + item.groupId,{sale_status:this.item_status}).then((res) => {
          this.mainLoading = false
          if (res.code == 200) {
            this.status_tc_flag = false
            this.$message.success("設定に成功しました");
            this.change_page(this.page)
          } else {
            this.$message.error(res.message);
          }
        }).catch(res => {
          this.$message.warning(res.message);
        }).finally(res => {
          this.mainLoading = false;
        })
      },
      show_info(item) {
        this.select_item = item
        if(item.isApproved == 'approved' && item.sale_status == 'not_on_sale') {
          this.status_tc_flag = true
          this.item_status = false
        } else if(item.isApproved == 'approved' && item.sale_status == 'on_sale') {
          this.status_tc_flag = true
          this.item_status = true
        } else if(item.isApproved == 'approved' && item.sale_status == 'sold_out') {
          this.sold_tc_flag = true
        }
      },
      go_page(url){
        this.$router.push({path:url,query:{}})
      },
      go_edit(item){
        if(item.isApproved == 'pending') {
          //wating
          this.$router.push({path:'UploadFinish'});
        } else if(item.isApproved == 'rejected') {
          //reject
          this.$router.push({path:'uploadEdit',query:{requestId:item.requestId}});
        } else if(item.isApproved == 'approved' && item.sale_status == 'on_sale') {
          //apprvoed onsale
          this.$router.push({path:'ntfHome',query:{id:item.groupId}});
        } else if(item.isApproved == 'approved' && item.sale_status == 'not_on_sale' && item.soldCount == 0) {
          //stop sale --- salecount=0 === go to edit
          this.$router.push({path:'uploadEdit',query:{requestId:item.requestId}});
        } else if(item.isApproved == 'approved' && item.sale_status == 'not_on_sale' && item.soldCount > 0) {
          //stop sale --- salecount>0 ====go to nft home
          this.$router.push({path:'ntfHome',query:{id:item.groupId}});
        } else if(item.isApproved == 'approved' && item.sale_status == 'blocked') {
          //blocked
          this.$router.push({path:'NftBlocked'});
        }
      },
      go_add_page(){
        this.$router.push({path:'upload',query:{}});
      },
      go_home(){
        this.$router.push({path:'home',query:{}})
      },
      change_page(e){
        this.list=[];
        this.page = e;
        this.listData();
      },
      numberFormat(txt){
        return numeral(txt).format('0,0.000')
      },
      nextPage(){
        this.page = this.page + 1;
        this.listData();
      },
      loadHl(){
        const that = this;
        getAction(this.url.hl,{}).then((res) => {
          if (res.code == 200) {
            that.hl = res.data.maticToYen;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      listData(){
        const that = this;
        that.mainLoading = true;
        getAction(this.url.list,{page:this.page,size:that.pageSize}).then((res) => {
          if (res.code == 200) {
            var obj = res.data.contents;
            for(var i = 0; i < obj.length; i++){
              obj[i].sellPriceMaticSummary = (obj[i].sellPriceMatic*obj[i].soldCount)/1000
              obj[i].sellPriceMatic = obj[i].sellPriceMatic / 1000;

              obj[i].url = "background-image:url('"+obj[i].url+"')";
              if(obj[i].likeCount > 1000000) {
                obj[i].likeCount = (obj[i].likeCount/1000000).toFixed(1) + "M"
              }
              if(obj[i].likeCount > 1000) {
                obj[i].likeCount = (obj[i].likeCount/1000).toFixed(1) + "k"
              }

            }
            that.list = that.list.concat(obj);
            that.total = res.data.page.totalElements;
            if(res.data.page.currentPage < res.data.page.totalPage)
              that.isHaveNext = true;
            else
              that.isHaveNext = false;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.mainLoading = false;
        })
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-family:'SF UI Display thin';font-size:13px;color:#4A4A4A;}
  .goodsList .tool{display: flex;align-items: center;color:#888888;justify-content: space-between;}
  .goodsList .tool a{background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right center;padding-right:20px;}
  .goodsList .tool div{display: flex;}
  .goodsList .tool div span{cursor: pointer;}
  .goodsList .tool div .act{color:#00C999;}
  .goodsList .tool .addOption{cursor: pointer;color: #1192CA;padding-left:30px;background-image: url(../assets/img/addBlue.png);background-repeat: no-repeat;background-position: left top 3px;}
  .goodsList .list{display: grid;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .zz{background: rgba(0, 0, 0, 0.4);position: absolute;}
  .goodsList .list .item .zz .zzCon{display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .goodsList .list .item .zz p{color:#ffffff;font-size:15px;margin-top:10px;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;display: flex;justify-content: flex-end;align-items: flex-start;padding:7px 12px;}
  .goodsList .list .item .img .tg{background: rgb(255 255 255 / 44%);box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, .4);border-radius: 20px;font-size:13px;padding:2px 20px;color:#000000;font-weight: bold;}
  .goodsList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;justify-content: space-between;}
  .goodsList .list .item .price div{display: flex;flex-direction: column;}
  .goodsList .list .item .price h4{font-family: 'SF UI Display bold';line-height:1;}
  .goodsList .list .item .price span{font-family: 'SF UI Display bold';line-height:1;color:#888888;font-size:15px;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/heart.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/heartAct.png);}
  .goodsList .list .item .options{border-top:1px solid #F0F0F0;padding:16px;display: flex;justify-content: space-between;align-items: center;}
  .goodsList .list .item .options .heart{padding-left:30px;background-position:left top 3px;background-repeat: no-repeat;background-image: url(../assets/img/heartAct.png);font-size:15px;}
  .goodsList .list .item .options .edit{padding-left:30px;background-position:left center;background-repeat: no-repeat;background-image: url(../assets/img/cl.png);font-size:15px;}
  .goodsList .list .item .options .soldout{color: #1192CA;font-size: 15px;}
  .pager{display: flex;justify-content: center;align-items: center;margin-top:50px !important;}
  .pager i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;margin:0px 20px;}
  .pager .lastPage{background-image: url(../assets/img/right.png);transform: rotate(180deg);cursor: pointer;}
  .pager .nextPage{background-image: url(../assets/img/right.png);cursor: pointer;}
  .pager a{width:18px;height:18px;line-height:18px;font-family:'SF UI Display thin';color:#888888;margin:0px 15px;cursor: pointer;}
  .pager .act{color:#000000;font-family:'SF UI Display bold'}
  .title{line-height: 80px;font-size:20px;}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:140px 0;border-bottom: 1px solid #F0F0F0;margin-bottom:20px;}


  .layerDiv{position: fixed;left:0;top:0;z-index:999;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .layerDiv .cont{background: #ffffff;width:510px;padding:30px 40px 60px 40px;border-radius: 30px;box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);display: flex;flex-direction: column;align-items: center;}
  .layerDiv .cont .layTil{width:100%;display: flex;align-items: center;justify-content: space-between;font-size:24px;font-weight: bold;margin-bottom:20px;}
  .layerDiv .cont .layTil img{width:20px;}
  .layerDiv .cont .fle{display: flex;width:100%;align-items:center;margin-top:6px;}
  .layerDiv .cont .fle span{width:100px;flex-shrink: 0;}
  .layerDiv .cont .fle p{width:100%;color:#888888;font-size:14px;display: flex;align-items: center;}
  .layerDiv .cont .fle p a{margin-left:5px;color:#888888;font-weight: bold;}
  .layerDiv .cont .tils{color:#00C999;font-weight: bold;margin:20px 0;}
  .layerDiv .cont .p{color:#01388B;width:100%;font-size:12px;line-height:2;}
  .layerDiv .cont .btn{width:350px;height:50px;color:#ffffff;display: flex;cursor: pointer;margin-top:40px;align-items: center;justify-content: center;background-color: #00C999;}

  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
    .content{margin:0 16px;}
    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:13px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:12px;}
    .goodsList .tool{margin-top:10px;height:50px;margin-bottom:10px;width:100%;}
    .goodsList .tool div{width:100%;justify-content: space-between;}
    .goodsList .tool div span{line-height:50px;height:48px;margin-right:0px;display: flex;flex-direction: column;align-items: center;}
    .goodsList .tool div .act::after{content: '';width:30px;background-color:#00C999;height:1px;position: absolute;margin-top:38px;}
    .goodsList .mobile{padding:30px 0 60px 0;color:#888888;background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right top 37px;padding-right:16px;}
    .goodsList .list .item .zz{width:44.2vw;height:24.25vw;}
    .goodsList .list .item .zz .zzBG{height:145px;position: absolute;width:44.2vw;background: rgba(0, 0, 0, 0.4);padding:0px;margin:0px;}
    .goodsList .list .item .zz .zzCon{height:24.25vw;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .goodsList .list .item .options .heart{width:21px;height:21px;padding:0px;margin:0px;background-image: url(../assets/img/heartAct.png);background-repeat: no-repeat;background-position: right center;}
    .paths {padding: 20px 0;}
    .emptyInfo{padding:20vw 0;}
    .more{height:30px;background: #00C999;color:#ffffff;border-radius: 35px;width: 148px;margin:30px auto;font-family: 'SF UI Display bold';display: flex;justify-content: center;align-items: center;}

    .layerDiv{background-color: #ffffff;align-items: flex-start;}
    .layerDiv .cont{box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);width:100%;padding:20px;}
    .layerDiv .cont .btn{width:100%;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
    .content{width:800px;margin:auto;}
    .goodsList .list{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
    .goodsList .list .item .img{width:191px;height:105px;}
    .goodsList .list .item .price i{width:30px;height:30px;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price h4{font-size:14px;}
    .goodsList .list .item .price span{font-size:14px;}
    .goodsList .list .item .zz{width:191px;height:246.5px;}
    .goodsList .list .item .zz .zzCon{height:105px;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .bottomDiv{height:100px;}
    .goodsList .tool{margin-top:0px;height:50px;margin-top:20px;}
    .goodsList .tool div span{line-height:50px;margin-right:30px;}
    .emptyInfo{padding:80px 0;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
    .goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
    .goodsList .list .item .img{width:285px;height:156px;}
    .goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
    .goodsList .list .item h5{height:48px;}
    .goodsList .list .item .zz{width:285px;height:320.5px;}
    .goodsList .list .item .zz .zzCon{height:156px;}
    .goodsList .list .item .zz img{height:60px;}
    .bottomDiv{height:200px;}
    .goodsList .tool{margin-top:0px;height:70px;margin-top:40px;}
    .goodsList .tool div span{line-height:70px;margin-right:50px;}
    .emptyInfo{padding:80px 0;}
  }
</style>
